import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Typography, Box, Avatar, Grid, Paper, Tabs, Tab,
    List, ListItem, ListItemText, Button, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    TextField, Divider, InputAdornment,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Accordion, AccordionSummary, AccordionDetails,
    Card, CardContent, CardMedia, Chip, CircularProgress, IconButton, Alert,
    ListItemIcon, Collapse
} from '@mui/material';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import VideocamIcon from '@mui/icons-material/Videocam';
import PersonIcon from '@mui/icons-material/Person';
import { getFirestore, doc, getDoc, updateDoc, collection, deleteDoc, getDocs, query, where, arrayUnion, orderBy, limit } from 'firebase/firestore';
import BoltIcon from '@mui/icons-material/Bolt';
import { AuthContext } from '../../contexts/AuthContext';
import deleteClientService from '../../services/deleteClient';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getStorage } from 'firebase/storage';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import CancelIcon from '@mui/icons-material/Cancel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/animations/Resumen_Home_Loading.json';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import {
    FiZap,  // Para calorías
    FiTarget, // Para proteínas
    FiDroplet, // Para grasas
    FiHexagon // Para carbohidratos
} from 'react-icons/fi';
import AddIcon from '@mui/icons-material/Add';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditAIModal from './modals/EditAIModal';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { SnackbarProvider } from 'notistack';
import FitnessTracker from './FitnessTracker';
import ExerciseDetailModal from '../modals/ExerciseDetailModal';
import CreateRoutineModal from './CreateRoutineModal'; // Importar el nuevo modal
import ClientRoutine from './ClientRoutine'; // Importar el nuevo componente
import EditIcon from '@mui/icons-material/Edit';


const sampleData = [
    { name: 'Ene', peso: 70 },
    { name: 'Feb', peso: 69 },
    { name: 'Mar', peso: 68 },
    { name: 'Abr', peso: 67 },
    { name: 'May', peso: 66 },
    { name: 'Jun', peso: 65 },
];

const GlowingTypography = styled(Typography)(({ theme }) => ({
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: -2,
        left: -2,
        right: -2,
        bottom: -2,
        background: 'linear-gradient(45deg, #FF9800, #FF4081)',
        filter: 'blur(10px)',
        opacity: 0.7,
        zIndex: -1,
        borderRadius: '4px',
    },
}));

// Estilo personalizado para el botón
const PlanButton = styled(Button)(({ theme, plan }) => ({
    background: plan === 'Pro'
        ? 'linear-gradient(45deg, #FF9800 30%, #FF4081 90%)'
        : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    border: 0,
    borderRadius: 20,
    boxShadow: plan === 'Pro'
        ? '0 3px 5px 2px rgba(255, 105, 135, .3)'
        : '0 3px 5px 2px rgba(33, 203, 243, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        background: plan === 'Pro'
            ? 'linear-gradient(45deg, #FF4081 30%, #FF9800 90%)'
            : 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
        boxShadow: plan === 'Pro'
            ? '0 4px 6px 2px rgba(255, 105, 135, .5)'
            : '0 4px 6px 2px rgba(33, 203, 243, .5)',
    },
}));

const BlurOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
}));

const ProCard = styled(Card)(({ theme }) => ({
    maxWidth: 300,
    textAlign: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 8px 32px rgba(31, 38, 135, 0.37)',
    borderRadius: 10,
}));

const NotificationCard = ({ message, type = 'success', onClose }) => {
    const colors = {
        success: {
            bg: 'rgba(46, 125, 50, 0.1)',
            border: '#2e7d32',
            text: '#1b5e20'
        },
        error: {
            bg: 'rgba(211, 47, 47, 0.1)',
            border: '#d32f2f',
            text: '#b71c1c'
        },
        info: {
            bg: 'rgba(2, 136, 209, 0.1)',
            border: '#0288d1',
            text: '#01579b'
        }
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: '20px',
                right: '20px',
                zIndex: 9999,
                minWidth: '300px',
                backgroundColor: colors[type].bg,
                borderLeft: `4px solid ${colors[type].border}`,
                borderRadius: '4px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                p: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                animation: 'slideIn 0.3s ease-out',
                '@keyframes slideIn': {
                    from: { transform: 'translateX(100%)', opacity: 0 },
                    to: { transform: 'translateX(0)', opacity: 1 }
                }
            }}
        >
            {type === 'success' && <CheckCircleIcon sx={{ color: colors[type].text }} />}
            {type === 'error' && <ErrorIcon sx={{ color: colors[type].text }} />}
            {type === 'info' && <InfoIcon sx={{ color: colors[type].text }} />}

            <Typography sx={{ color: colors[type].text, flexGrow: 1 }}>
                {message}
            </Typography>

            <IconButton
                size="small"
                onClick={onClose}
                sx={{
                    color: colors[type].text,
                    '&:hover': {
                        backgroundColor: `${colors[type].bg}`,
                    }
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Box>
    );
};

const DietaViewer = ({ dieta, loading, error, setDieta, userDetails }) => {
    const [selectedDay, setSelectedDay] = useState(0);
    const [selectedMeal, setSelectedMeal] = useState(0);
    const [foodImages, setFoodImages] = useState({});
    const [openFoodPicker, setOpenFoodPicker] = useState(false);
    const [availableFoodTypes, setAvailableFoodTypes] = useState([]);
    const [openSuggestionDialog, setOpenSuggestionDialog] = useState(false);
    const [newSuggestion, setNewSuggestion] = useState('');
    const [loadingState, setLoadingState] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [addingFood, setAddingFood] = useState(false);
    const db = getFirestore();
    const { enqueueSnackbar } = useSnackbar();

    const getMealType = (selectedMeal) => {
        switch (selectedMeal) {
            case 0:
                return 'desayuno';
            case 1:
                return 'comida';
            case 2:
                return 'cena';
            default:
                return 'desayuno';
        }
    };

    const handleAddFood = async (foodType) => {
        setAddingFood(true);
        try {
            const mealType = getMealType(selectedMeal);
            const dayKey = `dieta${mealType}`;

            // Crear una copia profunda del estado actual
            const updatedDieta = JSON.parse(JSON.stringify(dieta));

            // Asegurarse de que el array del día existe
            if (!updatedDieta[dayKey][selectedDay].listsstringsmini) {
                updatedDieta[dayKey][selectedDay].listsstringsmini = [];
            }

            // Verificar si el alimento ya existe
            if (updatedDieta[dayKey][selectedDay].listsstringsmini.includes(foodType.tipo)) {
                enqueueSnackbar('Este alimento ya está en la lista', {
                    variant: 'warning'
                });
                return;
            }

            // Añadir el nuevo alimento al array existente
            updatedDieta[dayKey][selectedDay].listsstringsmini.push(foodType.tipo);

            // Actualizar estado local
            setDieta(updatedDieta);

            // Actualizar Firestore
            const dietaRef = doc(db, 'Dieta_cliente', dieta.id);
            await updateDoc(dietaRef, {
                [`${dayKey}.${selectedDay}.listsstringsmini`]: updatedDieta[dayKey][selectedDay].listsstringsmini
            });

            // Actualizar array cambiardieta según el tipo de profesional
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const trainerRef = doc(db, 'userDetails', userDetails.uid);
                await updateDoc(trainerRef, {
                    cambiardieta: arrayUnion(dieta.uidcliente)
                });
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                const centerRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centerRef, {
                    cambiardieta: arrayUnion(dieta.uidcliente)
                });
            }

            setOpenFoodPicker(false);
            enqueueSnackbar('Alimento añadido correctamente', {
                variant: 'success'
            });

        } catch (error) {
            console.error('Error al añadir alimento:', error);
            enqueueSnackbar('Error al añadir el alimento', {
                variant: 'error'
            });
        } finally {
            setAddingFood(false);
        }
    };

    const showConfirmationDialog = (message) => {
        return new Promise((resolve) => {
            setDeleteConfirmation({
                message,
                onConfirm: () => {
                    resolve(true);
                    setDeleteConfirmation(null);
                },
                onCancel: () => {
                    resolve(false);
                    setDeleteConfirmation(null);
                }
            });
        });
    };

    const handleDeleteFood = async (alimento, dayIndex, mealType) => {
        const shouldDelete = await showConfirmationDialog(
            `¿Estás seguro de que quieres eliminar ${alimento}?`
        );

        if (!shouldDelete) return;

        const previousState = JSON.parse(JSON.stringify(dieta));
        
        const dayKey = `dieta${mealType}`;

        try {
            setLoadingState(true);

            // Crear una copia profunda del estado actual
            const updatedDieta = JSON.parse(JSON.stringify(dieta));

            // Filtrar el alimento a eliminar
            updatedDieta[dayKey][dayIndex].listsstringsmini = 
                updatedDieta[dayKey][dayIndex].listsstringsmini.filter(item => item !== alimento);

            // Actualizar estado local
            setDieta(updatedDieta);

            // Actualizar Firestore
            const dietaRef = doc(db, 'Dieta_cliente', dieta.id);
            await updateDoc(dietaRef, {
                [`${dayKey}.${dayIndex}.listsstringsmini`]: updatedDieta[dayKey][dayIndex].listsstringsmini
            });

            // Actualizar array cambiardieta según el tipo de profesional
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const trainerRef = doc(db, 'userDetails', userDetails.uid);
                await updateDoc(trainerRef, {
                    cambiardieta: arrayUnion(dieta.uidcliente)
                });
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                const centerRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centerRef, {
                    cambiardieta: arrayUnion(dieta.uidcliente)
                });
            }

            enqueueSnackbar('Alimento eliminado correctamente', {
                variant: 'success'
            });
        } catch (error) {
            console.error('Error al eliminar alimento:', error);
            setDieta(previousState);
            enqueueSnackbar('Error al eliminar el alimento', {
                variant: 'error'
            });
        } finally {
            setLoadingState(false);
        }
    };

    useEffect(() => {
        const fetchFoodTypes = async () => {
            try {
                const sugerenciasRef = collection(db, 'sugerenciassemanales');
                const querySnapshot = await getDocs(sugerenciasRef);
                const foodTypes = new Map(); // Usamos Map para evitar duplicados

                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    // Filtrar solo tipos en español
                    if (data.tipo && data.imagen && (!data.lang || data.lang === 'esp')) {
                        foodTypes.set(data.tipo, data.imagen);
                    }
                });

                setAvailableFoodTypes(Array.from(foodTypes).map(([tipo, imagen]) => ({
                    tipo,
                    imagen
                })));
                setFoodImages(Object.fromEntries(foodTypes));
            } catch (error) {
                console.error('Error al obtener tipos de comida:', error);
            }
        };

        fetchFoodTypes();
    }, [db]);

    useEffect(() => {
        const fetchFoodImages = async () => {
            try {
                const zonasRef = collection(db, 'leezonafotos');
                const querySnapshot = await getDocs(zonasRef);
                const images = {};

                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.zona && data.imagen) {
                        images[data.zona.toLowerCase()] = data.imagen;
                    }
                });

                setFoodImages(images);
            } catch (error) {
                console.error('Error al obtener imágenes de alimentos:', error);
            }
        };

        fetchFoodImages();
    }, [db]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box p={2}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    if (!dieta) {
        return (
            <Box p={2}>
                <Alert severity="info">No hay dieta asignada para este cliente</Alert>
            </Box>
        );
    }

    const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

    const handleDayChange = (event, newValue) => {
        setSelectedDay(newValue);
    };

    const handleMealChange = (event, newValue) => {
        setSelectedMeal(newValue);
    };

    const renderMealContent = (mealType, dayIndex) => {
        const mealData = {
            0: dieta.dietadesayuno?.[dayIndex],
            1: dieta.dietacomida?.[dayIndex],
            2: dieta.dietacena?.[dayIndex]
        }[mealType];

        const ejemplosData = {
            0: dieta.ejemplosdietadesayuno?.[dayIndex],
            1: dieta.ejemplosdietacomida?.[dayIndex],
            2: dieta.ejemplosdietacena?.[dayIndex]
        }[mealType];

        if (!mealData?.listsstringsmini) return null;

        return (
            <Card variant="outlined" sx={{ mt: 2 }}>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2
                    }}>
                        <Typography variant="h6" sx={{
                            borderBottom: '2px solid #f50057',
                            pb: 1,
                            color: 'primary.main'
                        }}>
                            Alimentos recomendados
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => setOpenFoodPicker(true)}
                            size="small"
                            sx={{
                                borderRadius: '20px',
                                boxShadow: 2,
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: 3
                                }
                            }}
                        >
                            Añadir
                        </Button>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        mb: 3,
                        justifyContent: 'center'
                    }}>
                        {mealData.listsstringsmini.map((alimento, index) => {
                            // Buscar el tipo de comida en availableFoodTypes
                            const foodType = availableFoodTypes.find(
                                food => food.tipo.toLowerCase() === alimento.toLowerCase()
                            );
                            const imagenUrl = foodType?.imagen;

                            return (
                                <Card
                                    key={index}
                                    sx={{
                                        width: 150,
                                        transition: 'transform 0.2s',
                                        position: 'relative',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: 3,
                                            '& .delete-button': {
                                                opacity: 1
                                            }
                                        }
                                    }}
                                >
                                    <IconButton
                                        className="delete-button"
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteFood(alimento, selectedDay, getMealType(selectedMeal));
                                        }}
                                        disabled={loadingState}
                                        sx={{
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            backgroundColor: 'error.main',
                                            color: 'white',
                                            opacity: 0,
                                            transition: 'opacity 0.2s',
                                            zIndex: 1,
                                            '&:hover': {
                                                backgroundColor: 'error.dark'
                                            },
                                            width: 24,
                                            height: 24,
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 16
                                            }
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <CardMedia
                                        component="img"
                                        height="100"
                                        image={imagenUrl || '/path/to/default/image.jpg'}
                                        alt={alimento}
                                        sx={{ objectFit: 'cover' }}
                                    />
                                    <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                                        <Typography
                                            variant="body2"
                                            align="center"
                                            sx={{
                                                fontWeight: 500,
                                                color: 'text.primary'
                                            }}
                                        >
                                            {alimento}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            );
                        })}
                    </Box>

                    <Typography variant="h6" gutterBottom sx={{
                        borderBottom: '2px solid #f50057',
                        pb: 1,
                        mb: 2,
                        color: 'primary.main',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <span>Ejemplos de platos</span>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => setOpenSuggestionDialog(true)}
                            size="small"
                            sx={{
                                borderRadius: '20px',
                                boxShadow: 2,
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: 3
                                }
                            }}
                        >
                            Añadir ejemplo
                        </Button>
                    </Typography>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                        gap: 2,
                        mt: 2
                    }}>
                        {ejemplosData?.ejemplos?.map((ejemplo, index) => (
                            <Card
                                key={index}
                                elevation={1}
                                sx={{
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: 3,
                                        '& .delete-button': {
                                            opacity: 1
                                        }
                                    },
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative'
                                }}
                            >
                                <IconButton
                                    className="delete-button"
                                    size="small"
                                    onClick={() => {
                                        // Aquí iría la lógica para eliminar la sugerencia
                                        console.log('Eliminar sugerencia:', ejemplo);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: 5,
                                        right: 5,
                                        backgroundColor: 'error.main',
                                        color: 'white',
                                        opacity: 0,
                                        transition: 'opacity 0.2s',
                                        zIndex: 1,
                                        '&:hover': {
                                            backgroundColor: 'error.dark'
                                        },
                                        width: 24,
                                        height: 24,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 16
                                        }
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <CardContent sx={{
                                    p: 2,
                                    '&:last-child': { pb: 2 },
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1
                                    }}>
                                        <RestaurantIcon color="primary" />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontWeight: 500,
                                                color: 'text.primary'
                                            }}
                                        >
                                            {ejemplo}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </CardContent>
            </Card>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            {/* Selector de días */}
            <Box sx={{
                mb: 4,
                backgroundColor: '#f5f5f5',
                borderRadius: '16px',
                p: 1
            }}>
                <Tabs
                    value={selectedDay}
                    onChange={handleDayChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#FF5722',
                            height: '3px',
                            borderRadius: '3px'
                        },
                        '& .MuiTab-root': {
                            minWidth: '100px',
                            fontSize: '0.95rem',
                            fontWeight: 500,
                            color: '#666',
                            textTransform: 'none',
                            '&.Mui-selected': {
                                color: '#FF5722',
                                fontWeight: 600
                            }
                        }
                    }}
                >
                    {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((dia, index) => (
                        <Tab
                            key={index}
                            label={dia}
                            sx={{
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '50%',
                                    bottom: 0,
                                    width: index === selectedDay ? '100%' : '0%',
                                    height: '3px',
                                    backgroundColor: '#FF5722',
                                    transition: 'width 0.3s ease',
                                    transform: 'translateX(-50%)'
                                }
                            }}
                        />
                    ))}
                </Tabs>
            </Box>

            {/* Selector de comidas */}
            <Box sx={{
                display: 'flex',
                gap: 2,
                mb: 4
            }}>
                {[
                    { label: 'Desayuno', icon: <WbSunnyIcon />, color: '#FF9800', bgColor: '#FFF3E0' },
                    { label: 'Comida', icon: <RestaurantIcon />, color: '#4CAF50', bgColor: '#E8F5E9' },
                    { label: 'Cena', icon: <WbTwilightIcon />, color: '#2196F3', bgColor: '#E3F2FD' }
                ].map((meal, index) => (
                    <Box
                        key={index}
                        onClick={() => handleMealChange(null, index)}
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1,
                            p: 2,
                            backgroundColor: selectedMeal === index ? meal.bgColor : 'transparent',
                            border: '2px solid',
                            borderColor: selectedMeal === index ? meal.color : 'transparent',
                            borderRadius: '16px',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: meal.bgColor,
                                transform: 'translateY(-2px)'
                            }
                        }}
                    >
                        {React.cloneElement(meal.icon, {
                            sx: {
                                fontSize: 28,
                                color: selectedMeal === index ? meal.color : 'text.secondary',
                                transition: 'color 0.3s ease'
                            }
                        })}
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: selectedMeal === index ? meal.color : 'text.secondary',
                                fontWeight: selectedMeal === index ? 600 : 400,
                                transition: 'color 0.3s ease'
                            }}
                        >
                            {meal.label}
                        </Typography>
                    </Box>
                ))}
            </Box>

            <Box sx={{ p: 2 }}>
                {renderMealContent(selectedMeal, selectedDay)}
            </Box>

            {/* Food Type Picker Dialog */}
            <Dialog
                open={openFoodPicker}
                onClose={() => setOpenFoodPicker(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Seleccionar tipo de alimento
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenFoodPicker(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <List sx={{ width: '100%' }}>
                        {availableFoodTypes.map((food, index) => (
                            <ListItem
                                key={index}
                                button
                                onClick={() => handleAddFood(food)}
                                disabled={addingFood}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    gap: 2,
                                    '&:hover': {
                                        backgroundColor: 'action.hover',
                                    },
                                    borderRadius: 1,
                                    mb: 1
                                }}>
                                    <Box
                                        component="img"
                                        src={food.imagen}
                                        alt={food.tipo}
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: 1,
                                            objectFit: 'cover'
                                        }}
                                    />
                                    <Typography variant="body1">
                                        {food.tipo}
                                    </Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openSuggestionDialog}
                onClose={() => {
                    setOpenSuggestionDialog(false);
                    setNewSuggestion('');
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Añadir nuevo ejemplo de plato
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setOpenSuggestionDialog(false);
                            setNewSuggestion('');
                        }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Descripción del plato"
                        type="text"
                        fullWidth
                        value={newSuggestion}
                        onChange={(e) => setNewSuggestion(e.target.value)}
                        multiline
                        rows={3}
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenSuggestionDialog(false);
                            setNewSuggestion('');
                        }}
                        color="inherit"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            // Aquí iría la lógica para añadir la sugerencia
                            console.log('Nueva sugerencia:', newSuggestion);
                            setOpenSuggestionDialog(false);
                            setNewSuggestion('');
                        }}
                        variant="contained"
                        color="primary"
                        disabled={!newSuggestion.trim()}
                    >
                        Añadir
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={Boolean(deleteConfirmation)}
                onClose={() => deleteConfirmation?.onCancel()}
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {deleteConfirmation?.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => deleteConfirmation?.onCancel()}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => deleteConfirmation?.onConfirm()}
                        color="error"
                        autoFocus
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

// Añadir esta función antes del componente ClientDetails
const calculateAge = (birthDate) => {
    if (!birthDate) return null;
    
    // Si birthDate es un Timestamp de Firestore, convertirlo a Date
    const birthDateObj = birthDate.toDate ? birthDate.toDate() : new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    
    // Si aún no ha llegado el mes de cumpleaños, o si es el mes pero no ha llegado el día
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }
    
    return age;
};

const ClientDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [client, setClient] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [mealData, setMealData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openPlanDialog, setOpenPlanDialog] = useState(false);
    const [tempPlan, setTempPlan] = useState('');
    const [error, setError] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [analyzing, setAnalyzing] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const storage = getStorage();

    
    // Añadir estos dos nuevos estados aquí
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(null);
    const [openPhotoModal, setOpenPhotoModal] = useState(false);

    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();

    const [exerciseImages, setExerciseImages] = useState({});

    const analyzeMessages = [
        "La IA está afinando sus oídos digitales... 🎧",
        "Mis circuitos procesan cada palabra... 🤖",
        "Activando módulos de empatía artificial... 💫",
        "Analizando patrones conversacionales... 🧠",
        "Mis algoritmos toman nota velozmente... ⚡",
        "Calibrando el detector de objetivos... 🎯",
        "Procesando en modo consulta inteligente... 💭",
        "Mi IA entrena para entenderte mejor... 💪"
    ];

    const [currentMessage, setCurrentMessage] = useState(0);

    const [showNotification, setShowNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);

    const [comidas, setComidas] = useState([]);
    const [loadingComidas, setLoadingComidas] = useState(false);

    const [dieta, setDieta] = useState(null);
    const [loadingDieta, setLoadingDieta] = useState(false);

    const [rutina, setRutina] = useState(null);
    const [loadingRutina, setLoadingRutina] = useState(false);
    const [errorRutina, setErrorRutina] = useState(null);  // Añadir este estado

    const [openAddExercise, setOpenAddExercise] = useState(null);

    const [openAIModal, setOpenAIModal] = useState(false);
    const [modalType, setModalType] = useState(''); // 'diet' o 'routine'

    // Añade este estado cerca de los otros estados
    const [instruccionesTemp, setInstruccionesTemp] = useState('');
    const [guardandoInstrucciones, setGuardandoInstrucciones] = useState(false);
    const [indicacionesUsuarioTemp, setIndicacionesUsuarioTemp] = useState('');
    const [guardandoIndicaciones, setGuardandoIndicaciones] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const [expandedIA, setExpandedIA] = useState(false);
    const [expandedUser, setExpandedUser] = useState(false);

    const [loadingState, setLoadingState] = useState(false);

    // Añade este estado al inicio del componente
    const [ejerciciosData, setEjerciciosData] = useState([]);

    // Añade este estado junto a los otros estados al inicio del componente
    const [caloriasData, setCaloriasData] = useState([]);

    const [openCreateRoutineModal, setOpenCreateRoutineModal] = useState(false); // Estado para el modal

    const handleOpenCreateRoutineModal = () => {
        setOpenCreateRoutineModal(true);
    };

    const handleCloseCreateRoutineModal = () => {
        setOpenCreateRoutineModal(false);
    };

    useEffect(() => {
        let messageInterval;
        if (analyzing) {
            messageInterval = setInterval(() => {
                setCurrentMessage(prev => (prev + 1) % analyzeMessages.length);
            }, 3000);
        }
        return () => clearInterval(messageInterval);
    }, [analyzing]);

    useEffect(() => {
        const fetchClientDetails = async () => {
            if (!userDetails || !id) {
                setError("Información de usuario o ID de cliente no disponible");
                setLoading(false);
                return;
            }

            try {
                let clientDocRef;

                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
                }

                const clientDoc = await getDoc(clientDocRef);

                if (!clientDoc.exists()) {
                    throw new Error("Cliente no encontrado");
                }

                const clientData = clientDoc.data();

                // Si no hay fechanacimiento en el documento de clientes asociados
                if (!clientData.fechanacimiento) {
                    // Obtener el documento principal del usuario
                    const userDocRef = doc(db, 'userDetails', clientData.uidcliente);
                    const userDoc = await getDoc(userDocRef);

                    if (userDoc.exists()) {
                        const userData = userDoc.data();

                        // Si encontramos la fecha de nacimiento en el documento principal
                        if (userData.fechanacimiento) {
                            // Actualizar el documento en clientes asociados
                            await updateDoc(clientDocRef, {
                                fechanacimiento: userData.fechanacimiento
                            });

                            // Actualizar clientData con la fecha de nacimiento
                            clientData.fechanacimiento = userData.fechanacimiento;
                        }
                    }
                }

                // Si existe una URL de PDF almacenada, obtener la URL de descarga
                if (clientData.url_pdf_sesion) {
                    try {
                        const storage = getStorage();
                        const pdfRef = ref(storage, clientData.url_pdf_sesion);
                        const downloadURL = await getDownloadURL(pdfRef);
                        setPdfUrl(downloadURL);
                    } catch (error) {
                        console.error('Error al obtener la URL del PDF:', error);
                    }
                }

                setClient({
                    ...clientData,
                    id: id
                });

            } catch (error) {
                console.error("Error al obtener detalles del cliente:", error);
                setError("Error al cargar los detalles del cliente");
            } finally {
                setLoading(false);
            }
        };

        fetchClientDetails();
    }, [id, userDetails, db]);

    // Mover fetchDieta fuera del useEffect para poder reutilizarla
    const fetchDieta = async () => {
        if (!client?.uidcliente) {
            console.log('No hay uidcliente disponible:', client);
            return;
        }

        setLoadingDieta(true);
        try {
            let q = query(
                collection(db, 'Dieta_cliente'),
                where('uidcliente', '==', client.uidcliente)
            );

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                q = query(q, where('IDPT', '==', userDetails.IDPT));
                console.log('Buscando con IDPT:', userDetails.IDPT);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                q = query(q, where('IDBO', '==', userDetails.IDBO));
                console.log('Buscando con IDBO:', userDetails.IDBO);
            }

            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const dietaData = querySnapshot.docs[0].data();
                console.log('Dieta encontrada:', dietaData);
                setDieta(dietaData);
            } else {
                console.log('No se encontró dieta para este cliente');
                setDieta(null);
            }
        } catch (error) {
            console.error('Error al obtener la dieta:', error);
            setError('Error al cargar la dieta del cliente');
        } finally {
            setLoadingDieta(false);
        }
    };

    // Usar fetchDieta en el useEffect
    useEffect(() => {
        if (client) {
            fetchDieta();
        }
    }, [client, userDetails, db]);

    // Mover la definición de fetchRutina aquí
    const fetchRutina = async () => {
        if (!client?.uidcliente) {
            console.log('No hay uidcliente disponible:', client);
            return;
        }
        setLoadingRutina(true);
        try {
            // Construir la consulta base
            let q = query(
                collection(db, 'Rutina_cliente'),
                where('uidcliente', '==', client.uidcliente),
                orderBy('hora_creacion', 'desc'), // Ordenar por fecha de creación descendente
                limit(1) // Obtener solo el más reciente
            );

            // Añadir filtro adicional según tipo de profesional
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                q = query(
                    collection(db, 'Rutina_cliente'),
                    where('uidcliente', '==', client.uidcliente),
                    where('IDPT', '==', userDetails.IDPT),
                    orderBy('hora_creacion', 'desc'),
                    limit(1)
                );
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                q = query(
                    collection(db, 'Rutina_cliente'),
                    where('uidcliente', '==', client.uidcliente),
                    where('IDBO', '==', userDetails.IDBO),
                    orderBy('hora_creacion', 'desc'),
                    limit(1)
                );
            }

            console.log('Query params:', {
                uidcliente: client.uidcliente,
                tipo_profesional: userDetails.tipo_profesional,
                IDPT: userDetails.IDPT,
                IDBO: userDetails.IDBO
            });

            const querySnapshot = await getDocs(q);

            console.log('Documentos encontrados:', querySnapshot.size);

            if (!querySnapshot.empty) {
                const rutinaData = querySnapshot.docs[0].data();
                console.log('Rutina encontrada:', rutinaData);
                setRutina(rutinaData);
            } else {
                console.log('No se encontró rutina para este cliente');
                setRutina(null);
            }
        } catch (error) {
            console.error('Error al obtener la rutina:', error);
            setErrorRutina('Error al cargar la rutina del cliente');
        } finally {
            setLoadingRutina(false);
        }
    };

    useEffect(() => {
        const fetchRutina = async () => {
            if (!client?.uidcliente) {
                console.log('No hay uidcliente disponible:', client);
                return;
            }
            setLoadingRutina(true);
            try {
                let q = query(
                    collection(db, 'Rutina_cliente'),
                    where('uidcliente', '==', client.uidcliente)
                );

                // Añadir filtro adicional según tipo de profesional
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    q = query(q, where('IDPT', '==', userDetails.IDPT));
                    console.log('Buscando con IDPT:', userDetails.IDPT);
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    q = query(q, where('IDBO', '==', userDetails.IDBO));
                    console.log('Buscando con IDBO:', userDetails.IDBO);
                }

                console.log('Query params:', {
                    uidcliente: client.uidcliente,
                    tipo_profesional: userDetails.tipo_profesional,
                    IDPT: userDetails.IDPT,
                    IDBO: userDetails.IDBO
                });

                const querySnapshot = await getDocs(q);

                console.log('Documentos encontrados:', querySnapshot.size);

                if (!querySnapshot.empty) {
                    const rutinaData = querySnapshot.docs[0].data();
                    console.log('Rutina encontrada:', rutinaData);
                    setRutina(rutinaData);
                } else {
                    console.log('No se encontró rutina para este cliente');
                    setRutina(null);
                }
            } catch (error) {
                console.error('Error al obtener la rutina:', error);
                setErrorRutina('Error al cargar la rutina del cliente');
            } finally {
                setLoadingRutina(false);
            }
        };

        if (client) {
            fetchRutina();
        }
    }, [client, userDetails, db]);

    useEffect(() => {
        const fetchExerciseImages = async () => {
            try {
                const zonasRef = collection(db, 'leezonafotos');
                const querySnapshot = await getDocs(zonasRef);
                const images = {};

                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.zona && data.imagen) {
                        images[data.zona.toLowerCase()] = data.imagen;
                    }
                });

                setExerciseImages(images);
            } catch (error) {
                console.error('Error al obtener imágenes de ejercicios:', error);
            }
        };

        fetchExerciseImages();
    }, [db]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleDeleteClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteClientService(userDetails, id);
            setOpenDialog(false);
            navigate('/clients');
        } catch (error) {
            alert(`Error al eliminar el cliente: ${error.message}`);
        }
    };

    const handleEditClick = () => {
        setEditMode(!editMode);
    };

    const handleSaveClick = async () => {
        try {
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
            }

            // Crear un objeto con solo los campos que tienen valor
            const updateData = {};
            
            // Campos de texto normales
            const textFields = ['nombre', 'apellidos', 'sexo', 'tipodieta', 'notes'];
            textFields.forEach(field => {
                if (client[field] !== undefined && client[field] !== null) {
                    updateData[field] = client[field];
                }
            });

            // Campos numéricos (objetivos)
            const numericFields = [
                'peso',
                'objetivoagua',
                'objetivocalorias',
                'objetivograsas',
                'objetivohidratos',
                'objetivoprote',
                'objetivotiempo',
                'objetivopasos'
            ];
            
            numericFields.forEach(field => {
                if (client[field] !== undefined && client[field] !== null && client[field] !== '') {
                    updateData[field] = Number(client[field]);
                }
            });

            // Actualizar solo los campos que tienen valor
            await updateDoc(clientDocRef, updateData);

            // Añadir el ID al array cambiarobjetivos del cliente
            const userRef = doc(db, 'userDetails', client.uidcliente);
            await updateDoc(userRef, {
                cambiarobjetivos: arrayUnion(client.uidcliente)
            });

            // Añadir el ID al array cambiarobjetivos del entrenador/centro
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                // Para entrenador independiente
                const trainerRef = doc(db, 'userDetails', userDetails.uid);
                await updateDoc(trainerRef, {
                    cambiarobjetivos: arrayUnion(client.uidcliente)
                });
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                // Para centro
                const centerRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centerRef, {
                    cambiarobjetivos: arrayUnion(dieta.uidcliente)
                });
            }

            setEditMode(false);
            enqueueSnackbar('Datos actualizados correctamente', {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });

        } catch (error) {
            console.error('Error al guardar los cambios:', error);
            enqueueSnackbar('Error al guardar los cambios', {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
        }
    };

    const handlePlanChange = () => {
        // Eliminar la validación que bloqueaba el cambio a Pro
        setTempPlan(client.plan === 'Free' ? 'Pro' : 'Free');
        setOpenPlanDialog(true);
    };

    const handleConfirmPlanChange = async () => {
        try {
            let clientDocRef;
            let userDocRef;

            // Primero obtenemos la referencia al documento del cliente en la subcolección correspondiente
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('ID del Centro (IDBO) no disponible');
                }
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
            }

            // Obtenemos el documento para sacar el uidcliente
            const clientDoc = await getDoc(clientDocRef);
            if (!clientDoc.exists()) {
                throw new Error('Cliente no encontrado');
            }

            const clientData = clientDoc.data();
            const uidCliente = clientData.uidcliente;

            if (!uidCliente) {
                throw new Error('UID del cliente no encontrado');
            }

            // Ahora sí actualizamos el plan en userDetails usando el uidcliente correcto
            userDocRef = doc(db, 'userDetails', uidCliente);
            await updateDoc(userDocRef, { plan: tempPlan });

            // También actualizamos el plan en la subcolección
            await updateDoc(clientDocRef, { plan: tempPlan });

            // Actualizamos el estado local
            setClient(prevClient => ({ ...prevClient, plan: tempPlan }));
            setOpenPlanDialog(false);

        } catch (error) {
            console.error("Error al actualizar el plan:", error);
            alert(`Error al actualizar el plan: ${error.message}`);
        }
    };

    const handleClosePlanDialog = () => {
        setOpenPlanDialog(false);
    };

    const fitaiGradient = 'linear-gradient(45deg, #FF5722 30%, #FF1493 90%)';

    const paperStyle = {
        p: 3,
        borderRadius: 4,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
        },
    };

    const goalCardStyle = {
        p: 2,
        textAlign: 'center',
        borderRadius: 4,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
        },
    };

    const iconStyle = {
        fontSize: 40,
        mb: 1,
        color: 'primary.main',
    };

    const textFieldStyle = {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
        },
    };

    const renderMealPlan = (mealPlan) => {
        const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
        const meals = ['Desayuno', 'Comida', 'Cena'];

        return (
            <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 600, boxShadow: 3 }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="plan de comidas semanal">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{
                                fontWeight: 'bold',
                                backgroundColor: 'primary.main',
                                color: 'white',
                                fontSize: '1.1rem',
                                width: '14%'
                            }}>
                                Comida
                            </TableCell>
                            {days.map((day) => (
                                <TableCell key={day} align="center" sx={{
                                    fontWeight: 'bold',
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    fontSize: '1.1rem'
                                }}>
                                    {day}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {meals.map((meal, index) => (
                            <TableRow key={meal} sx={{
                                '&:nth-of-type(odd)': { backgroundColor: 'action.hover' },
                                '&:hover': { backgroundColor: 'action.selected' }
                            }}>
                                <TableCell component="th" scope="row" sx={{
                                    fontWeight: 'bold',
                                    backgroundColor: 'primary.light',
                                    color: 'primary.contrastText'
                                }}>
                                    {meal}
                                </TableCell>
                                {mealPlan[index].map((dayMeals, dayIndex) => (
                                    <TableCell key={`${meal}-${days[dayIndex]}`} align="left" sx={{
                                        p: 1,
                                        '& > *:not(:last-child)': { mb: 0.5 }
                                    }}>
                                        {dayMeals.map((item, itemIndex) => (
                                            <Typography key={itemIndex} variant="body2" sx={{
                                                backgroundColor: 'background.paper',
                                                p: 0.5,
                                                borderRadius: 1,
                                                boxShadow: 1
                                            }}>
                                                {item}
                                            </Typography>
                                        ))}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    // Ejemplo de dieta semanal
    const sampleDiet = {
        dietadesayuno: [
            ['Avena con frutas', 'Yogur natural'],
            ['Tostadas integrales con aguacate', 'Huevos revueltos'],
            ['Batido de proteínas', 'Plátano'],
            ['Tortilla de espinacas', 'Zumo de naranja'],
            ['Gachas de avena con manzana y canela'],
            ['Pan integral con pavo y queso fresco'],
            ['Cereales integrales con leche de almendras']
        ],
        dietacomida: [
            ['Pechuga de pollo a la plancha', 'Ensalada mixta', 'Arroz integral'],
            ['Salmón al horno', 'Brócoli al vapor', 'Quinoa'],
            ['Lentejas con verduras', 'Pechuga de pavo a la plancha'],
            ['Pasta integral con atún y tomate', 'Ensalada verde'],
            ['Garbanzos con espinacas', 'Pechuga de pollo a la plancha'],
            ['Merluza al horno', 'Puré de patata', 'Judías verdes'],
            ['Arroz con verduras', 'Filete de ternera a la plancha']
        ],
        dietacena: [
            ['Sopa de verduras', 'Tortilla francesa'],
            ['Ensalada de atún', 'Yogur griego'],
            ['Revuelto de champiñones', 'Tostada integral'],
            ['Pescado blanco a la plancha', 'Verduras al vapor'],
            ['Pavo a la plancha', 'Ensalada de tomate'],
            ['Crema de calabacín', 'Tortilla de claras'],
            ['Ensalada de pollo', 'Fruta']
        ]
    };

    // Nuevo componente Exercise
    const Exercise = ({ exercise, dayIndex, exerciseIndex, onDelete, onReplace, loading }) => {
        const [showActions, setShowActions] = useState(false);
        const [openReplacePicker, setOpenReplacePicker] = useState(false);
        const [deleteConfirmation, setDeleteConfirmation] = useState(null);
        const [openDetailModal, setOpenDetailModal] = useState(false);
        const imagenUrl = exerciseImages[exercise.Zona?.toLowerCase()];

        const showConfirmationDialog = (message) => {
            return new Promise((resolve) => {
                setDeleteConfirmation({
                    message,
                    onConfirm: () => {
                        resolve(true);
                        setDeleteConfirmation(null);
                    },
                    onCancel: () => {
                        resolve(false);
                        setDeleteConfirmation(null);
                    }
                });
            });
        };

        const handleDelete = async (e) => {
            e.stopPropagation(); // Prevenir la propagación del evento
            const shouldDelete = await showConfirmationDialog(
                `¿Estás seguro de que quieres eliminar ${exercise.Nombre}?`
            );

            if (shouldDelete) {
                onDelete(exercise, dayIndex);
            }
        };

        const handleReplace = async (newExercise) => {
            onReplace(exercise, newExercise, dayIndex);
            setOpenReplacePicker(false);
        };

        if (exercise.Nombre === "Descanso") {
            return (
                <Card sx={{
                    mb: 2,
                    backgroundColor: 'grey.100',
                    borderRadius: '15px',
                    overflow: 'hidden'
                }}>
                    <CardContent>
                        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                            Día de Descanso
                        </Typography>
                    </CardContent>
                </Card>
            );
        }

        return (
            <>
                <Card
                    sx={{
                        mb: 2,
                        borderRadius: '15px',
                        overflow: 'hidden',
                        border: '1px solid #eee',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            width: '4px',
                            backgroundColor: '#FF7F50',
                            borderTopLeftRadius: '15px',
                            borderBottomLeftRadius: '15px'
                        },
                        '&:hover .exercise-actions': {
                            opacity: 1
                        }
                    }}
                    onClick={() => setOpenDetailModal(true)}
                    onMouseEnter={() => setShowActions(true)}
                    onMouseLeave={() => setShowActions(false)}
                >
                    {/* Botones de acción */}
                    <Box
                        className="exercise-actions"
                        sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            display: 'flex',
                            gap: 1,
                            opacity: 0,
                            transition: 'opacity 0.3s ease',
                            zIndex: 2,
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            borderRadius: '10px',
                            padding: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => setOpenReplacePicker(true)}
                            startIcon={<SwapHorizIcon />}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'primary.dark'
                                },
                                textTransform: 'none',
                                fontSize: '0.9rem',
                                px: 2
                            }}
                        >
                            Reemplazar
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            onClick={handleDelete}
                            disabled={loading}
                            sx={{
                                backgroundColor: 'error.main',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'error.dark'
                                },
                                textTransform: 'none',
                                fontSize: '0.9rem',
                                px: 2
                            }}
                        >
                            Eliminar
                        </Button>
                    </Box>

                    {/* Contenido del ejercicio */}
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                                {imagenUrl && (
                                    <Box
                                        component="img"
                                        src={imagenUrl}
                                        alt={exercise.Zona}
                                        sx={{
                                            width: '80px',
                                            height: '80px',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                            border: '1px solid #eee'
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: '#FF7F50',
                                            fontWeight: 'bold',
                                            fontSize: '1.5rem'
                                        }}
                                    >
                                        {exercise.Nombre}
                                    </Typography>
                                    <Chip
                                        label="Cardio"
                                        sx={{
                                            backgroundColor: '#2196F3',
                                            color: 'white',
                                            borderRadius: '20px',
                                            fontWeight: 500
                                        }}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        backgroundColor: 'rgba(255, 127, 80, 0.1)',
                                        p: 2,
                                        borderRadius: '10px',
                                        mb: 2
                                    }}
                                >
                                    <Typography sx={{ color: '#666' }}>
                                        {exercise.Nombre_extenso}
                                    </Typography>
                                </Box>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                            {exercise.Series > 0 && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Typography variant="body2" sx={{ color: '#666', fontWeight: 'bold' }}>
                                                        Series:
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: '#666' }}>
                                                        {exercise.Series}
                                                    </Typography>
                                                </Box>
                                            )}
                                            {exercise.Descanso && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Typography variant="body2" sx={{ color: '#666', fontWeight: 'bold' }}>
                                                        Descanso:
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: '#666' }}>
                                                        {exercise.Descanso / 1000} segundos
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography variant="body2" sx={{ color: '#666', fontWeight: 'bold' }}>
                                                    Zona:
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#666' }}>
                                                    {exercise.Zona}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography variant="body2" sx={{ color: '#666', fontWeight: 'bold' }}>
                                                    Zona específica:
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#666' }}>
                                                    {exercise.Zonadeverdad}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>

                <ExerciseDetailModal
                    open={openDetailModal}
                    onClose={() => setOpenDetailModal(false)}
                    exercise={exercise}
                />

                {/* Dialog para reemplazar ejercicio */}
                <Dialog
                    open={openReplacePicker}
                    onClose={() => setOpenReplacePicker(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        Reemplazar ejercicio: {exercise.Nombre}
                        <IconButton
                            onClick={() => setOpenReplacePicker(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <ExercisePicker
                            onSelect={(newExercise) => {
                                handleReplace(newExercise);
                            }}
                            dayIndex={dayIndex}
                        />
                    </DialogContent>
                </Dialog>

                {/* Dialog de confirmación de eliminación */}
                <Dialog
                    open={Boolean(deleteConfirmation)}
                    onClose={() => deleteConfirmation?.onCancel()}
                >
                    <DialogTitle>Confirmar eliminación</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {deleteConfirmation?.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => deleteConfirmation?.onCancel()}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={() => deleteConfirmation?.onConfirm()}
                            color="error"
                            autoFocus
                        >
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    // Modificar la función renderExercise para usar el nuevo componente
    const renderExercise = (exercise, dayIndex, exerciseIndex) => {
        return (
            <Box key={exerciseIndex}>
                <Exercise
                    exercise={exercise}
                    dayIndex={dayIndex}
                    exerciseIndex={exerciseIndex}
                    onDelete={handleDeleteExercise}
                    onReplace={handleReplaceExercise}
                    loading={loadingState}
                />
            </Box>
        );
    };

    // Nuevo componente para el selector de ejercicios
    const ExercisePicker = ({ onSelect, dayIndex }) => {
        const [exercises, setExercises] = useState({});
        const [loading, setLoading] = useState(true);
        const [selectedZone, setSelectedZone] = useState(null);
        const db = getFirestore();

        useEffect(() => {
            const fetchExercises = async () => {
                try {
                    const barSnapshot = await getDocs(collection(db, 'BAR'));
                    console.log('Total de ejercicios en BAR:', barSnapshot.size);
                    const exercisesByZone = {};

                    const zonasSnapshot = await getDocs(collection(db, 'leezonafotos'));
                    console.log('Total de zonas en leezonafotos:', zonasSnapshot.size);

                    const zonasImages = {};
                    zonasSnapshot.forEach(doc => {
                        const data = doc.data();
                        if (data.zona && data.imagen) {
                            zonasImages[data.zona.toLowerCase()] = data.imagen;
                        }
                    });

                    barSnapshot.forEach(doc => {
                        const data = doc.data();
                        if (data.Ejercicios && data.Zona) {
                            const zona = data.Zona;
                            if (!exercisesByZone[zona]) {
                                const zonaLower = zona.toLowerCase();
                                exercisesByZone[zona] = {
                                    exercises: [],
                                    image: zonasImages[zonaLower]
                                };
                            }
                            exercisesByZone[zona].exercises.push({
                                nombre: data.Ejercicios,
                                zona: data.Zona,
                                nombre_extenso: data.Nombre_extenso
                            });
                        }
                    });

                    setExercises(exercisesByZone);
                } catch (error) {
                    console.error('Error al cargar ejercicios:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchExercises();
        }, [db]);

        const handleExerciseSelect = (exercise) => {
            onSelect(exercise);
        };

        if (loading) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <CircularProgress />
                </Box>
            );
        }

        if (!selectedZone) {
            // Vista de selección de zona
            return (
                <Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        pb: 1
                    }}>
                        <FitnessCenterIcon sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="h6">
                            Selecciona una zona muscular
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {Object.entries(exercises).map(([zona, data]) => (
                            <Grid item xs={12} sm={6} md={4} key={zona}>
                                <Card
                                    onClick={() => setSelectedZone(zona)}
                                    sx={{
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <Box sx={{ position: 'relative' }}>
                                        <CardMedia
                                        component="img"
                                        height="140"
                                        image={data.image || '/path/to/default/image.jpg'}
                                        alt={zona}
                                        sx={{ objectFit: 'cover' }}
                                    />
                                        <Box sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            bgcolor: 'rgba(0, 0, 0, 0.6)',
                                            color: 'white',
                                            p: 1
                                        }}>
                                            <Typography variant="subtitle1">
                                                {zona}
                                            </Typography>
                                            <Typography variant="body2">
                                                {data.exercises.length} ejercicios
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            );
        }

        // Vista de ejercicios de la zona seleccionada
        return (
            <Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    pb: 1
                }}>
                    <IconButton
                        onClick={() => setSelectedZone(null)}
                        sx={{ mr: 1 }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">
                        {selectedZone}
                    </Typography>
                </Box>
                <List sx={{ width: '100%' }}>
                    {exercises[selectedZone].exercises.map((exercise, index) => (
                        <ListItem
                            key={index}
                            button
                            onClick={() => handleExerciseSelect(exercise)}
                            sx={{
                                borderRadius: 1,
                                mb: 1,
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                    backgroundColor: 'action.hover',
                                    transform: 'translateX(4px)'
                                }
                            }}
                        >
                            <ListItemIcon>
                                <FitnessCenterIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={exercise.nombre}
                                secondary={`Zona: ${exercise.zona}`}
                            />
                            <ChevronRightIcon color="action" />
                        </ListItem>
                    ))}
                </List>
            </Box>
        );
    };

    const renderRoutine = () => {
        if (loadingRutina) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            );
        }

        if (errorRutina) {
            return (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {errorRutina}
                </Alert>
            );
        }

        if (!rutina) {
            return (
                <Alert severity="info" sx={{ mt: 2 }}>
                    No hay rutina asignada para este cliente
                </Alert>
            );
        }

        const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
        const rutinas = [
            rutina.rutinalunes || [],
            rutina.rutinamartes || [],
            rutina.rutinamiercoles || [],
            rutina.rutinajueves || [],
            rutina.rutinaviernes || [],
            rutina.rutinasabado || [],
            rutina.rutinadomingo || []
        ];

        return (
            <Box sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenAIModal(true)}
                        startIcon={<EditIcon />}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleOpenCreateRoutineModal}
                        startIcon={<PictureAsPdfIcon />}
                    >
                        Crear Rutina PDF
                    </Button>
                </Box>
                {dias.map((dia, index) => (
                    <Accordion
                        key={dia}
                        defaultExpanded={rutinas[index].length > 0 && rutinas[index][0].Nombre !== "Descanso"}
                        sx={{
                            mb: 2,
                            backgroundColor: 'white',
                            '&:before': { display: 'none' },
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            borderRadius: '15px !important',
                            overflow: 'hidden'
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#FF7F50' }} />}
                            sx={{
                                backgroundColor: '#FFF5F2',
                                '& .MuiAccordionSummary-content': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }
                            }}
                        >
                            <FitnessCenterIcon sx={{ color: '#FF7F50' }} />
                            <Typography
                                variant="h6"
                                sx={{
                                    color: 'black',
                                    fontWeight: 500
                                }}
                            >
                                {dia}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 2 }}>
                            {rutinas[index].map((exercise, exerciseIndex) => (
                                renderExercise(exercise, index, exerciseIndex)
                            ))}

                            {/* Botón para añadir ejercicio */}
                            <Button
                                variant="outlined"
                                startIcon={<AddIcon />}
                                onClick={() => setOpenAddExercise(index)}
                                sx={{
                                    mt: 2,
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderRadius: '15px',
                                    height: '60px',
                                    '&:hover': {
                                        borderStyle: 'dashed',
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                Añadir ejercicio
                            </Button>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Dialog
                    open={openAddExercise !== null}
                    onClose={() => setOpenAddExercise(null)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        Añadir ejercicio
                        <IconButton
                            onClick={() => setOpenAddExercise(null)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <ExercisePicker
                            dayIndex={openAddExercise}
                            onSelect={(exercise) => handleAddExercise(exercise, openAddExercise)}
                        />
                    </DialogContent>
                </Dialog>
            </Box>
        );
    };

    // Función para obtener las comidas del día seleccionado
    const fetchMealData = async (date) => {
        if (!client?.uidcliente) return;

        setLoadingComidas(true);
        try {
            // Crear fecha inicio y fin para el filtro
            const startOfDay = new Date(date);
            startOfDay.setHours(0, 0, 0, 0);
            const endOfDay = new Date(date);
            endOfDay.setHours(23, 59, 59, 999);

            const comidasRef = collection(db, 'comidacoleccion');
            const q = query(
                comidasRef,
                where('idusuario', '==', client.uidcliente),
                where('tiempodeconsumo', '>=', startOfDay),
                where('tiempodeconsumo', '<=', endOfDay)
            );

            const querySnapshot = await getDocs(q);
            const comidasData = await Promise.all(querySnapshot.docs.map(async doc => {
                const data = doc.data();
                let photoUrl = null;
                
                // Si existe url_photo, obtener la URL de descarga
                if (data.url_photo) {
                    try {
                        const photoRef = ref(storage, data.url_photo);
                        photoUrl = await getDownloadURL(photoRef);
                    } catch (error) {
                        console.error('Error al obtener URL de la foto:', error);
                    }
                }

                return {
                    id: doc.id,
                    ...data,
                    url_photo: photoUrl
                };
            }));

            setComidas(comidasData);

            // Calcular totales del día
            const totales = comidasData.reduce((acc, comida) => ({
                calorias: acc.calorias + (comida.calorias || 0),
                proteinas: acc.proteinas + (comida.gramosprote || 0),
                grasas: acc.grasas + (comida.gramosgrasa || 0),
                carbohidratos: acc.carbohidratos + (comida.gramoscarbs || 0)
            }), { calorias: 0, proteinas: 0, grasas: 0, carbohidratos: 0 });

            setMealData({
                fecha: format(date, 'yyyy-MM-dd'),
                ...totales,
                comidas: comidasData
            });

        } catch (error) {
            console.error('Error al obtener comidas:', error);
            setMealData(null);
        } finally {
            setLoadingComidas(false);
        }
    };

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
        fetchMealData(newDate);
    };

    const renderMealInfo = () => {
        if (loadingComidas) {
            return <CircularProgress />;
        }

        const comidasValidas = mealData?.comidas?.filter(comida =>
            comida.comida &&
            (comida.calorias || comida.gramosprote || comida.gramosgrasa || comida.gramoscarbs)
        ) || [];

        if (comidasValidas.length === 0) {
            return (
                <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
                    No hay registros de comidas para este día.
                </Typography>
            );
        }

        const nutrientInfo = [
            {
                label: 'Calorías',
                value: mealData.calorias,
                unit: 'kcal',
                icon: <FiZap size={24} />,
                color: '#FF5722',
                gradient: 'linear-gradient(135deg, #FF5722 0%, #FF9800 100%)',
                bgLight: 'rgba(255, 87, 34, 0.1)'
            },
            {
                label: 'Proteínas',
                value: mealData.proteinas,
                unit: 'g',
                icon: <FiTarget size={24} />,
                color: '#2196F3',
                gradient: 'linear-gradient(135deg, #2196F3 0%, #4CAF50 100%)',
                bgLight: 'rgba(33, 150, 243, 0.1)'
            },
            {
                label: 'Grasas',
                value: mealData.grasas,
                unit: 'g',
                icon: <FiDroplet size={24} />,
                color: '#FFC107',
                gradient: 'linear-gradient(135deg, #FFC107 0%, #FF9800 100%)',
                bgLight: 'rgba(255, 193, 7, 0.1)'
            },
            {
                label: 'Carbohidratos',
                value: mealData.carbohidratos,
                unit: 'g',
                icon: <FiHexagon size={24} />,
                color: '#4CAF50',
                gradient: 'linear-gradient(135deg, #4CAF50 0%, #8BC34A 100%)',
                bgLight: 'rgba(76, 175, 80, 0.1)'
            }
        ];

        return (
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={2} sx={{ mb: 4 }}>
                    {nutrientInfo.map((nutrient) => (
                        <Grid item xs={6} key={nutrient.label}>
                            <Box
                                sx={{
                                    background: nutrient.bgLight,
                                    borderRadius: '16px',
                                    p: 2,
                                    height: '100%',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                        '& .nutrient-icon': {
                                            transform: 'scale(1.1)',
                                        }
                                    },
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '4px',
                                        height: '100%',
                                        background: nutrient.gradient
                                    }
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: 1
                                }}>
                                    <Box
                                        className="nutrient-icon"
                                        sx={{
                                            background: nutrient.gradient,
                                            borderRadius: '12px',
                                            p: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: 'transform 0.3s ease',
                                            mr: 1
                                        }}
                                    >
                                        {React.cloneElement(nutrient.icon, {
                                            sx: {
                                                fontSize: 24,
                                                color: 'white'
                                            }
                                        })}
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: 'text.secondary',
                                            fontWeight: 500
                                        }}
                                    >
                                        {nutrient.label}
                                    </Typography>
                                </Box>
                                <Box sx={{ pl: 1 }}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 'bold',
                                            background: nutrient.gradient,
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            display: 'inline-block'
                                        }}
                                    >
                                        {Math.round(nutrient.value)}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{
                                            ml: 0.5,
                                            color: 'text.secondary',
                                            fontWeight: 500
                                        }}
                                    >
                                        {nutrient.unit}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                <Typography variant="h6" sx={{ mb: 2 }}>Comidas del día</Typography>
                {comidasValidas.map((comida, index) => (
                    <Paper key={index} elevation={2} sx={{ mb: 2, overflow: 'hidden' }}>
                        <Grid container alignItems="center">
                            {/* Imagen asociada (si existe) */}
                            {comida.imagenasociada && (
                                <Grid item xs={2} sm={1}>
                                    <img
                                        src={comida.imagenasociada}
                                        alt={comida.comida}
                                        style={{
                                            width: '100%',
                                            height: '60px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Grid>
                            )}
                            
                            {/* Contenido principal */}
                            <Grid item xs={comida.imagenasociada ? 8 : 10} sm={comida.imagenasociada ? 9 : 10}>
                                <Box sx={{ p: 2 }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        {comida.comida}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {comida.tiempodeconsumo ? format(comida.tiempodeconsumo.toDate(), 'HH:mm') : ''}
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                        {comida.calorias > 0 && (
                                            <Chip
                                                label={`${comida.calorias} kcal`}
                                                size="small"
                                                sx={{ mr: 1, mb: 1 }}
                                            />
                                        )}
                                        {comida.gramosprote > 0 && (
                                            <Chip
                                                label={`P: ${comida.gramosprote}g`}
                                                size="small"
                                                color="primary"
                                                sx={{ mr: 1, mb: 1 }}
                                            />
                                        )}
                                        {comida.gramosgrasa > 0 && (
                                            <Chip
                                                label={`G: ${comida.gramosgrasa}g`}
                                                size="small"
                                                color="secondary"
                                                sx={{ mr: 1, mb: 1 }}
                                            />
                                        )}
                                        {comida.gramoscarbs > 0 && (
                                            <Chip
                                                label={`C: ${comida.gramoscarbs}g`}
                                                size="small"
                                                color="info"
                                                sx={{ mb: 1 }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Grid>

                            {/* Foto del usuario (si existe) */}
                            {comida.url_photo && (
                                <Grid item xs={2} sm={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <IconButton onClick={() => { setSelectedPhotoUrl(comida.url_photo); setOpenPhotoModal(true); }}>
                                        <Avatar
                                            src={comida.url_photo}
                                            alt="Foto de la comida"
                                            sx={{ 
                                                width: 56, 
                                                height: 56,
                                                border: '2px solid #eee'
                                            }}
                                        />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                ))}

                {/* Modal para mostrar la imagen ampliada */}
                <Dialog
                    open={openPhotoModal}
                    onClose={() => setOpenPhotoModal(false)}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogContent>
                        <img
                            src={selectedPhotoUrl}
                            alt="Comida"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </DialogContent>
                </Dialog>
            </Box>
        );
    };

    // Datos de ejemplo para las nuevas gráficas
    const sampleWeeklyData = [
        { day: 'Lun', peso: 70, pasos: 8000, calorias: 2100 },
        { day: 'Mar', peso: 69.8, pasos: 10000, calorias: 2000 },
        { day: 'Mié', peso: 69.5, pasos: 7500, calorias: 2200 },
        { day: 'Jue', peso: 69.3, pasos: 9000, calorias: 1900 },
        { day: 'Vie', peso: 69, pasos: 11000, calorias: 2150 },
        { day: 'Sáb', peso: 68.8, pasos: 6000, calorias: 2300 },
        { day: 'Dom', peso: 68.5, pasos: 5000, calorias: 2250 },
    ];

    const renderSchedule = () => {
        const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
        const scheduleData = [
            { day: 'Lunes', time: '10:00', activity: 'Entrenamiento personal', type: 'fitness' },
            { day: 'Martes', time: '15:00', activity: 'Consulta nutricional online', type: 'nutrition-online' },
            { day: 'Jueves', time: '11:00', activity: 'Clase de grupo', type: 'fitness' },
            { day: 'Viernes', time: '17:00', activity: 'Consulta nutricional presencial', type: 'nutrition-inperson' },
            { day: 'Sábado', time: '09:00', activity: 'Entrenamiento personal', type: 'fitness' },
        ];

        const getActivityIcon = (type) => {
            switch (type) {
                case 'fitness':
                    return <FitnessCenterIcon sx={{ color: '#4CAF50' }} />;
                case 'nutrition-online':
                    return <VideocamIcon sx={{ color: '#2196F3' }} />;
                case 'nutrition-inperson':
                    return <RestaurantIcon sx={{ color: '#FF9800' }} />;
                default:
                    return <PersonIcon sx={{ color: '#9E9E9E' }} />;
            }
        };

        return (
            <TableContainer component={Paper} sx={{ mt: 2, borderRadius: 2, boxShadow: 3 }}>
                <Table aria-label="horario del cliente">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'primary.main' }}>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Día</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Hora</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actividad</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {daysOfWeek.map((day) => {
                            const activities = scheduleData.filter(item => item.day === day);
                            return activities.length > 0 ? (
                                activities.map((activity, index) => (
                                    <TableRow key={`${day}-${index}`} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}>
                                        <TableCell component="th" scope="row">{day}</TableCell>
                                        <TableCell>{activity.time}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {getActivityIcon(activity.type)}
                                                <Typography sx={{ ml: 1 }}>{activity.activity}</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow key={day}>
                                    <TableCell component="th" scope="row">{day}</TableCell>
                                    <TableCell colSpan={2} align="center">
                                        <Typography sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                                            No hay actividades programadas
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const sectionTitleStyle = {
        fontSize: '2rem',
        color: 'text.primary',
        mb: 4,
        fontWeight: 'bold',
        borderBottom: '3px solid',
        borderColor: 'primary.main',
        paddingBottom: '0.5rem',
        display: 'inline-block'
    };

    const renderProOverlay = () => (
        <BlurOverlay>
            <ProCard>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 2
                    }}>
                        <BoltIcon sx={{
                            fontSize: 80,
                            color: '#FFD700',  // Color dorado
                            filter: 'drop-shadow(0 0 10px rgba(255, 215, 0, 0.5))'  // Efecto de brillo
                        }} />
                    </Box>
                    <Typography variant="h5" component="div" gutterBottom>
                        Funcionalidad Pro
                    </Typography>
                    <Typography variant="body2" color="text.secondary" paragraph>
                        Esta función está disponible solo para clientes con Plan Pro.
                    </Typography>
                    <PlanButton
                        plan="Free"
                        onClick={handlePlanChange}
                        startIcon={<BoltIcon />}
                    >
                        Actualizar a Pro
                    </PlanButton>
                </CardContent>
            </ProCard>
        </BlurOverlay>
    );

    const handleAudioUpload = async (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('audio/')) {
            setAudioFile(file);
            try {
                setUploading(true);

                const audioRef = ref(storage, `audios_sesiones/${id}_${Date.now()}_${file.name}`);
                await uploadBytes(audioRef, file);
                const downloadUrl = await getDownloadURL(audioRef);

                let clientDocRef;
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
                }

                await updateDoc(clientDocRef, {
                    audioUrl: downloadUrl
                });

                setAudioUrl(downloadUrl);

                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 5000);

            } catch (error) {
                console.error('Error al subir el audio:', error);
                setShowErrorNotification(true);
                setTimeout(() => setShowErrorNotification(false), 5000);
            } finally {
                setUploading(false);
            }
        }
    };

    const handleAnalyzeSession = async () => {
        try {
            setAnalyzing(true);
            setPdfUrl(null);

            const payload = {
                audio_url: audioUrl,
                uidcliente: client.uidcliente,
                nombre_cliente: client.nombre,
                tipo_profesional: userDetails.tipo_profesional
            };

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                payload.IDPT = userDetails.uid;
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                payload.IDBO = userDetails.IDBO;
            }

            const response = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/analizar-sesion-pdf', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Error en la respuesta del servidor');
            }

            // Esperamos un momento para asegurarnos de que la cloud function ha terminado
            await new Promise(resolve => setTimeout(resolve, 2000));

            // Obtenemos el documento actualizado del cliente
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
            }

            const updatedClientDoc = await getDoc(clientDocRef);
            const updatedClientData = updatedClientDoc.data();

            if (updatedClientData.url_pdf_sesion) {
                // Obtenemos la referencia al archivo en Storage
                const storage = getStorage();
                const pdfRef = ref(storage, updatedClientData.url_pdf_sesion);

                // Obtenemos la URL de descarga
                const downloadURL = await getDownloadURL(pdfRef);

                setPdfUrl(downloadURL);
                console.log('URL de descarga del PDF:', downloadURL);

                // Actualizamos el estado del cliente
                setClient(prevClient => ({
                    ...prevClient,
                    url_pdf_sesion: updatedClientData.url_pdf_sesion
                }));
            } else {
                throw new Error('No se encontró la URL del PDF en el documento actualizado');
            }

        } catch (error) {
            console.error('Error al analizar la sesión:', error);
            alert(`Error al analizar la sesión: ${error.message}`);
        } finally {
            setAnalyzing(false);
        }
    };

    const handleCancelAudio = () => {
        setAudioFile(null);
        setAudioUrl(null);
        setPdfUrl(null);
    };

    const handleGuardarInstrucciones = async () => {
        console.log('Intentando guardar instrucciones para el cliente con ID:', id);
        console.log('Tipo de profesional:', userDetails.tipo_profesional);
        console.log('Nuevo valor de instrucciones:', instruccionesTemp);

        setGuardandoInstrucciones(true);
        try {
            // Actualizar en Dieta_cliente si existe
            if (dieta?.id) {
                const dietaRef = doc(db, 'Dieta_cliente', dieta.id);
                await updateDoc(dietaRef, {
                    instrucciones_ia: instruccionesTemp
                });
                setDieta(prev => ({
                    ...prev,
                    instrucciones_ia: instruccionesTemp
                }));
            }

            // Actualizar en la subcolección de clientes asociados
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
            }

            await updateDoc(clientDocRef, {
                indicacion_ia_dieta: instruccionesTemp
            });

            setClient(prev => ({
                ...prev,
                indicacion_ia_dieta: instruccionesTemp
            }));

            enqueueSnackbar('Instrucciones guardadas correctamente', {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });

            console.log('Instrucciones guardadas correctamente en ambas rutas.');
        } catch (error) {
            console.error('Error al guardar instrucciones:', error);
            enqueueSnackbar('Error al guardar instrucciones', {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
        } finally {
            setGuardandoInstrucciones(false);
        }
    };

    const handleGuardarIndicaciones = async () => {
        console.log('Intentando guardar indicaciones para el cliente con ID:', id);
        console.log('Tipo de profesional:', userDetails.tipo_profesional);
        console.log('Nuevo valor de indicaciones:', indicacionesUsuarioTemp);

        setGuardandoIndicaciones(true);
        try {
            // Actualizar en la subcolección de clientes asociados
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
            }

            await updateDoc(clientDocRef, {
                indicaciones_para_usuario: indicacionesUsuarioTemp
            });

            setClient(prev => ({
                ...prev,
                indicaciones_para_usuario: indicacionesUsuarioTemp
            }));

            enqueueSnackbar('Indicaciones guardadas correctamente', {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });

            console.log('Indicaciones guardadas correctamente.');
        } catch (error) {
            console.error('Error al guardar indicaciones:', error);
            enqueueSnackbar('Error al guardar indicaciones', {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
        } finally {
            setGuardandoIndicaciones(false);
        }
    };

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                let clientDocRef;
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
                }

                const clientSnap = await getDoc(clientDocRef);
                if (clientSnap.exists()) {
                    const clientData = clientSnap.data();
                    setClient(clientData);
                    // Establecer los valores iniciales
                    setInstruccionesTemp(clientData.indicacion_ia_dieta || '');
                    setIndicacionesUsuarioTemp(clientData.indicaciones_para_usuario || '');
                }

                // ... resto del código de fetch ...
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        if (id && userDetails) {
            fetchClientData();
        }
    }, [id, userDetails]);

    // Función para manejar la actualización después de editar con IA
    const handleAIModalUpdate = () => {
        if (modalType === 'diet') {
            fetchDieta();
        } else if (modalType === 'routine') {
            fetchRutina();
        }
    };

    const openEditAIModal = (type) => {
        setModalType(type);
        setOpenAIModal(true);
    };

    const handleDeleteExercise = async (exercise, dayIndex) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);

            const updatedRutina = { ...rutina };
            const dayKey = `rutina${dias[dayIndex]}`; // convertimos el índice al día correspondiente
            const currentExercises = [...rutina[dayKey]];
            const updatedExercises = currentExercises.filter(ex => ex.Nombre !== exercise.Nombre);

            updatedRutina[dayKey] = updatedExercises;
            setRutina(updatedRutina);

            // Actualizar Firestore
            const rutinaRef = doc(db, 'Rutina_cliente', rutina.id);
            await updateDoc(rutinaRef, {
                [dayKey]: updatedExercises
            });

            enqueueSnackbar('Ejercicio eliminado correctamente', {
                variant: 'success'
            });
        } catch (error) {
            console.error('Error al eliminar ejercicio:', error);
            setRutina(previousState);
            enqueueSnackbar('Error al eliminar el ejercicio', {
                variant: 'error'
            });
        } finally {
            setLoadingState(false);
        }
    };

    // Añadir la nueva función handleAddExercise después de handleDeleteExercise
    const handleAddExercise = async (selectedExercise, dayIndex) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);

            // Verificar que dayIndex es válido
            if (dayIndex === null || dayIndex === undefined || !dias[dayIndex]) {
                console.error('dayIndex inválido:', dayIndex);
                enqueueSnackbar('Error: día inválido', { variant: 'error' });
                return;
            }

            const newExercise = {
                Nombre: selectedExercise.nombre,
                Zona: selectedExercise.zona,
                Series: 3,
                Descanso: 60000,
                Nombre_extenso: selectedExercise.nombre_extenso || selectedExercise.nombre,
                Zonadeverdad: selectedExercise.zona
            };

            const dayKey = `rutina${dias[dayIndex]}`;
            console.log('Añadiendo ejercicio a:', dayKey); // Para debugging

            const updatedRutina = { ...rutina };
            const currentExercises = Array.isArray(rutina[dayKey]) ? [...rutina[dayKey]] : [];

            if (currentExercises.some(ex => ex.Nombre === newExercise.Nombre)) {
                enqueueSnackbar('Este ejercicio ya está en la rutina de este día', {
                    variant: 'warning'
                });
                return;
            }

            const updatedExercises = [...currentExercises, newExercise];
            updatedRutina[dayKey] = updatedExercises;

            // Optimistic update
            setRutina(updatedRutina);

            // Actualizar Firestore
            const rutinaRef = doc(db, 'Rutina_cliente', rutina.id);
            await updateDoc(rutinaRef, {
                [dayKey]: updatedExercises
            });

            enqueueSnackbar('Ejercicio añadido correctamente', {
                variant: 'success'
            });

            setOpenAddExercise(null);

        } catch (error) {
            console.error('Error al añadir ejercicio:', error);
            setRutina(previousState);
            enqueueSnackbar('Error al añadir el ejercicio', {
                variant: 'error'
            });
        } finally {
            setLoadingState(false);
        }
    };

    const handleReplaceExercise = async (oldExercise, newExercise, dayIndex) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);

            const dayKey = `rutina${dias[dayIndex]}`;
            const currentExercises = [...rutina[dayKey]];

            const exerciseToAdd = {
                Nombre: newExercise.nombre,
                Zona: newExercise.zona,
                Series: 3,
                Descanso: 60000,
                Nombre_extenso: newExercise.nombre_extenso || newExercise.nombre,
                Zonadeverdad: newExercise.zona
            };

            const indexToReplace = currentExercises.findIndex(ex => ex.Nombre === oldExercise.Nombre);

            if (indexToReplace === -1) {
                throw new Error('Ejercicio no encontrado');
            }

            const updatedExercises = [...currentExercises];
            updatedExercises[indexToReplace] = exerciseToAdd;

            const updatedRutina = { ...rutina };
            updatedRutina[dayKey] = updatedExercises;
            setRutina(updatedRutina);

            const rutinaRef = doc(db, 'Rutina_cliente', rutina.id);
            await updateDoc(rutinaRef, {
                [dayKey]: updatedExercises
            });

            enqueueSnackbar('Ejercicio reemplazado correctamente', {
                variant: 'success'
            });

        } catch (error) {
            console.error('Error al reemplazar ejercicio:', error);
            setRutina(previousState);
            enqueueSnackbar('Error al reemplazar el ejercicio', {
                variant: 'error'
            });
        } finally {
            setLoadingState(false);
        }
    };

    // Añade esta función dentro del componente ClientDetails, antes del return
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setClient(prevClient => ({
            ...prevClient,
            [name]: value
        }));
    };

    // Añade esta función para obtener los datos de ejercicio
    const fetchEjerciciosData = async () => {
        try {
            // Obtener fecha de hace 7 días
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

            const ejerciciosRef = collection(db, 'Ejercicio');
            const q = query(
                ejerciciosRef,
                where('usuario', '==', client.uidcliente),
                where('fecha', '>=', sevenDaysAgo),
                orderBy('fecha', 'asc')
            );

            const querySnapshot = await getDocs(q);
            const ejercicios = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                // Convertir la fecha de Timestamp a Date
                fecha: doc.data().fecha.toDate()
            }));

            setEjerciciosData(ejercicios);
        } catch (error) {
            console.error('Error al obtener datos de ejercicio:', error);
            enqueueSnackbar('Error al cargar datos de ejercicio', { variant: 'error' });
        }
    };

    // Añade este useEffect para cargar los datos cuando se monte el componente
    useEffect(() => {
        if (client?.uidcliente) {
            fetchEjerciciosData();
            fetchCaloriasData(); // Añade esta línea
        }
    }, [client?.uidcliente]);

    // Modifica la parte del gráfico de pasos en el tab 6 (Estadísticas)
    const renderPasosChart = () => {
        // Procesar datos para el gráfico
        const chartData = ejerciciosData.map(ejercicio => ({
            day: format(ejercicio.fecha, 'EEE', { locale: es }), // Formato corto del día
            pasos: ejercicio.pasos || 0, // Asegurar que pasos sea número
            fecha: format(ejercicio.fecha, 'dd/MM')
        }));

        return (
            <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>Pasos Diarios</Typography>
                {chartData.length > 0 ? (
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="day"
                                tickFormatter={(value, index) => `${value} ${chartData[index]?.fecha}`}
                            />
                            <YAxis />
                            <Tooltip 
                                formatter={(value) => [`${value} pasos`, 'Pasos']}
                            />
                            <Legend />
                            <Bar 
                                dataKey="pasos" 
                                fill="#FF9800" 
                                radius={[10, 10, 0, 0]}
                                name="Pasos"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                ) : (
                    <Typography variant="body2" color="text.secondary" align="center">
                        Faltan datos
                    </Typography>
                )}
                
                {/* Añadir resumen de estadísticas */}
                {chartData.length > 0 && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-around' }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h6">
                                {chartData.reduce((sum, item) => sum + (item.pasos || 0), 0).toLocaleString()}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Total Pasos
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h6">
                                {Math.max(...chartData.map(item => item.pasos || 0)).toLocaleString()}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Máximo Pasos
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Grid>
        );
    };

    const fetchCaloriasData = async () => {
        try {
            // Obtener fecha de hace 7 días
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

            const consumicionesRef = collection(db, 'consumiciones');
            const q = query(
                consumicionesRef,
                where('usuario', '==', client.uidcliente),
                where('dia', '>=', sevenDaysAgo),
                orderBy('dia', 'asc')
            );

            const querySnapshot = await getDocs(q);
            const calorias = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                // Convertir la fecha de Timestamp a Date
                fecha: doc.data().dia.toDate()
            }));

            setCaloriasData(calorias);
        } catch (error) {
            console.error('Error al obtener datos de calorías:', error);
            enqueueSnackbar('Error al cargar datos de calorías', { variant: 'error' });
        }
    };

    const renderCaloriasChart = () => {
        // Procesar datos para el gráfico
        const chartData = caloriasData.map(consumicion => ({
            day: format(consumicion.fecha, 'EEE', { locale: es }), // Formato corto del día
            calorias: consumicion.calorias || 0,
            fecha: format(consumicion.fecha, 'dd/MM')
        }));

        return (
            <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>Calorías Consumidas</Typography>
                {chartData.length > 0 ? (
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="day"
                                tickFormatter={(value, index) => `${value} ${chartData[index]?.fecha}`}
                            />
                            <YAxis />
                            <Tooltip 
                                formatter={(value) => [`${value} kcal`, 'Calorías']}
                            />
                            <Legend />
                            <Bar 
                                dataKey="calorias" 
                                fill="#FF9800" 
                                radius={[10, 10, 0, 0]}
                                name="Calorías"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                ) : (
                    <Typography variant="body2" color="text.secondary" align="center">
                        No hay datos de calorías disponibles
                    </Typography>
                )}
                
                {/* Añadir resumen de estadísticas */}
                {chartData.length > 0 && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-around' }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h6">
                                {Math.round(chartData.reduce((sum, item) => sum + (item.calorias || 0), 0) / chartData.length).toLocaleString()}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Promedio Diario
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h6">
                                {Math.max(...chartData.map(item => item.calorias || 0)).toLocaleString()}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Máximo Diario
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Grid>
        );
    };

    if (loading) {
        return <Typography>Cargando...</Typography>;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!client) {
        return <Typography>No se encontró el cliente</Typography>;
    }

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={3} alignItems="center" sx={{ mb: 3 }}>
                <Grid item>
                    <Avatar
                        src={client.photo_url}
                        alt={`${client.nombre} ${client.apellidos}`}
                        sx={{ width: 100, height: 100, boxShadow: 3 }}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
                        {`${client.nombre} ${client.apellidos}`}
                        {client.plan === 'Pro' && (
                            <BoltIcon sx={{ ml: 1, color: '#FFD700' }} />
                        )}
                    </Typography>
                </Grid>
            </Grid>

            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{
                    mb: 3,
                    '& .MuiTab-root': {
                        minWidth: 'auto',
                        px: 2,
                        py: 1.5,
                        borderRadius: 2,
                        mr: 1,
                        textTransform: 'capitalize',
                        fontSize: '0.95rem',
                        fontWeight: 500,
                        transition: 'all 0.2s ease',
                        '&.Mui-selected': {
                            backgroundColor: 'primary.main',
                            color: 'white',
                            transform: 'translateY(-0.5px)',
                            boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: '1.2rem',
                            marginRight: '4px'
                        },
                    },
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                    '& .MuiTabs-scrollButtons': {
                        display: 'none'
                    }
                }}
                variant="scrollable"
            >
                <Tab
                    label="Resumen"
                    icon={<HomeIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Ficha"
                    icon={<PersonIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Rutina"
                    icon={<FitnessCenterIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Dieta"
                    icon={<RestaurantIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Sus comidas"
                    icon={<LocalDiningIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Actividad Física"
                    icon={<DirectionsRunIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Estadísticas"
                    icon={<BarChartIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Gestión"
                    icon={<SettingsIcon />}
                    iconPosition="start"
                />
            </Tabs>

            {tabValue === 0 && (
                <Paper sx={{ p: 3, ...paperStyle, position: 'relative' }}>
                    <Typography variant="h5" sx={sectionTitleStyle}>
                        Resumen de Sesiones
                    </Typography>
                    
                    {pdfUrl ? (
                        // Si hay un PDF generado, mostrarlo
                        <Box sx={{ mt: 3, height: '600px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 1 }}>
                            <iframe
                                src={`${pdfUrl}#view=FitH`}
                                width="100%"
                                height="100%"
                                style={{ border: 'none' }}
                                title="Resumen de sesión"
                            />
                        </Box>
                    ) : (
                        // Si no hay PDF, mostrar tutorial
                        <Box sx={{ mt: 3, p: 3, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 1 }}>
                            <Typography variant="h6" gutterBottom color="primary">
                                ¿Cómo generar informes de sesiones?
                            </Typography>
                            
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mt: 2 }}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'center',
                                    flex: 1,
                                    p: 2,
                                    bgcolor: 'background.default',
                                    borderRadius: 2
                                }}>
                                    <AudioFileIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                                    <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                                        Paso 1
                                    </Typography>
                                    <Typography variant="body2" textAlign="center">
                                        Graba el audio de tu sesión con el cliente usando cualquier grabadora de voz
                                    </Typography>
                                </Box>

                                <Box sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'center',
                                    flex: 1,
                                    p: 2,
                                    bgcolor: 'background.default',
                                    borderRadius: 2
                                }}>
                                    <CloudUploadIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                                    <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                                        Paso 2
                                    </Typography>
                                    <Typography variant="body2" textAlign="center">
                                        Sube el archivo de audio en la sección "Sesiones" más abajo
                                    </Typography>
                                </Box>

                                <Box sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'center',
                                    flex: 1,
                                    p: 2,
                                    bgcolor: 'background.default',
                                    borderRadius: 2
                                }}>
                                    <PictureAsPdfIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                                    <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                                        Paso 3
                                    </Typography>
                                    <Typography variant="body2" textAlign="center">
                                        ¡Listo! Generaremos automáticamente un informe detallado de la sesión
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h5" sx={sectionTitleStyle}>
                        Sesiones
                    </Typography>

                    <Box sx={{
                        mt: 2,
                        p: 3,
                        border: '2px dashed',
                        borderColor: 'primary.main',
                        borderRadius: 2,
                        textAlign: 'center',
                        backgroundColor: 'background.default',
                        position: 'relative'
                    }}>
                        {!audioUrl ? (
                            <>
                                <input
                                    type="file"
                                    accept="audio/*"
                                    onChange={handleAudioUpload}
                                    style={{ display: 'none' }}
                                    id="audio-upload"
                                    disabled={uploading}
                                />
                                <label htmlFor="audio-upload">
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 2
                                    }}>
                                        {uploading ? (
                                            <CircularProgress />
                                        ) : (
                                            <>
                                                <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                                                <Typography variant="h6" color="primary">
                                                    {audioFile ? audioFile.name : 'Subir audio de sesión'}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Arrastra y suelta un archivo de audio o haz clic para seleccionar
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                </label>
                            </>
                        ) : (
                            <Box sx={{ position: 'relative' }}>
                                <IconButton
                                    onClick={handleCancelAudio}
                                    sx={{
                                        position: 'absolute',
                                        top: -16,
                                        right: -16,
                                        backgroundColor: 'background.paper',
                                        '&:hover': { backgroundColor: 'error.light' }
                                    }}
                                >
                                    <CancelIcon color="error" />
                                </IconButton>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    mb: 2,
                                    p: 2,
                                    backgroundColor: 'background.paper',
                                    borderRadius: 1
                                }}>
                                    <AudioFileIcon color="primary" />
                                    <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                        Audio cargado exitosamente
                                    </Typography>
                                </Box>

                                {!pdfUrl ? (
                                    analyzing ? (
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            p: 3,
                                            position: 'relative'
                                        }}>
                                            <Lottie
                                                animationData={loadingAnimation}
                                                style={{
                                                    width: 150,
                                                    height: 150,
                                                    filter: 'drop-shadow(0 0 10px rgba(255, 87, 34, 0.3))'
                                                }}
                                            />
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    mt: 2,
                                                    color: '#FF5722',
                                                    textAlign: 'center',
                                                    animation: 'pulse 1.5s infinite',
                                                    '@keyframes pulse': {
                                                        '0%': { opacity: 0.6 },
                                                        '50%': { opacity: 1 },
                                                        '100%': { opacity: 0.6 }
                                                    }
                                                }}
                                            >
                                                {analyzeMessages[currentMessage]}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleAnalyzeSession}
                                            startIcon={<BoltIcon />}
                                            sx={{ mt: 1 }}
                                        >
                                            Analizar sesión
                                        </Button>
                                    )
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PictureAsPdfIcon />}
                                        href={pdfUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ mt: 1 }}
                                    >
                                        Ver/Descargar PDF
                                    </Button>
                                )}
                            </Box>
                        )}
                    </Box>
                </Paper>
            )}

            {tabValue === 1 && (
                <Paper sx={paperStyle}>
                    <Typography variant="h5" sx={sectionTitleStyle}>
                        Ficha del Cliente
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Box>
                            <Button
                                variant="contained"
                                color={editMode ? "success" : "primary"}
                                onClick={editMode ? handleSaveClick : handleEditClick}
                                sx={{ borderRadius: 2, mr: 2 }}
                            >
                                {editMode ? "Guardar" : "Editar"}
                            </Button>
                        </Box>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="nombre"
                                value={client.nombre}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Apellidos"
                                name="apellidos"
                                value={client.apellidos || ''}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Edad"
                                value={calculateAge(client.fechanacimiento) ? `${calculateAge(client.fechanacimiento)} años` : 'No disponible'}
                                disabled={true}
                                sx={{ mb: 2 }}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Sexo"
                                name="sexo"
                                value={client.sexo || ''}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Peso (kg)"
                                name="peso"
                                value={client.peso || ''}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Tipo de Dieta"
                                name="tipodieta"
                                value={client.tipodieta || ''}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Objetivo de Agua (L)"
                                name="objetivoagua"
                                value={client.objetivoagua || ''}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Objetivo de Calorías (kcal)"
                                name="objetivocalorias"
                                value={client.objetivocalorias || ''}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Objetivo de Grasas (g)"
                                name="objetivograsas"
                                value={client.objetivograsas || ''}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Objetivo de Hidratos (g)"
                                name="objetivohidratos"
                                value={client.objetivohidratos || ''}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Objetivo de Proteínas (g)"
                                name="objetivoprote"
                                value={client.objetivoprote || ''}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>Objetivos Diarios</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={3} sx={goalCardStyle}>
                                <RestaurantIcon sx={iconStyle} />
                                <TextField
                                    fullWidth
                                    label="Calorías"
                                    name="objetivocalorias"
                                    value={client.objetivocalorias || ''}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                                    }}
                                    sx={textFieldStyle}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={3} sx={goalCardStyle}>
                                <LocalDrinkIcon sx={iconStyle} />
                                <TextField
                                    fullWidth
                                    label="Agua"
                                    name="objetivoagua"
                                    value={client.objetivoagua || ''}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">L</InputAdornment>,
                                    }}
                                    sx={textFieldStyle}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={3} sx={goalCardStyle}>
                                <FitnessCenterIcon sx={iconStyle} />
                                <TextField
                                    fullWidth
                                    label="Tiempo de Actividad"
                                    name="objetivotiempo"
                                    value={client.objetivotiempo || ''}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">min</InputAdornment>,
                                    }}
                                    sx={textFieldStyle}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={3} sx={goalCardStyle}>
                                <DirectionsRunIcon sx={iconStyle} />
                                <TextField
                                    fullWidth
                                    label="Pasos"
                                    name="objetivopasos"
                                    value={client.objetivopasos || ''}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                    sx={textFieldStyle}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>Notas</Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        name="notes"
                        value={client.notes || ''}
                        onChange={handleInputChange}
                        disabled={!editMode}
                        sx={textFieldStyle}
                    />
                </Paper>
            )}

            {tabValue === 2 && (
                    <ClientRoutine 
                        rutina={rutina}
                        loadingRutina={loadingRutina}
                        errorRutina={errorRutina}
                        setRutina={setRutina}
                        onOpenAIEdit={() => openEditAIModal('routine')}
                        onOpenCreateRoutine={handleOpenCreateRoutineModal}
                    />
            )}

            {tabValue === 3 && (
                <Paper sx={{ p: 2, position: 'relative' }}>
                    {client.plan === 'Free' && renderProOverlay()}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h5" sx={sectionTitleStyle}>
                            Dieta Semanal
                        </Typography>
                        <Button
                            onClick={() => openEditAIModal('diet')}
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                color: 'white',
                                px: 3,
                                py: 1,
                                borderRadius: '25px',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                fontSize: '1rem',
                                transition: 'all 0.3s ease',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                    opacity: 0,
                                    transition: 'opacity 0.3s ease',
                                    zIndex: 0,
                                },
                                '& .button-content': {
                                    position: 'relative',
                                    zIndex: 1,
                                    transition: 'color 0.3s ease',
                                },
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0 6px 20px rgba(255, 87, 34, 0.4)',
                                    '&::before': {
                                        opacity: 1,
                                    },
                                    '& .button-content': {
                                        color: 'white', // Mantiene el texto en blanco durante el hover
                                    },
                                },
                            }}
                        >
                            <span className="button-content">✨ Editar con IA</span>
                        </Button>
                    </Box>

                    {/* Nueva sección de Instrucciones */}
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            mb: 4,
                            background: 'linear-gradient(135deg, #FFF9F6 0%, #FFF5F2 100%)',
                            border: '1px solid #FFE0D6',
                            borderRadius: '16px'
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            mb: expandedIA ? 2 : 0
                        }}
                            onClick={() => setExpandedIA(!expandedIA)}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PsychologyIcon sx={{ color: '#FF5722', fontSize: 28 }} />
                                <Typography variant="h6" sx={{ color: '#FF5722', fontWeight: 'bold' }}>
                                    Instrucciones para la IA
                                </Typography>
                            </Box>
                            <IconButton
                                sx={{
                                    transform: expandedIA ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s'
                                }}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Box>

                        <Collapse in={expandedIA}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 3 }}
                            >
                                Especifica aquí las restricciones dietéticas, preferencias y consideraciones especiales
                                que la IA debe tener en cuenta al generar recomendaciones de comidas y recetas para este cliente.
                                Por ejemplo: alergias, intolerancias, alimentos a evitar, preferencias culturales o religiosas.
                            </Typography>

                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Ejemplo: 
- Evitar lácteos por intolerancia a la lactosa
- No incluir alimentos con gluten
- Preferencia por proteínas vegetales
- Evitar alimentos procesados y azúcares refinados
- Incluir opciones bajas en sodio"
                                value={instruccionesTemp}
                                onChange={(e) => setInstruccionesTemp(e.target.value)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                        '&:hover': {
                                            '& > fieldset': {
                                                borderColor: '#FF5722',
                                            }
                                        },
                                        '&.Mui-focused': {
                                            '& > fieldset': {
                                                borderColor: '#FF5722',
                                            }
                                        }
                                    }
                                }}
                            />

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2,
                                gap: 2
                            }}>
                                <Button
                                    variant="outlined"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setInstruccionesTemp(client?.indicacion_ia_dieta || '');
                                    }}
                                    disabled={guardandoInstrucciones}
                                    sx={{
                                        borderColor: 'rgba(255, 87, 34, 0.5)',
                                        color: '#FF5722',
                                        '&:hover': {
                                            borderColor: '#FF5722',
                                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                                        }
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleGuardarInstrucciones();
                                    }}
                                    loading={guardandoInstrucciones}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    sx={{
                                        backgroundColor: '#FF5722',
                                        boxShadow: '0 3px 5px 2px rgba(255, 87, 34, .3)',
                                        '&:hover': {
                                            backgroundColor: '#F4511E'
                                        }
                                    }}
                                >
                                    Guardar
                                </LoadingButton>
                            </Box>
                        </Collapse>
                    </Paper>

                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            mb: 4,
                            background: 'linear-gradient(135deg, #F6F9FF 0%, #F2F5FF 100%)',
                            border: '1px solid #D6E0FF',
                            borderRadius: '16px'
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            mb: expandedUser ? 2 : 0
                        }}
                            onClick={() => setExpandedUser(!expandedUser)}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PersonIcon sx={{ color: '#2196F3', fontSize: 28 }} />
                                <Typography variant="h6" sx={{ color: '#2196F3', fontWeight: 'bold' }}>
                                    Indicaciones para el Usuario
                                </Typography>
                            </Box>
                            <IconButton
                                sx={{
                                    transform: expandedUser ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s'
                                }}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Box>

                        <Collapse in={expandedUser}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 3 }}
                            >
                                Escribe aquí las indicaciones, consejos y recordatorios que deseas que el usuario vea al consultar su dieta.
                                Por ejemplo: horarios de comidas, porciones recomendadas, consejos para mantenerse hidratado, etc.
                            </Typography>

                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Ejemplo:
- Recuerda beber al menos 2 litros de agua al día
- Intenta comer despacio y masticar bien cada bocado
- Las porciones de proteína deben ser del tamaño de la palma de tu mano
- Evita comer muy tarde en la noche, trata de cenar al menos 2 horas antes de dormir
- ¡Mantente constante y verás resultados increíbles!"
                                value={indicacionesUsuarioTemp}
                                onChange={(e) => setIndicacionesUsuarioTemp(e.target.value)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                        '&:hover': {
                                            '& > fieldset': {
                                                borderColor: '#2196F3',
                                            }
                                        },
                                        '&.Mui-focused': {
                                            '& > fieldset': {
                                                borderColor: '#2196F3',
                                            }
                                        }
                                    }
                                }}
                            />

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2,
                                gap: 2
                            }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIndicacionesUsuarioTemp(client?.indicaciones_para_usuario || '');
                                    }}
                                    disabled={guardandoIndicaciones}
                                    sx={{
                                        borderColor: 'rgba(33, 150, 243, 0.5)',
                                        color: '#2196F3',
                                        '&:hover': {
                                            borderColor: '#2196F3',
                                            backgroundColor: 'rgba(33, 150, 243, 0.04)'
                                        }
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleGuardarIndicaciones();
                                    }}
                                    loading={guardandoIndicaciones}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    sx={{
                                        backgroundColor: '#2196F3',
                                        boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
                                        '&:hover': {
                                            backgroundColor: '#1976D2'
                                        }
                                    }}
                                >
                                    Guardar
                                </LoadingButton>
                            </Box>
                        </Collapse>
                    </Paper>

                    <DietaViewer
                        dieta={dieta}
                        loading={loadingDieta}
                        error={error}
                        setDieta={setDieta}
                        userDetails={userDetails} // Añadir esta prop
                    />
                </Paper>
            )}

            {tabValue === 4 && (
                <Paper sx={{ p: 3, ...paperStyle, position: 'relative' }}>
                    {client.plan === 'Free' && renderProOverlay()}
                    <Typography variant="h5" sx={sectionTitleStyle}>
                        Registro de Comidas
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                <DateCalendar
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    sx={{
                                        width: '100%',
                                        '& .MuiPickersDay-root': {
                                            borderRadius: '50%',
                                        },
                                        '& .MuiPickersDay-today': {
                                            borderColor: 'primary.main',
                                        },
                                        '& .Mui-selected': {
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'primary.dark',
                                            },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {renderMealInfo()}
                        </Grid>
                    </Grid>
                </Paper>
            )}

            {tabValue === 5 && (
                <FitnessTracker client={client} /> // Componente de Actividad Física
            )}

            {tabValue === 6 && (
                <Paper sx={{ p: 3, ...paperStyle }}>
                    <Typography variant="h5" sx={sectionTitleStyle}>
                        Estadísticas Semanales
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Evolución del Peso</Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={sampleWeeklyData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="day" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="peso" stroke="#FF5722" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Grid>
                        {renderPasosChart()}
                        {renderCaloriasChart()} {/* Reemplaza la gráfica anterior con esta línea */}
                    </Grid>
                </Paper>
            )}

            {tabValue === 7 && (
                <Paper sx={{ p: 3, ...paperStyle }}>
                    <Typography variant="h5" sx={sectionTitleStyle}>
                        Gestión del Cliente
                    </Typography>
                    <Box sx={{ mb: 3, mt: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>Plan Actual:</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 2,
                            bgcolor: 'background.default',
                            borderRadius: 2,
                            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <BoltIcon sx={{
                                    color: client.plan === 'Pro' ? '#FF9800' : '#2196F3',
                                    mr: 1,
                                    fontSize: 30
                                }} />
                                <Typography variant="h6" component="span">
                                    {client.plan === 'Pro' ? 'Plan Pro' : 'Plan Free'}
                                </Typography>
                            </Box>
                            <PlanButton
                                plan={client.plan}
                                onClick={handlePlanChange}
                                startIcon={<BoltIcon />}
                            >
                                {client.plan === 'Pro' ? 'Cambiar a Free' : 'Actualizar a Pro'}
                            </PlanButton>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteClick}
                        sx={{ mt: 2, borderRadius: 2 }}
                    >
                        Eliminar Cliente
                    </Button>
                </Paper>
            )}

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                PaperProps={{
                    style: {
                        borderRadius: 8,
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Estás seguro de que quieres eliminar este cliente?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esta acción eliminará al cliente de tu lista y actualizará su perfil. Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button onClick={handleConfirmDelete} color="error" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openPlanDialog}
                onClose={handleClosePlanDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`¿Estás seguro de que quieres cambiar el plan a ${tempPlan}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {tempPlan === 'Pro'
                            ? "Al cambiar a Plan Pro, el cliente tendrá acceso a todas las funciones premium."
                            : "Al cambiar a Plan Free, el cliente perderá acceso a las funciones premium."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePlanDialog}>Cancelar</Button>
                    <Button onClick={handleConfirmPlanChange} autoFocus color={tempPlan === 'Pro' ? "primary" : "secondary"}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            {showNotification && (
                <NotificationCard
                    message="Audio subido exitosamente"
                    type="success"
                    onClose={() => setShowNotification(false)}
                />
            )}

            {showErrorNotification && (
                <NotificationCard
                    message="Error al subir el audio"
                    type="error"
                    onClose={() => setShowErrorNotification(false)}
                />
            )}

            <EditAIModal
                open={openAIModal}
                onClose={() => setOpenAIModal(false)}
                type={modalType}
                client={client}
                userDetails={userDetails}
                onUpdate={handleAIModalUpdate}
            />
        </Box>
    );
};

export default ClientDetails;