import React, { useState, useContext, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    TextField, 
    Button, 
    FormControl, 
    Select, 
    MenuItem, 
    InputLabel,
    Paper,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
    Grid,
    Avatar,
    ListItemAvatar,
    ListItemText,
    Chip,
    Tooltip,
    LinearProgress
} from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import { collection, onSnapshot, doc, getDoc, runTransaction, increment } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';
import { useSnackbar } from 'notistack';
import ReactMarkdown from 'react-markdown';
import { Page, Text, Document, StyleSheet, PDFDownloadLink, View, Image } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkStringify from 'remark-stringify';
import { 
    Barbell, 
    ForkKnife, 
    Timer,
    Bandaids,
    Microphone,
    MicrophoneSlash
} from "@phosphor-icons/react";
import PsychologyIcon from '@mui/icons-material/Psychology';
import SettingsIcon from '@mui/icons-material/Settings';
import PlantillaConfigModal from '../modals/PlantillaConfigModal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../config/firebaseConfig';
import { getStorage, ref, getBytes } from 'firebase/storage';

// Estilos para el PDF actualizados
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'Helvetica',
        backgroundColor: '#f8f8f8'
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 30,
        borderBottom: '1 solid #ddd',
        paddingBottom: 20
    },
    logo: {
        width: 80,
        height: 80,
        marginRight: 15,
        objectFit: 'contain'
    },
    businessInfo: {
        flex: 1
    },
    businessName: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 5
    },
    businessDetails: {
        fontSize: 10,
        color: '#666',
        marginBottom: 3
    },
    title: {
        fontSize: 24,
        marginBottom: 20,
        fontWeight: 'bold',
        color: '#333',
        textAlign: 'center'
    },
    section: {
        marginBottom: 10
    },
    heading1: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        marginTop: 15,
        color: '#FF5722'
    },
    heading2: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
        marginTop: 12,
        color: '#FF5722'
    },
    heading3: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 6,
        marginTop: 10,
        color: '#333'
    },
    paragraph: {
        fontSize: 12,
        lineHeight: 1.5,
        marginBottom: 8,
        color: '#555'
    },
    listItem: {
        fontSize: 12,
        lineHeight: 1.5,
        marginBottom: 4,
        paddingLeft: 15,
        color: '#555'
    }
});

// Función para procesar el markdown
const processMarkdown = async (markdown) => {
    try {
        await unified()
            .use(remarkParse)
            .use(remarkStringify)
            .process(markdown);

        // Dividir el contenido en secciones
        const sections = markdown.split('\n\n').map(section => {
            if (section.startsWith('# ')) {
                return { type: 'h1', content: section.replace('# ', '') };
            } else if (section.startsWith('## ')) {
                return { type: 'h2', content: section.replace('## ', '') };
            } else if (section.startsWith('### ')) {
                return { type: 'h3', content: section.replace('### ', '') };
            } else if (section.startsWith('- ')) {
                return { 
                    type: 'list', 
                    items: section.split('\n').map(item => item.replace('- ', ''))
                };
            } else {
                return { type: 'paragraph', content: section };
            }
        });

        return sections;
    } catch (error) {
        console.error('Error procesando markdown:', error);
        return [{ type: 'paragraph', content: markdown }];
    }
};

// Componente para el PDF actualizado
const PDFDocument = ({ markdown, clientData, userDetails, generationType }) => {
    const [sections, setSections] = useState([]);
    
    useEffect(() => {
        processMarkdown(markdown).then(setSections);
    }, [markdown]);

    // Determinar la URL del logo según el tipo de profesional
    const businessName = userDetails?.tipo_profesional === 'entrenador_independiente'
        ? userDetails?.display_name
        : userDetails?.tipo_profesional === 'entrenador_centro'
            ? userDetails?.centro?.nombreCentro
            : userDetails?.tipo_profesional === 'propietario_centro'
                ? userDetails?.centro?.nombreCentro
                : userDetails?.display_name;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Encabezado mejorado */}
                <View style={styles.header}>
                    <View style={styles.businessInfo}>
                        <Text style={styles.businessName}>
                            {businessName || 'Nombre del Negocio'}
                        </Text>
                        <Text style={styles.businessDetails}>
                            {userDetails?.especialidad || 'Especialidad no especificada'}
                        </Text>
                        {userDetails?.tipo_profesional !== 'entrenador_independiente' && (
                            <Text style={styles.businessDetails}>
                                {userDetails?.centro?.email || 'Email no especificado'}
                            </Text>
                        )}
                        <Text style={styles.businessDetails}>
                            Profesional: {userDetails?.display_name || 'Nombre no especificado'}
                        </Text>
                        <Text style={styles.businessDetails}>
                            Cliente: {clientData?.nombre || 'Cliente no especificado'}
                        </Text>
                        <Text style={styles.businessDetails}>
                            Fecha: {new Date().toLocaleDateString('es-ES')}
                        </Text>
                    </View>
                </View>

                {/* Contenido */}
                {sections.map((section, index) => (
                    <View key={index} style={styles.section}>
                        {section.type === 'heading1' && (
                            <Text style={styles.heading1}>{section.content}</Text>
                        )}
                        {section.type === 'heading2' && (
                            <Text style={styles.heading2}>{section.content}</Text>
                        )}
                        {section.type === 'heading3' && (
                            <Text style={styles.heading3}>{section.content}</Text>
                        )}
                        {section.type === 'paragraph' && (
                            <Text style={styles.paragraph}>{section.content}</Text>
                        )}
                        {section.type === 'listItem' && (
                            <Text style={styles.listItem}>• {section.content}</Text>
                        )}
                    </View>
                ))}
            </Page>
        </Document>
    );
};

const getBase64FromStorageUrl = async (url) => {
    try {
        // Extraer el path del storage de la URL
        const storage = getStorage(app);
        const fullPath = decodeURIComponent(url.split('/o/')[1].split('?')[0]);
        const storageRef = ref(storage, fullPath);
        
        // Obtener los bytes de la imagen
        const bytes = await getBytes(storageRef);
        
        // Convertir a base64
        let binary = '';
        const bytes_array = new Uint8Array(bytes);
        for (let i = 0; i < bytes_array.byteLength; i++) {
            binary += String.fromCharCode(bytes_array[i]);
        }
        const base64 = btoa(binary);
        
        // Determinar el tipo de imagen basado en la extensión o usar un tipo por defecto
        const fileExtension = fullPath.split('.').pop()?.toLowerCase() || 'png';
        const mimeType = fileExtension === 'jpg' || fileExtension === 'jpeg' 
            ? 'image/jpeg' 
            : 'image/png';
        
        return `data:${mimeType};base64,${base64}`;
    } catch (error) {
        console.error('Error convirtiendo imagen a base64:', error);
        return null;
    }
};

const Generar = () => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [instructions, setInstructions] = useState('');
    const [loading, setLoading] = useState(true);
    const [generationType, setGenerationType] = useState('');
    const { userDetails } = useContext(AuthContext);
    const [isGenerating, setIsGenerating] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [generatedContent, setGeneratedContent] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [openUnavailableModal, setOpenUnavailableModal] = useState(false);
    const [unavailableFeature, setUnavailableFeature] = useState('');
    const [selectedClientData, setSelectedClientData] = useState(null);
    const [editableContent, setEditableContent] = useState('');
    const [editPrompt, setEditPrompt] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const [plantillaConfig, setPlantillaConfig] = useState(null);

    // Efecto para manejar la barra de progreso
    useEffect(() => {
        let timer;
        if (isGenerating && progress < 95) {
            timer = setTimeout(() => {
                // Fórmula para hacer que el progreso sea cada vez más lento
                const increment = Math.max(0.5, (100 - progress) / 15);
                setProgress(prev => Math.min(95, prev + increment));
            }, 200);
        }
        return () => {
            clearTimeout(timer);
            if (!isGenerating) {
                setProgress(0);
            }
        };
    }, [isGenerating, progress]);

    const generationTypes = [
        { 
            value: 'rutina', 
            label: 'Rutina de Entrenamiento', 
            endpoint: 'crear-rutina-beta',
            icon: <Barbell size={24} weight="duotone" />,
            color: '#FF5722'
        },
        { 
            value: 'dieta', 
            label: 'Plan de Alimentación', 
            endpoint: 'crear-dieta-beta',
            icon: <ForkKnife size={24} weight="duotone" />,
            color: '#4CAF50'
        },
        { 
            value: 'crossfit', 
            label: 'Programa CrossFit', 
            endpoint: 'crear-crossfit-beta',
            icon: <Timer size={24} weight="duotone" />,
            color: '#2196F3'
        },
        { 
            value: 'rehabilitacion', 
            label: 'Plan de Rehabilitación', 
            endpoint: 'crear-rehabilitacion-beta',
            icon: <Bandaids size={24} weight="duotone" />,
            color: '#9C27B0'
        }
    ];

    useEffect(() => {
        let unsubscribe;
        
        const fetchClients = async () => {
            if (!userDetails) {
                setLoading(false);
                return;
            }

            try {
                let clientsCollectionRef;

                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientsCollectionRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados');
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    clientsCollectionRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros');
                }

                unsubscribe = onSnapshot(clientsCollectionRef, (snapshot) => {
                    const clientsData = snapshot.docs
                        .map(doc => ({
                            id: doc.id,
                            ...doc.data()
                        }))
                        .filter(client => !client.isInSolicitudes);

                    setClients(clientsData);
                    setLoading(false);
                });

            } catch (error) {
                console.error("Error al cargar los clientes:", error);
                setLoading(false);
            }
        };

        fetchClients();
        return () => unsubscribe && unsubscribe();
    }, [userDetails]);

    // Inicializar el reconocimiento de voz
    useEffect(() => {
        const initializeSpeechRecognition = () => {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            
            if (SpeechRecognition) {
                const recognitionInstance = new SpeechRecognition();
                recognitionInstance.continuous = false;
                recognitionInstance.interimResults = false;
                recognitionInstance.lang = 'es-ES';

                recognitionInstance.onstart = () => {
                    setIsRecording(true);
                };

                recognitionInstance.onend = () => {
                    setIsRecording(false);
                };

                recognitionInstance.onerror = (event) => {
                    console.error('Error en reconocimiento de voz:', event.error);
                    setIsRecording(false);
                    enqueueSnackbar('Error en el reconocimiento de voz. Por favor, intenta de nuevo.', { 
                        variant: 'error' 
                    });
                };

                recognitionInstance.onresult = (event) => {
                    const transcript = event.results[0][0].transcript;
                    setInstructions(prev => prev + (prev ? ' ' : '') + transcript);
                };

                setRecognition(recognitionInstance);
            }
        };

        initializeSpeechRecognition();

        return () => {
            if (recognition) {
                recognition.onresult = null;
                recognition.onend = null;
                recognition.onerror = null;
                recognition.onstart = null;
            }
        };
    }, []);

    useEffect(() => {
        if (selectedClient && clients) {
            const clientData = clients.find(client => client.id === selectedClient);
            setSelectedClientData(clientData);
        }
    }, [selectedClient, clients]);

    const toggleRecording = () => {
        if (!recognition) {
            enqueueSnackbar('Tu navegador no soporta reconocimiento de voz. Por favor, utiliza Chrome o Edge.', { 
                variant: 'error',
                autoHideDuration: 5000
            });
            return;
        }

        if (isRecording) {
            recognition.stop();
        } else {
            try {
                recognition.start();
            } catch (error) {
                console.error('Error al iniciar reconocimiento:', error);
                setIsRecording(false);
                enqueueSnackbar('Error al iniciar el reconocimiento de voz. Por favor, intenta de nuevo.', { 
                    variant: 'error' 
                });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Verificar si es crossfit o rehabilitación
        if (generationType === 'crossfit' || generationType === 'rehabilitacion') {
            setUnavailableFeature(generationType === 'crossfit' ? 'CrossFit' : 'Rehabilitación');
            setOpenUnavailableModal(true);
            return;
        }

        setIsGenerating(true);
        setProgress(0);
        setOpenPreview(true);

        try {
            // 1. Verifica que exista el cliente seleccionado
            const selectedClientData = clients.find(client => client.id === selectedClient);
            if (!selectedClientData) {
                throw new Error('Cliente no encontrado');
            }

            // 2. Verifica el tipo de generación seleccionado
            const selectedType = generationTypes.find(type => type.value === generationType);
            if (!selectedType) {
                throw new Error('Tipo de generación no válido');
            }

            // 3. Prepara el payload con los datos necesarios
            const payload = {
                IDPT: userDetails.IDPT,
                IDBO: userDetails.IDBO || null,
                instrucciones: instructions,
                tipo_profesional: userDetails.tipo_profesional,
                tipo_generacion: generationType
            };

            console.log('Payload a enviar:', payload);

            // 4. Realiza la petición al endpoint correspondiente
            const response = await fetch(`https://europe-southwest1-fitai-2cd85.cloudfunctions.net/${selectedType.endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            // 5. Verifica la respuesta
            if (!response.ok) {
                const errorData = await response.text();
                console.error('Error completo:', errorData);
                throw new Error('Error en la generación');
            }

            // 6. Procesa la respuesta y actualiza el estado
            const data = await response.json();
            console.log('Respuesta completa:', data);

            const markdown = data.plan_markdown || data.rutina_markdown || data.contenido_markdown;

            if (!markdown) {
                console.error('La respuesta no contiene el markdown esperado:', data);
                throw new Error('Formato de respuesta inválido');
            }

            setProgress(100);
            setGeneratedContent(markdown);
            setEditableContent(markdown);
            enqueueSnackbar('Contenido generado con éxito', { variant: 'success' });

        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(error.message || 'Error en la generación', { variant: 'error' });
        } finally {
            setIsGenerating(false);
        }
    };

    const handlePromptEdit = async () => {
        if (!editPrompt.trim()) {
            enqueueSnackbar('Por favor, introduce un prompt para editar el contenido', { variant: 'warning' });
            return;
        }

        setIsEditing(true);
        try {
            const endpoint = `editar-${generationType}-beta`;

            // Determinar el nombre del parámetro según el tipo de contenido
            const contentParam = generationType === 'dieta' ? 'dieta_actual' : 'rutina_actual';
            
            const response = await fetch(`https://europe-southwest1-fitai-2cd85.cloudfunctions.net/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    tipo_profesional: userDetails.tipo_profesional,
                    IDPT: userDetails.tipo_profesional === 'entrenador_independiente' ? userDetails.IDPT : null,
                    IDBO: userDetails.tipo_profesional !== 'entrenador_independiente' ? userDetails.IDBO : null,
                    id_cliente: selectedClient,
                    instrucciones: editPrompt,
                    [contentParam]: editableContent
                })
            });

            let errorData;
            try {
                errorData = await response.json();
            } catch (e) {
                errorData = await response.text();
            }

            if (!response.ok) {
                console.error('Error de la cloud function:', errorData);
                throw new Error(
                    errorData?.error || 
                    'Error al editar el contenido. Por favor, intenta con otras instrucciones.'
                );
            }

            // Obtener el contenido según el tipo de generación
            let newContent;
            if (generationType === 'dieta') {
                newContent = errorData.dieta_markdown;
            } else if (generationType === 'rutina') {
                newContent = errorData.rutina_markdown;
            } else {
                newContent = errorData.contenido_markdown;
            }
            
            if (!newContent) {
                console.error('Respuesta recibida:', errorData);
                throw new Error('La respuesta no contiene el contenido esperado. Por favor, intenta de nuevo.');
            }

            setEditableContent(newContent);
            setGeneratedContent(newContent);
            setEditPrompt('');
            enqueueSnackbar('Contenido editado con éxito', { variant: 'success' });
        } catch (error) {
            console.error('Error al editar:', error);
            enqueueSnackbar(
                error.message || 
                'Ha ocurrido un error al editar el contenido. Por favor, intenta de nuevo.', 
                { variant: 'error' }
            );
        } finally {
            setIsEditing(false);
        }
    };

    const handleSavePlantillaConfig = (config) => {
        setPlantillaConfig(config);
        // Aquí puedes guardar la configuración en tu backend o donde sea necesario
    };

    const handleGeneratePDF = async () => {
        try {
            setIsGenerating(true);
            setProgress(0);

            // Obtener las URLs de las imágenes
            const businessLogoUrl = userDetails?.tipo_profesional === 'entrenador_independiente'
                ? userDetails?.business_logo_url
                : userDetails?.centro?.business_logo_url;

            const centroPhotoUrl = userDetails?.centro?.photo_url;

            // Obtener la configuración de la plantilla
            const docRef = userDetails?.tipo_profesional === 'entrenador_independiente'
                ? doc(db, 'userDetails', userDetails.uid)
                : doc(db, 'Centros', userDetails.IDBO);

            const docSnap = await getDoc(docRef);
            const plantillaConfig = docSnap.exists() ? docSnap.data().plantillaConfig : null;

            // Preparar los datos para la cloud function
            const pdfData = {
                content: {
                    text: editableContent,
                    type: generationType,
                    clientData: selectedClientData
                },
                businessData: {
                    businessName: plantillaConfig?.businessName || (userDetails?.tipo_profesional === 'entrenador_independiente' 
                        ? userDetails?.display_name 
                        : userDetails?.centro?.nombreCentro),
                    business_logo_url: businessLogoUrl,
                    contactInfo: plantillaConfig?.contactInfo || userDetails?.contactInfo,
                    tipo_profesional: userDetails?.tipo_profesional,
                    centro_photo_url: centroPhotoUrl,
                    nombreCentro: userDetails?.centro?.nombreCentro
                },
                design: {
                    headerStyle: plantillaConfig?.design?.headerStyle || 'modern',
                    primaryColor: plantillaConfig?.design?.primaryColor || '#FF5722',
                    secondaryColor: plantillaConfig?.design?.secondaryColor || '#FF1493',
                    fontSize: plantillaConfig?.design?.fontSize || 'medium',
                    showLogo: plantillaConfig?.showLogo ?? true,
                    showDate: plantillaConfig?.showDate ?? true,
                    showContactInfo: plantillaConfig?.showContactInfo ?? true,
                    footerText: plantillaConfig?.footerText || ''
                }
            };

            console.log('Enviando datos a la cloud function:', pdfData);

            // Llamar a la cloud function
            const response = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/pdf-creator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(pdfData)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error(`Error al generar el PDF: ${errorText}`);
            }

            const result = await response.json();
            console.log('Respuesta de la cloud function recibida');
            
            if (!result.pdf_base64) {
                throw new Error('No se recibió el PDF en base64');
            }

            // Convertir base64 a Blob
            const byteCharacters = atob(result.pdf_base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Crear URL del blob y descargar
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${generationType}_${selectedClientData?.nombre || 'documento'}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            // Obtener la fecha actual en formato YYYY-MM-DD
            const today = new Date();
            const dateStr = today.toISOString().split('T')[0];

            // Incrementar los contadores de PDFs generados
            const metricasRef = doc(db, 'metricas', 'pdfs');
            const metricasDiariasRef = doc(db, 'metricas', 'pdfs', 'por_dia', dateStr);

            await runTransaction(db, async (transaction) => {
                // Primero hacemos todas las lecturas
                const metricasDoc = await transaction.get(metricasRef);
                const metricasDiariasDoc = await transaction.get(metricasDiariasRef);

                // Luego hacemos todas las escrituras
                if (!metricasDoc.exists()) {
                    transaction.set(metricasRef, {
                        total_generados: 1,
                        ultima_actualizacion: today
                    });
                } else {
                    transaction.update(metricasRef, {
                        total_generados: increment(1),
                        ultima_actualizacion: today
                    });
                }

                if (!metricasDiariasDoc.exists()) {
                    transaction.set(metricasDiariasRef, {
                        fecha: today,
                        total_dia: 1,
                        por_tipo: {
                            [generationType]: 1
                        }
                    });
                } else {
                    const currentData = metricasDiariasDoc.data();
                    const porTipo = currentData.por_tipo || {};
                    porTipo[generationType] = (porTipo[generationType] || 0) + 1;
                    
                    transaction.update(metricasDiariasRef, {
                        total_dia: increment(1),
                        por_tipo: porTipo
                    });
                }
            });

            setProgress(100);
            enqueueSnackbar('PDF generado correctamente', { variant: 'success' });
        } catch (error) {
            console.error('Error al generar el PDF:', error);
            enqueueSnackbar(error.message || 'Error al generar el PDF', { variant: 'error' });
        } finally {
            setIsGenerating(false);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Generador de Contenido
                </Typography>
                <Button
                    variant="outlined"
                    startIcon={<SettingsIcon />}
                    onClick={() => setOpenConfigModal(true)}
                    sx={{
                        borderColor: 'primary.main',
                        color: 'primary.main',
                        '&:hover': {
                            borderColor: 'primary.dark',
                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                        }
                    }}
                >
                    Configurar Plantilla
                </Button>
            </Box>
            <Typography variant="subtitle1" gutterBottom sx={{ mb: 4 }}>
                Selecciona un cliente y el tipo de contenido que deseas generar.
            </Typography>

            <Paper elevation={3} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        {generationTypes.map((type) => (
                            <Grid item xs={12} sm={6} md={3} key={type.value}>
                                <Paper
                                    elevation={generationType === type.value ? 3 : 1}
                                    sx={{
                                        p: 2,
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        border: generationType === type.value ? `2px solid ${type.color}` : '2px solid transparent',
                                        '&:hover': {
                                            transform: 'translateY(-2px)',
                                            boxShadow: 3
                                        },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 1,
                                        height: '100%',
                                        backgroundColor: generationType === type.value ? `${type.color}10` : 'transparent'
                                    }}
                                    onClick={() => setGenerationType(type.value)}
                                >
                                    <Box sx={{ 
                                        color: type.color,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {type.icon}
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        align="center"
                                        sx={{
                                            fontWeight: generationType === type.value ? 600 : 400
                                        }}
                                    >
                                        {type.label}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id="client-select-label">Cliente</InputLabel>
                        <Select
                            labelId="client-select-label"
                            value={selectedClient}
                            label="Cliente"
                            onChange={(e) => setSelectedClient(e.target.value)}
                            required
                            disabled={isGenerating}
                            sx={{
                                '& .MuiSelect-select': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }
                            }}
                        >
                            {clients.map((client) => (
                                <MenuItem 
                                    key={client.id} 
                                    value={client.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        py: 1
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar 
                                            src={client.photo_url} 
                                            alt={`${client.nombre} ${client.apellidos}`}
                                            sx={{ 
                                                width: 40, 
                                                height: 40,
                                                bgcolor: !client.photo_url ? 'primary.main' : 'transparent'
                                            }}
                                        >
                                            {!client.photo_url && `${client.nombre.charAt(0)}${client.apellidos.charAt(0)}`}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${client.nombre} ${client.apellidos}`}
                                        secondary={client.email}
                                        primaryTypographyProps={{
                                            fontWeight: 500
                                        }}
                                    />
                                    {client.plan === 'Pro' && (
                                        <Chip 
                                            label="Pro"
                                            size="small"
                                            sx={{
                                                background: 'linear-gradient(45deg, #FFA500 30%, #FF1493 90%)',
                                                color: 'white',
                                                fontWeight: 'bold',
                                                ml: 'auto'
                                            }}
                                        />
                                    )}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Box sx={{ position: 'relative' }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={6}
                            label="Instrucciones"
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                            required
                            disabled={isGenerating}
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    pr: 5 // Espacio para el botón de micrófono
                                }
                            }}
                            placeholder={`Describe aquí las instrucciones para generar ${
                                generationType === 'rutina' ? 'la rutina' :
                                generationType === 'dieta' ? 'el plan de alimentación' :
                                generationType === 'crossfit' ? 'el programa de CrossFit' :
                                generationType === 'rehabilitacion' ? 'el plan de rehabilitación' :
                                'el contenido'
                            } para tu cliente...`}
                        />
                        <Tooltip title={isRecording ? "Detener grabación" : "Grabar instrucciones por voz"}>
                            <IconButton
                                onClick={toggleRecording}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: isRecording ? 'error.main' : 'action.active',
                                    animation: isRecording ? 'pulse 1.5s infinite' : 'none',
                                    '@keyframes pulse': {
                                        '0%': {
                                            transform: 'scale(1)',
                                            opacity: 1
                                        },
                                        '50%': {
                                            transform: 'scale(1.2)',
                                            opacity: 0.8
                                        },
                                        '100%': {
                                            transform: 'scale(1)',
                                            opacity: 1
                                        }
                                    }
                                }}
                            >
                                {isRecording ? 
                                    <MicrophoneSlash size={24} weight="duotone" /> : 
                                    <Microphone size={24} weight="duotone" />
                                }
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Button 
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                        disabled={isGenerating}
                        sx={{
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            color: 'white',
                            py: 1.5,
                            fontWeight: 'bold',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                            },
                            '&.Mui-disabled': {
                                background: 'rgba(0, 0, 0, 0.12)',
                            }
                        }}
                    >
                        {isGenerating ? (
                            <>
                                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                Generando...
                            </>
                        ) : (
                            'Generar Contenido'
                        )}
                    </Button>
                </form>
            </Paper>

            {/* Dialog para previsualizar el contenido */}
            <Dialog
                open={openPreview && (generatedContent !== null || isGenerating)}
                onClose={() => !isGenerating && setOpenPreview(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    {isGenerating ? (
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '300px',
                            textAlign: 'center',
                            gap: 3
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Generando contenido personalizado...
                            </Typography>
                            <Box sx={{ width: '100%', maxWidth: 400, position: 'relative' }}>
                                <LinearProgress 
                                    variant="determinate" 
                                    value={progress}
                                    sx={{
                                        height: 10,
                                        borderRadius: 5,
                                        backgroundColor: '#f0f0f0',
                                        '& .MuiLinearProgress-bar': {
                                            borderRadius: 5,
                                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                            backgroundSize: '200% 200%',
                                            animation: 'gradient 2s ease infinite',
                                        },
                                        '@keyframes gradient': {
                                            '0%': {
                                                backgroundPosition: '0% 50%'
                                            },
                                            '50%': {
                                                backgroundPosition: '100% 50%'
                                            },
                                            '100%': {
                                                backgroundPosition: '0% 50%'
                                            },
                                        }
                                    }}
                                />
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                {progress < 95 ? 'Esto puede tardar unos segundos...' : 'Casi listo...'}
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                                <IconButton onClick={() => setOpenPreview(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            
                            <Typography variant="h6" gutterBottom>
                                Vista previa del contenido
                            </Typography>

                            {/* Campo de texto editable */}
                            <TextField
                                fullWidth
                                multiline
                                rows={12}
                                value={editableContent}
                                onChange={(e) => setEditableContent(e.target.value)}
                                variant="outlined"
                                sx={{ mb: 3 }}
                            />

                            {/* Sección de edición por prompt */}
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Editar mediante prompt
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <TextField
                                        fullWidth
                                        value={editPrompt}
                                        onChange={(e) => setEditPrompt(e.target.value)}
                                        placeholder="Escribe tus instrucciones para editar el contenido..."
                                        disabled={isEditing}
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={handlePromptEdit}
                                        disabled={isEditing}
                                        sx={{ minWidth: '120px' }}
                                    >
                                        {isEditing ? (
                                            <>
                                                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                                Editando...
                                            </>
                                        ) : (
                                            'Editar'
                                        )}
                                    </Button>
                                </Box>
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                    Una vez tengas el resultado que te guste, puedes darle a:
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleGeneratePDF}
                                        disabled={isGenerating}
                                        startIcon={<DownloadIcon />}
                                    >
                                        {isGenerating ? 'Generando PDF...' : 'Generar PDF'}
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </DialogContent>
            </Dialog>

            {/* Modal de funcionalidad no disponible */}
            <Dialog
                open={openUnavailableModal}
                onClose={() => setOpenUnavailableModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent sx={{ textAlign: 'center', py: 4 }}>
                    <Box sx={{ mb: 3 }}>
                        <PsychologyIcon sx={{ 
                            fontSize: 60, 
                            color: 'primary.main',
                            mb: 2
                        }} />
                        <Typography variant="h6" gutterBottom>
                            Funcionalidad en Desarrollo
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            La generación de programas de {unavailableFeature} estará disponible próximamente.
                            Estamos trabajando para ofrecerte la mejor experiencia posible.
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={() => setOpenUnavailableModal(false)}
                        sx={{
                            mt: 2,
                            px: 4,
                            borderRadius: 2
                        }}
                    >
                        Entendido
                    </Button>
                </DialogContent>
            </Dialog>

            <PlantillaConfigModal
                open={openConfigModal}
                onClose={() => setOpenConfigModal(false)}
                onSave={handleSavePlantillaConfig}
                initialConfig={plantillaConfig}
            />
        </Box>
    );
};

export default Generar; 