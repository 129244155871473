import React, { useState, useContext, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
    IconButton,
    Grid,
    Switch,
    FormControlLabel,
    Tabs,
    Tab,
    Divider,
    InputLabel,
    Card,
    CardContent,
    Tooltip,
    Popover,
    CircularProgress,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import { styled } from '@mui/material/styles';
import { 
    X as CloseIcon,
    Buildings as BusinessIcon,
    PaintBrush as PaletteIcon,
    Layout as TemplateIcon,
    Image as LogoIcon,
    Phone as ContactIcon,
    Calendar as DateIcon,
    TextT as FontIcon,
    Layout as LayoutIcon,
} from "@phosphor-icons/react";
import { AuthContext } from '../../contexts/AuthContext';
import { db } from '../../config/firebaseConfig';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';

const ModernSwitch = styled(Switch)(({ theme }) => ({
    width: 56,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 4,
        '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                borderColor: 'transparent',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 24,
        height: 24,
        boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
        borderRadius: 32,
        backgroundColor: theme.palette.grey[300],
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const SwitchOption = ({ icon: Icon, label, checked, onChange }) => (
    <Box
        sx={{
            p: 2,
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            transition: 'all 0.2s',
            '&:hover': {
                bgcolor: 'action.hover',
                transform: 'translateY(-2px)',
            },
        }}
    >
        <FormControlLabel
            control={
                <ModernSwitch 
                    checked={checked}
                    onChange={onChange}
                />
            }
            label={
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                    color: checked ? 'primary.main' : 'text.primary',
                    transition: 'color 0.2s'
                }}>
                    <Icon size={20} weight={checked ? "fill" : "duotone"} />
                    <Typography 
                        variant="body1"
                        sx={{ 
                            fontWeight: checked ? 500 : 400,
                            transition: 'font-weight 0.2s'
                        }}
                    >
                        {label}
                    </Typography>
                </Box>
            }
            sx={{
                m: 0,
                width: '100%',
                '& .MuiFormControlLabel-label': {
                    flex: 1
                }
            }}
        />
    </Box>
);

const LAYOUT_EXAMPLES = [
    {
        id: 'modern',
        label: 'Moderno',
        preview: (colors) => (
            <Box sx={{ width: '100%', height: 150, bgcolor: '#f5f5f5', borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
                {/* Header con gradiente */}
                <Box sx={{ 
                    height: '40%', 
                    background: `linear-gradient(45deg, ${colors.primaryColor}, ${colors.secondaryColor})`,
                    display: 'flex',
                    alignItems: 'center',
                    px: 2
                }}>
                    <Box sx={{ width: 30, height: 30, bgcolor: 'white', borderRadius: 1, mr: 2 }} /> {/* Logo */}
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ width: '60%', height: 10, bgcolor: 'white', borderRadius: 5, mb: 1 }} />
                        <Box sx={{ width: '40%', height: 8, bgcolor: 'white', opacity: 0.7, borderRadius: 5 }} />
                    </Box>
                </Box>
                {/* Contenido */}
                <Box sx={{ p: 2 }}>
                    <Box sx={{ width: '90%', height: 8, bgcolor: '#ddd', borderRadius: 5, mb: 1 }} />
                    <Box sx={{ width: '75%', height: 8, bgcolor: '#ddd', borderRadius: 5, mb: 1 }} />
                    <Box sx={{ width: '80%', height: 8, bgcolor: '#ddd', borderRadius: 5 }} />
                </Box>
            </Box>
        )
    },
    {
        id: 'classic',
        label: 'Clásico',
        preview: (colors) => (
            <Box sx={{ width: '100%', height: 150, bgcolor: '#f5f5f5', borderRadius: 2, overflow: 'hidden', p: 2 }}>
                {/* Header clásico */}
                <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    pb: 2,
                    borderBottom: `2px solid ${colors.primaryColor}`
                }}>
                    <Box sx={{ 
                        width: 40, 
                        height: 40, 
                        bgcolor: colors.primaryColor,
                        borderRadius: '50%',
                        mr: 2 
                    }} />
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ width: '70%', height: 12, bgcolor: colors.primaryColor, borderRadius: 1, mb: 1 }} />
                        <Box sx={{ width: '50%', height: 8, bgcolor: colors.secondaryColor, borderRadius: 1 }} />
                    </Box>
                </Box>
                {/* Contenido */}
                <Box sx={{ pl: 2 }}>
                    <Box sx={{ width: '85%', height: 8, bgcolor: '#ddd', borderRadius: 1, mb: 1 }} />
                    <Box sx={{ width: '70%', height: 8, bgcolor: '#ddd', borderRadius: 1, mb: 1 }} />
                    <Box sx={{ width: '75%', height: 8, bgcolor: '#ddd', borderRadius: 1 }} />
                </Box>
            </Box>
        )
    },
    {
        id: 'minimal',
        label: 'Minimalista',
        preview: (colors) => (
            <Box sx={{ width: '100%', height: 150, bgcolor: '#fff', borderRadius: 2, overflow: 'hidden', p: 3, border: '1px solid #eee' }}>
                {/* Header minimalista */}
                <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 3
                }}>
                    <Box sx={{ 
                        width: 25, 
                        height: 25, 
                        border: `2px solid ${colors.primaryColor}`,
                        borderRadius: 1,
                        mr: 2 
                    }} />
                    <Box sx={{ width: '50%', height: 10, bgcolor: colors.primaryColor, borderRadius: 5 }} />
                </Box>
                {/* Contenido */}
                <Box>
                    <Box sx={{ width: '100%', height: 1, bgcolor: '#eee', my: 2 }} />
                    <Box sx={{ width: '80%', height: 8, bgcolor: '#f5f5f5', borderRadius: 5, mb: 1 }} />
                    <Box sx={{ width: '90%', height: 8, bgcolor: '#f5f5f5', borderRadius: 5, mb: 1 }} />
                    <Box sx={{ width: '70%', height: 8, bgcolor: '#f5f5f5', borderRadius: 5 }} />
                </Box>
            </Box>
        )
    }
];

const PlantillaConfigModal = ({ open, onClose }) => {
    const { userDetails } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [config, setConfig] = useState({
        businessName: '',
        showLogo: true,
        showDate: true,
        showContactInfo: true,
        contactInfo: '',
        footerText: '',
        design: {
            primaryColor: '#FF5722',
            secondaryColor: '#FF1493',
            headerStyle: 'modern',
            fontSize: 'medium'
        }
    });

    const [colorAnchor, setColorAnchor] = useState({
        primary: null,
        secondary: null
    });

    useEffect(() => {
        const loadConfig = async () => {
            try {
                if (!userDetails) return;

                const docRef = userDetails.tipo_profesional === 'entrenador_independiente'
                    ? doc(db, 'userDetails', userDetails.uid)
                    : doc(db, 'Centros', userDetails.IDBO);

                const docSnap = await getDoc(docRef);
                
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    if (data.plantillaConfig) {
                        setConfig(data.plantillaConfig);
                    }
                }
            } catch (error) {
                console.error('Error al cargar la configuración:', error);
                enqueueSnackbar('Error al cargar la configuración', { variant: 'error' });
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            loadConfig();
        }
    }, [userDetails, open]);

    const handleSave = async () => {
        try {
            setSaving(true);
            if (!userDetails) throw new Error('No hay usuario autenticado');

            const docRef = userDetails.tipo_profesional === 'entrenador_independiente'
                ? doc(db, 'userDetails', userDetails.uid)
                : doc(db, 'Centros', userDetails.IDBO);

            await updateDoc(docRef, {
                plantillaConfig: config
            });

            enqueueSnackbar('Configuración guardada correctamente', { variant: 'success' });
            onClose();
        } catch (error) {
            console.error('Error al guardar la configuración:', error);
            enqueueSnackbar('Error al guardar la configuración', { variant: 'error' });
        } finally {
            setSaving(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleColorClick = (type, event) => {
        setColorAnchor(prev => ({
            ...prev,
            [type]: event.currentTarget
        }));
    };

    const handleColorClose = (type) => {
        setColorAnchor(prev => ({
            ...prev,
            [type]: null
        }));
    };

    const handleColorChange = (color, type) => {
        setConfig(prev => ({
            ...prev,
            design: {
                ...prev.design,
                [type]: color.hex
            }
        }));
    };

    if (loading) {
        return (
            <Dialog open={open} maxWidth="md" fullWidth>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 400 }}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    overflow: 'hidden'
                }
            }}
        >
            <DialogTitle sx={{ 
                m: 0, 
                p: 2.5,
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                bgcolor: 'background.default'
            }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LayoutIcon size={24} weight="duotone" />
                    Configurar Plantilla PDF
                </Typography>
                <IconButton 
                    onClick={onClose}
                    sx={{ 
                        color: 'text.secondary',
                        '&:hover': { 
                            color: 'text.primary',
                            bgcolor: 'action.hover'
                        }
                    }}
                >
                    <CloseIcon size={20} weight="bold" />
                </IconButton>
            </DialogTitle>

            <Divider />

            <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.default' }}>
                <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange}
                    variant="fullWidth"
                    sx={{
                        '& .MuiTab-root': {
                            minHeight: 64,
                            fontSize: '0.875rem'
                        }
                    }}
                >
                    <Tab 
                        icon={<BusinessIcon size={24} weight="duotone" />} 
                        label="Información de Negocio" 
                        iconPosition="start"
                    />
                    <Tab 
                        icon={<PaletteIcon size={24} weight="duotone" />} 
                        label="Colores y Estilo" 
                        iconPosition="start"
                    />
                    <Tab 
                        icon={<TemplateIcon size={24} weight="duotone" />} 
                        label="Diseño" 
                        iconPosition="start"
                    />
                </Tabs>
            </Box>

            <DialogContent sx={{ bgcolor: 'background.default' }}>
                {activeTab === 0 && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Nombre del Negocio"
                                value={config.businessName}
                                onChange={(e) => setConfig(prev => ({
                                    ...prev,
                                    businessName: e.target.value
                                }))}
                                InputProps={{
                                    startAdornment: <BusinessIcon size={20} weight="duotone" style={{ marginRight: 8 }} />
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <SwitchOption
                                        icon={LogoIcon}
                                        label="Mostrar Logo"
                                        checked={config.showLogo}
                                        onChange={(e) => setConfig(prev => ({
                                            ...prev,
                                            showLogo: e.target.checked
                                        }))}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SwitchOption
                                        icon={DateIcon}
                                        label="Mostrar Fecha"
                                        checked={config.showDate}
                                        onChange={(e) => setConfig(prev => ({
                                            ...prev,
                                            showDate: e.target.checked
                                        }))}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Texto del Pie de Página"
                                value={config.footerText}
                                onChange={(e) => setConfig(prev => ({
                                    ...prev,
                                    footerText: e.target.value
                                }))}
                                multiline
                                rows={2}
                                InputProps={{
                                    startAdornment: <FontIcon size={20} weight="duotone" style={{ marginRight: 8, marginBottom: 'auto', marginTop: 12 }} />
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SwitchOption
                                icon={ContactIcon}
                                label="Mostrar Información de Contacto"
                                checked={config.showContactInfo}
                                onChange={(e) => setConfig(prev => ({
                                    ...prev,
                                    showContactInfo: e.target.checked
                                }))}
                            />
                            {config.showContactInfo && (
                                <TextField
                                    fullWidth
                                    label="Información de Contacto"
                                    value={config.contactInfo}
                                    onChange={(e) => setConfig(prev => ({
                                        ...prev,
                                        contactInfo: e.target.value
                                    }))}
                                    placeholder="Ej: Tel: +34 600 000 000 | Email: ejemplo@email.com"
                                    sx={{ mt: 2 }}
                                    InputProps={{
                                        startAdornment: <ContactIcon size={20} weight="duotone" style={{ marginRight: 8 }} />
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}

                {activeTab === 1 && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputLabel sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PaletteIcon size={20} weight="duotone" />
                                Color Principal
                            </InputLabel>
                            <Box
                                onClick={(e) => handleColorClick('primary', e)}
                                sx={{
                                    width: '100%',
                                    height: 56,
                                    backgroundColor: config.design.primaryColor,
                                    cursor: 'pointer',
                                    borderRadius: 2,
                                    transition: 'all 0.2s',
                                    border: '2px solid transparent',
                                    '&:hover': {
                                        transform: 'scale(1.02)',
                                        border: '2px solid rgba(0,0,0,0.1)'
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    fontSize: '0.875rem',
                                    fontWeight: 500,
                                    textShadow: '0 1px 2px rgba(0,0,0,0.2)'
                                }}
                            >
                                {config.design.primaryColor}
                            </Box>
                            <Popover
                                open={Boolean(colorAnchor.primary)}
                                anchorEl={colorAnchor.primary}
                                onClose={() => handleColorClose('primary')}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                sx={{
                                    '& .MuiPopover-paper': {
                                        borderRadius: 2,
                                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                        border: '1px solid rgba(0,0,0,0.1)',
                                        mt: 1
                                    }
                                }}
                            >
                                <ChromePicker
                                    color={config.design.primaryColor}
                                    onChange={(color) => handleColorChange(color, 'primaryColor')}
                                    disableAlpha
                                />
                            </Popover>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PaletteIcon size={20} weight="duotone" />
                                Color Secundario
                            </InputLabel>
                            <Box
                                onClick={(e) => handleColorClick('secondary', e)}
                                sx={{
                                    width: '100%',
                                    height: 56,
                                    backgroundColor: config.design.secondaryColor,
                                    cursor: 'pointer',
                                    borderRadius: 2,
                                    transition: 'all 0.2s',
                                    border: '2px solid transparent',
                                    '&:hover': {
                                        transform: 'scale(1.02)',
                                        border: '2px solid rgba(0,0,0,0.1)'
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    fontSize: '0.875rem',
                                    fontWeight: 500,
                                    textShadow: '0 1px 2px rgba(0,0,0,0.2)'
                                }}
                            >
                                {config.design.secondaryColor}
                            </Box>
                            <Popover
                                open={Boolean(colorAnchor.secondary)}
                                anchorEl={colorAnchor.secondary}
                                onClose={() => handleColorClose('secondary')}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                sx={{
                                    '& .MuiPopover-paper': {
                                        borderRadius: 2,
                                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                        border: '1px solid rgba(0,0,0,0.1)',
                                        mt: 1
                                    }
                                }}
                            >
                                <ChromePicker
                                    color={config.design.secondaryColor}
                                    onChange={(color) => handleColorChange(color, 'secondaryColor')}
                                    disableAlpha
                                />
                            </Popover>
                        </Grid>
                    </Grid>
                )}

                {activeTab === 2 && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                <LayoutIcon size={20} weight="duotone" />
                                Elige el Estilo de tu Documento
                            </Typography>
                            <Grid container spacing={2}>
                                {LAYOUT_EXAMPLES.map((layout) => (
                                    <Grid item xs={12} md={4} key={layout.id}>
                                        <Card
                                            onClick={() => setConfig(prev => ({
                                                ...prev,
                                                design: {
                                                    ...prev.design,
                                                    headerStyle: layout.id
                                                }
                                            }))}
                                            sx={{
                                                cursor: 'pointer',
                                                transition: 'all 0.2s',
                                                transform: config.design.headerStyle === layout.id ? 'scale(1.02)' : 'none',
                                                border: theme => config.design.headerStyle === layout.id ? 
                                                    `2px solid ${config.design.primaryColor}` : 
                                                    '2px solid transparent',
                                                '&:hover': {
                                                    transform: 'scale(1.02)',
                                                    boxShadow: 4
                                                }
                                            }}
                                        >
                                            <CardContent>
                                                <Typography 
                                                    variant="subtitle2" 
                                                    sx={{ 
                                                        mb: 2,
                                                        color: config.design.headerStyle === layout.id ? config.design.primaryColor : 'text.primary'
                                                    }}
                                                >
                                                    {layout.label}
                                                </Typography>
                                                {layout.preview(config.design)}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>

            <DialogActions sx={{ p: 3, bgcolor: 'background.default' }}>
                <Button 
                    onClick={onClose} 
                    variant="outlined"
                    sx={{ borderRadius: 2 }}
                    disabled={saving}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={handleSave} 
                    variant="contained"
                    disabled={saving}
                    sx={{
                        borderRadius: 2,
                        background: `linear-gradient(45deg, ${config.design.primaryColor}, ${config.design.secondaryColor})`,
                        '&:hover': {
                            background: `linear-gradient(45deg, ${config.design.secondaryColor}, ${config.design.primaryColor})`,
                        }
                    }}
                >
                    {saving ? (
                        <>
                            <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                            Guardando...
                        </>
                    ) : (
                        'Guardar Configuración'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PlantillaConfigModal; 