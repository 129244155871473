import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    IconButton,
    Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

const BlockDetailModal = ({ open, onClose, block, onSave, loading }) => {
    const [blockData, setBlockData] = useState({
        tipo: block?.tipobloque || 'superserie',
        nombre: block?.Nombre || '',
        nombre_extenso: block?.Nombre_extenso || '',
        notasEntrenador: block?.NotasEntrenador || '',
        notasPrivadas: block?.NotasPrivadas || ''
    });

    const tiposBloque = [
        { value: 'superserie', label: 'Superserie' },
        { value: 'circuito', label: 'Circuito' },
        { value: 'piramide', label: 'Pirámide' },
        { value: 'dropset', label: 'Drop Set' },
        { value: 'emom', label: 'EMOM' },
        { value: 'amrap', label: 'AMRAP' }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBlockData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        onSave(blockData);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    bgcolor: '#f5f5f5'
                }
            }}
        >
            <DialogTitle sx={{
                m: 0,
                p: 2,
                bgcolor: 'primary.main',
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography variant="h6">Editar Bloque</Typography>
                <IconButton
                    onClick={onClose}
                    sx={{ color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <FormControl fullWidth>
                        <InputLabel>Tipo de Bloque</InputLabel>
                        <Select
                            name="tipo"
                            value={blockData.tipo}
                            onChange={handleChange}
                            label="Tipo de Bloque"
                        >
                            {tiposBloque.map(tipo => (
                                <MenuItem key={tipo.value} value={tipo.value}>
                                    {tipo.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        label="Nombre del Bloque"
                        name="nombre"
                        value={blockData.nombre}
                        onChange={handleChange}
                        variant="outlined"
                    />

                    <TextField
                        fullWidth
                        label="Nombre Extendido"
                        name="nombre_extenso"
                        value={blockData.nombre_extenso}
                        onChange={handleChange}
                        variant="outlined"
                    />

                    <Divider sx={{ my: 2 }}>
                        <Typography color="textSecondary">Notas</Typography>
                    </Divider>

                    <TextField
                        fullWidth
                        label="Notas para el Cliente"
                        name="notasEntrenador"
                        value={blockData.notasEntrenador}
                        onChange={handleChange}
                        multiline
                        rows={3}
                        variant="outlined"
                    />

                    <TextField
                        fullWidth
                        label="Notas Privadas"
                        name="notasPrivadas"
                        value={blockData.notasPrivadas}
                        onChange={handleChange}
                        multiline
                        rows={3}
                        variant="outlined"
                    />
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2, bgcolor: 'background.paper' }}>
                <Button 
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancelar
                </Button>
                <LoadingButton
                    onClick={handleSubmit}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    sx={{
                        bgcolor: 'primary.main',
                        '&:hover': {
                            bgcolor: 'primary.dark',
                        }
                    }}
                >
                    Guardar Cambios
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default BlockDetailModal; 