import React, { useState, useEffect } from 'react';
import { 
    Typography, Box, Paper, Grid, 
    Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Button, Container,
    Dialog, DialogTitle, DialogContent, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import classesBackground from '../../assets/images/clases-sf.png';
import CreateGroupClassForm from './CreateGroupClassForm';
import CreateIndividualClassForm from './CreateIndividualClassForm';
import { db, auth } from '../../config/firebaseConfig';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { startOfWeek, endOfWeek, format, addDays, isBefore, isAfter, getDay } from 'date-fns';
import { es } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';
import IndividualClassSummaryModal from './IndividualClassSummaryModal';
import GroupClassSummaryModal from './GroupClassSummaryModal';

const Classes = () => {
    const [groupClasses, setGroupClasses] = useState([]);
    const [individualClasses, setIndividualClasses] = useState([]);
    const [openCreateClassModal, setOpenCreateClassModal] = useState(false);
    const [classType, setClassType] = useState('group');
    const [openCreateIndividualClassModal, setOpenCreateIndividualClassModal] = useState(false);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [openSummaryModal, setOpenSummaryModal] = useState(false);
    const [selectedGroupClass, setSelectedGroupClass] = useState(null);
    const [openGroupSummaryModal, setOpenGroupSummaryModal] = useState(false);

    useEffect(() => {
        fetchClasses();
    }, []);

    const fetchClasses = async () => {
        try {
            const user = auth.currentUser;
            if (!user) {
                console.error('No hay usuario autenticado');
                return;
            }

            console.log('Usuario autenticado:', user.uid);

            const userDetailsRef = doc(db, 'userDetails', user.uid);
            const userDetailsSnap = await getDoc(userDetailsRef);
            const userDetails = userDetailsSnap.data();

            console.log('Detalles del usuario:', userDetails);

            // Declaramos 'today' una sola vez al principio de la función
            const today = new Date();

            // Obtener clases grupales
            const groupClassesRef = collection(db, 'ClasesGrupales');
            let groupQuery;

            if (userDetails.tipo_profesional === 'propietario_centro' || userDetails.tipo_profesional === 'entrenador_centro') {
                groupQuery = query(
                    groupClassesRef,
                    where('IDBO', '==', userDetails.IDBO)
                );
            } else {
                groupQuery = query(
                    groupClassesRef,
                    where('IDPT', '==', userDetails.IDPT)
                );
            }

            console.log('Tipo de consulta para clases grupales:', userDetails.tipo_profesional);

            const groupSnapshot = await getDocs(groupQuery);

            console.log(`Clases grupales recuperadas: ${groupSnapshot.docs.length}`);

            const groupClassesData = [];

            groupSnapshot.forEach((doc) => {
                const classData = doc.data();
                console.log('Datos de clase grupal:', classData);

                // Filtrar clases no periódicas cuya fecha ya ha pasado
                if (
                    classData.Tipo === 'puntual' &&
                    classData.fecha_inicio &&
                    isBefore(classData.fecha_inicio.toDate(), today)
                ) {
                    return;
                }

                groupClassesData.push({
                    id: doc.id,
                    nombreClase: classData.nombreClase,
                    Fecha: classData.fecha_inicio ? classData.fecha_inicio.toDate() : null,
                    Hora: classData.Tipo === 'periodica' ? classData.Hora.toDate() : (classData.fecha_inicio ? classData.fecha_inicio.toDate() : null),
                    Duracion: classData.Duracion,
                    CapacidadMaxima: classData.CapacidadMaxima,
                    Dificultad: classData.Dificultad,
                    clientes: classData.clientes || [],
                    Dia: classData.Dia,
                    Tipo: classData.Tipo,
                    Periodicidad: classData.Periodicidad,
                    Categorias: classData.Categorias,
                    Descripcion: classData.Descripcion,
                    fecha_final: classData.fecha_final ? classData.fecha_final.toDate() : null
                });
            });

            console.log('Clases grupales procesadas:', groupClassesData);

            setGroupClasses(groupClassesData);
            console.log('Estado de clases grupales actualizado');

            // Obtener clases individuales y solicitudes
            const individualClassesRef = collection(db, 'Citas_cliente_entrenador');
            let individualQuery;

            if (userDetails.tipo_profesional === 'propietario_centro' || userDetails.tipo_profesional === 'entrenador_centro') {
                individualQuery = query(
                    individualClassesRef,
                    where('IDBO', '==', userDetails.IDBO)
                );
            } else {
                individualQuery = query(
                    individualClassesRef,
                    where('IDPT', '==', userDetails.IDPT)
                );
            }

            const individualSnapshot = await getDocs(individualQuery);

            const individualClassesData = [];
            const pendingRequestsData = [];

            individualSnapshot.forEach((doc) => {
                const classData = doc.data();
                const classDate = classData.fecha_inicio.toDate();
                
                if (isAfter(classDate, today)) {
                    if (classData.aceptada) {
                        individualClassesData.push({
                            id: doc.id,
                            nombreClase: classData.nombreClase,
                            Fecha: classDate,
                            Hora: classDate,
                            Duracion: (classData.fecha_final.toDate() - classDate) / 60000,
                            clientes: classData.clientes,
                            modalidad: classData.modalidad,
                            notas: classData.notas
                        });
                    } else if (classData.solicitada_por_cliente && !classData.aceptada && !classData.denegada) {
                        pendingRequestsData.push({
                            id: doc.id,
                            nombreClase: classData.nombreClase,
                            Fecha: classDate,
                            Hora: classDate,
                            Duracion: (classData.fecha_final.toDate() - classDate) / 60000,
                            clientes: classData.clientes,
                            modalidad: classData.modalidad,
                            notas: classData.notas
                        });
                    }
                }
            });

            setIndividualClasses(individualClassesData);
            setPendingRequests(pendingRequestsData);

        } catch (error) {
            console.error('Error al obtener las clases:', error);
        }
    };

    const handleCreateGroupClass = () => {
        setOpenCreateClassModal(true);
    };

    const handleCloseModal = () => {
        setOpenCreateClassModal(false);
    };

    const handleSubmitClass = (newClass) => {
        setGroupClasses([...groupClasses, newClass]);
        setOpenCreateClassModal(false);
        fetchClasses();
    };

    const handleScheduleIndividualClass = () => {
        setOpenCreateIndividualClassModal(true);
    };

    const handleCloseIndividualClassModal = () => {
        setOpenCreateIndividualClassModal(false);
    };

    const handleSubmitIndividualClass = async (newClass) => {
        try {
            // La clase ya se ha creado en Firestore en el formulario
            console.log("Clase individual creada:", newClass);
            setOpenCreateIndividualClassModal(false);
            fetchClasses(); // Actualiza la lista de clases
        } catch (error) {
            console.error('Error al procesar la clase individual:', error);
        }
    };

    const handleClassTypeChange = (event, newClassType) => {
        if (newClassType !== null) {
            setClassType(newClassType);
        }
    };

    const handleAcceptRequest = async (requestId) => {
        try {
            const requestRef = doc(db, 'Citas_cliente_entrenador', requestId);
            await updateDoc(requestRef, {
                aceptada: true
            });
            fetchClasses();
        } catch (error) {
            console.error('Error al aceptar la solicitud:', error);
        }
    };

    const handleRejectRequest = async (requestId) => {
        try {
            const requestRef = doc(db, 'Citas_cliente_entrenador', requestId);
            await updateDoc(requestRef, {
                denegada: true
            });
            fetchClasses();
        } catch (error) {
            console.error('Error al rechazar la solicitud:', error);
        }
    };

    const handleOpenSummaryModal = (classData) => {
        setSelectedClass(classData);
        setOpenSummaryModal(true);
    };

    const handleCloseSummaryModal = () => {
        setOpenSummaryModal(false);
        setSelectedClass(null);
    };

    const handleOpenGroupSummaryModal = (classData) => {
        setSelectedGroupClass(classData);
        setOpenGroupSummaryModal(true);
    };

    const handleCloseGroupSummaryModal = () => {
        setOpenGroupSummaryModal(false);
        setSelectedGroupClass(null);
    };

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3,
                position: 'relative',
                minHeight: 'calc(100vh - 64px)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${classesBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.1,
                    zIndex: -1,
                }
            }}
        >
            <Box sx={{ marginBottom: '120px' }}>
                <Typography variant="h4" gutterBottom>
                    Clases y Reservas
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ mb: 4 }}>
                    Gestiona tus clases grupales e individuales
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                    <ToggleButtonGroup
                        value={classType}
                        exclusive
                        onChange={handleClassTypeChange}
                        aria-label="class type"
                    >
                        <ToggleButton value="group" aria-label="group classes">
                            <GroupIcon sx={{ mr: 1 }} /> Clases Grupales
                        </ToggleButton>
                        <ToggleButton value="individual" aria-label="individual classes">
                            <PersonIcon sx={{ mr: 1 }} /> Clases Individuales
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <Paper elevation={3} sx={{ p: 3, borderRadius: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        {classType === 'group' ? 'Clases Grupales' : 'Clases Individuales'}
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Hora</TableCell>
                                    <TableCell>Duración</TableCell>
                                    {classType === 'group' ? (
                                        <>
                                            <TableCell>Capacidad</TableCell>
                                            <TableCell>Dificultad</TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell>Cliente</TableCell>
                                            <TableCell>Modalidad</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(classType === 'group' ? groupClasses : individualClasses).map((clase) => (
                                    <TableRow 
                                        key={clase.id} 
                                        onClick={() => classType === 'individual' ? handleOpenSummaryModal(clase) : handleOpenGroupSummaryModal(clase)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell>{clase.nombreClase}</TableCell>
                                        <TableCell>
                                            {clase.Tipo === 'periodica' ? clase.Dia : (clase.Fecha ? format(clase.Fecha, 'dd/MM/yyyy', { locale: es }) : 'N/A')}
                                        </TableCell>
                                        <TableCell>
                                            {clase.Tipo === 'periodica' ? 
                                                (clase.Hora ? format(clase.Hora, 'HH:mm') : 'N/A') :
                                                (clase.Hora ? format(clase.Hora, 'HH:mm') : 'N/A')}
                                        </TableCell>
                                        <TableCell>{clase.Duracion} min</TableCell>
                                        {classType === 'group' ? (
                                            <>
                                                <TableCell>{`${clase.clientes.length}/${clase.CapacidadMaxima}`}</TableCell>
                                                <TableCell>{clase.Dificultad}</TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell>{clase.clientes[0]?.nombre || 'N/A'}</TableCell>
                                                <TableCell>{clase.modalidad || 'N/A'}</TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Container maxWidth="lg" sx={{ width: '100%' }}>
                    <Box sx={{ 
                        mt: 4, 
                        display: 'flex', 
                        justifyContent: 'center',
                        gap: 2,
                    }}>
                        <Button 
                            variant="contained" 
                            startIcon={<GroupIcon />}
                            onClick={handleCreateGroupClass}
                            sx={{
                                backgroundColor: '#FF5722',
                                color: 'white',
                                padding: '10px 20px',
                                borderRadius: '25px',
                                textTransform: 'none',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#E64A19',
                                    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
                                    transform: 'translateY(-2px)',
                                }
                            }}
                        >
                            Crear Clase Grupal
                        </Button>
                        <Button 
                            variant="outlined" 
                            startIcon={<PersonIcon />}
                            onClick={handleScheduleIndividualClass}
                            sx={{
                                color: '#FF5722',
                                borderColor: '#FF5722',
                                padding: '10px 20px',
                                borderRadius: '25px',
                                textTransform: 'none',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                    borderColor: '#E64A19',
                                    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
                                    transform: 'translateY(-2px)',
                                }
                            }}
                        >
                            Programar Clase Individual
                        </Button>
                    </Box>
                </Container>

                {classType === 'individual' && (
                    <Paper elevation={3} sx={{ p: 3, borderRadius: 4, mt: 4, mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Solicitudes Pendientes de Clases Individuales
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Hora</TableCell>
                                        <TableCell>Duración</TableCell>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>Modalidad</TableCell>
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pendingRequests.map((request) => (
                                        <TableRow key={request.id}>
                                            <TableCell>{request.nombreClase}</TableCell>
                                            <TableCell>{format(request.Fecha, 'dd/MM/yyyy', { locale: es })}</TableCell>
                                            <TableCell>{format(request.Hora, 'HH:mm')}</TableCell>
                                            <TableCell>{request.Duracion} min</TableCell>
                                            <TableCell>{request.clientes[0]?.nombre || 'N/A'}</TableCell>
                                            <TableCell>{request.modalidad || 'N/A'}</TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Button 
                                                        onClick={() => handleAcceptRequest(request.id)} 
                                                        variant="contained"
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#4CAF50',
                                                            color: 'white',
                                                            '&:hover': {
                                                                backgroundColor: '#45a049',
                                                            },
                                                        }}
                                                    >
                                                        Aceptar
                                                    </Button>
                                                    <Button 
                                                        onClick={() => handleRejectRequest(request.id)} 
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            borderColor: '#FF5722',
                                                            color: '#FF5722',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                                                borderColor: '#E64A19',
                                                            },
                                                        }}
                                                    >
                                                        Rechazar
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                )}

                <Dialog 
                    open={openCreateClassModal} 
                    onClose={handleCloseModal} 
                    maxWidth="md" 
                    fullWidth
                    PaperProps={{
                        style: {
                            borderRadius: '20px',
                            padding: '20px',
                            backgroundColor: '#FFFFFF',
                        },
                    }}
                >
                    <DialogTitle sx={{ color: '#333', fontWeight: 'normal' }}>Crear Clase Grupal</DialogTitle>
                    <DialogContent>
                        <CreateGroupClassForm onSubmit={handleSubmitClass} onCancel={handleCloseModal} />
                    </DialogContent>
                </Dialog>

                <Dialog 
                    open={openCreateIndividualClassModal} 
                    onClose={handleCloseIndividualClassModal} 
                    maxWidth="md" 
                    fullWidth
                    PaperProps={{
                        style: {
                            borderRadius: '20px',
                            padding: '20px',
                            backgroundColor: '#FFFFFF',
                        },
                    }}
                >
                    <DialogTitle sx={{ color: '#333', fontWeight: 'normal' }}>Crear Clase Individual</DialogTitle>
                    <DialogContent>
                        <CreateIndividualClassForm onSubmit={handleSubmitIndividualClass} onCancel={handleCloseIndividualClassModal} />
                    </DialogContent>
                </Dialog>

                <IndividualClassSummaryModal
                    open={openSummaryModal}
                    onClose={handleCloseSummaryModal}
                    classData={selectedClass}
                />

                <GroupClassSummaryModal
                    open={openGroupSummaryModal}
                    onClose={handleCloseGroupSummaryModal}
                    classData={selectedGroupClass}
                />
            </Box>
        </Box>
    );
};

export default Classes;
