import React, { useState } from 'react';
import {
    Card, CardContent, Typography, Box, IconButton,
    Button, Collapse, CardHeader, Chip, Divider,
    List, ListItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Exercise from './Exercise';
import BlockDetailModal from '../modals/BlockDetailModal';

const ExerciseBlock = ({
    block,
    dayIndex,
    onDelete,
    onAddExercise,
    onDeleteExercise,
    onReplaceExercise,
    onUpdateBlock,
    exerciseImages
}) => {
    const [expanded, setExpanded] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);

    const handleExpandClick = (e) => {
        e.stopPropagation();
        setExpanded(!expanded);
    };

    const handleCardClick = () => {
        setOpenDetailModal(true);
    };

    const getTipoBlockeColor = (tipo) => {
        const colors = {
            'superserie': '#FF4081',
            'circuito': '#7C4DFF',
            'piramide': '#FF9100',
            'dropset': '#00BFA5',
            'emom': '#FF1744',
            'amrap': '#2979FF'
        };
        return colors[tipo] || '#757575';
    };

    return (
        <>
            <Card 
                sx={{
                    mb: 2,
                    borderRadius: '15px',
                    overflow: 'hidden',
                    border: '1px solid #eee',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    cursor: 'pointer',
                    '&:hover': {
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    }
                }}
                onClick={handleCardClick}
            >
                <CardHeader
                    title={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                {block.Nombre}
                            </Typography>
                            <Chip 
                                label={block.tipobloque}
                                size="small"
                                sx={{
                                    bgcolor: getTipoBlockeColor(block.tipobloque),
                                    color: 'white',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    fontSize: '0.7rem'
                                }}
                            />
                        </Box>
                    }
                    subheader={block.Nombre_extenso}
                    action={
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton 
                                onClick={() => onDelete(block, dayIndex)}
                                size="small"
                                sx={{ 
                                    color: 'error.main',
                                    '&:hover': { bgcolor: 'error.light' }
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleExpandClick}
                                sx={{
                                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s'
                                }}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Box>
                    }
                />

                <Collapse in={expanded}>
                    <CardContent>
                        {block.NotasEntrenador && (
                            <Box sx={{ 
                                mb: 3,
                                p: 3,
                                background: 'linear-gradient(135deg, rgba(255, 87, 34, 0.05) 0%, rgba(255, 193, 7, 0.05) 100%)',
                                borderRadius: '16px',
                                border: '1px solid',
                                borderColor: 'rgba(255, 87, 34, 0.2)',
                                position: 'relative',
                                overflow: 'hidden',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                    width: '4px',
                                    background: 'linear-gradient(to bottom, #FF5722, #FFC107)',
                                    borderTopLeftRadius: '16px',
                                    borderBottomLeftRadius: '16px'
                                }
                            }}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 1, 
                                    mb: 1.5 
                                }}>
                                    <Typography 
                                        variant="subtitle1" 
                                        sx={{ 
                                            color: '#FF5722',
                                            fontWeight: 600,
                                            fontSize: '1.1rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1
                                        }}
                                    >
                                        📝 Notas del entrenador
                                    </Typography>
                                </Box>
                                <Typography 
                                    variant="body2" 
                                    sx={{ 
                                        color: 'text.secondary',
                                        lineHeight: 1.6,
                                        pl: 0.5
                                    }}
                                >
                                    {block.NotasEntrenador}
                                </Typography>
                            </Box>
                        )}

                        <Box sx={{ mb: 2 }}>
                            <Button
                                startIcon={<AddIcon />}
                                variant="outlined"
                                onClick={() => onAddExercise(block, dayIndex)}
                                fullWidth
                                sx={{
                                    borderStyle: 'dashed',
                                    '&:hover': {
                                        borderStyle: 'solid'
                                    }
                                }}
                            >
                                Añadir ejercicio al bloque
                            </Button>
                        </Box>

                        {block.Ejercicios_Bloque?.length > 0 ? (
                            <List sx={{ p: 0 }}>
                                {block.Ejercicios_Bloque.map((exercise, index) => (
                                    <ListItem 
                                        key={index} 
                                        sx={{ 
                                            px: 0, 
                                            py: 1,
                                            display: 'block'
                                        }}
                                    >
                                        <Exercise
                                            exercise={exercise}
                                            dayIndex={dayIndex}
                                            exerciseIndex={index}
                                            onDelete={(exercise) => onDeleteExercise(exercise, index)}
                                            onReplace={(oldExercise, newExercise) => 
                                                onReplaceExercise(oldExercise, newExercise)
                                            }
                                            exerciseImages={exerciseImages}
                                            isInBlock={true}
                                        />
                                        {index < block.Ejercicios_Bloque.length - 1 && (
                                            <Box sx={{ 
                                                display: 'flex', 
                                                alignItems: 'center',
                                                my: 1
                                            }}>
                                                <Divider sx={{ flex: 1 }} />
                                                <Chip 
                                                    label={block.tipobloque === 'superserie' ? 'Super Set' : 'Siguiente'}
                                                    size="small"
                                                    sx={{ mx: 1 }}
                                                />
                                                <Divider sx={{ flex: 1 }} />
                                            </Box>
                                        )}
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography 
                                variant="body2" 
                                color="text.secondary"
                                align="center"
                                sx={{ py: 2 }}
                            >
                                No hay ejercicios en este bloque
                            </Typography>
                        )}
                    </CardContent>
                </Collapse>
            </Card>

            <BlockDetailModal
                open={openDetailModal}
                onClose={() => setOpenDetailModal(false)}
                block={block}
                onSave={(updatedBlockData) => {
                    onUpdateBlock(block, updatedBlockData, dayIndex);
                    setOpenDetailModal(false);
                }}
            />
        </>
    );
};

export default ExerciseBlock; 