import React, { useState } from 'react';
import {
    Card, CardContent, Typography, Box, IconButton,
    Button, Dialog, DialogTitle, DialogContent,
    Chip, Grid, Collapse
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ExerciseDetailModal from '../modals/ExerciseDetailModal';
import ExercisePicker from './ExercisePicker';

const Exercise = ({ 
    exercise, 
    dayIndex, 
    exerciseIndex, 
    onDelete, 
    onReplace, 
    loading,
    exerciseImages = {},
    isInBlock = false
}) => {
    const [showActions, setShowActions] = useState(false);
    const [openReplacePicker, setOpenReplacePicker] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const imagenUrl = exercise.Zona ? exerciseImages[exercise.Zona.toLowerCase()] : null;

    if (exercise.Nombre === "Descanso") {
        return (
            <Card sx={{
                mb: 2,
                backgroundColor: 'grey.100',
                borderRadius: '15px',
                overflow: 'hidden'
            }}>
                <CardContent>
                    <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                        Día de Descanso
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    const renderSeriesInfo = () => (
        <Grid container spacing={2} sx={{ mt: 1 }}>
            {exercise.pesos && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2">Pesos por serie:</Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        {exercise.pesos.map((peso, idx) => (
                            <Chip
                                key={idx}
                                label={`${peso} kg`}
                                size="small"
                                variant="outlined"
                            />
                        ))}
                    </Box>
                </Grid>
            )}
            
            {exercise.repeticiones && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2">Repeticiones por serie:</Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        {exercise.repeticiones.map((reps, idx) => (
                            <Chip
                                key={idx}
                                label={`${reps} reps`}
                                size="small"
                                variant="outlined"
                            />
                        ))}
                    </Box>
                </Grid>
            )}
            
            {exercise.PR && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="success.main">
                        PR: {exercise.PR} kg
                    </Typography>
                </Grid>
            )}
        </Grid>
    );

    return (
        <>
            <Card
                sx={{
                    mb: 2,
                    borderRadius: isInBlock ? '8px' : '15px',
                    overflow: 'hidden',
                    border: '1px solid #eee',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: '4px',
                        backgroundColor: '#FF7F50',
                        borderTopLeftRadius: '15px',
                        borderBottomLeftRadius: '15px'
                    },
                    '&:hover .exercise-actions': {
                        opacity: 1
                    }
                }}
                onClick={() => setOpenDetailModal(true)}
                onMouseEnter={() => setShowActions(true)}
                onMouseLeave={() => setShowActions(false)}
            >
                <Box
                    className="exercise-actions"
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        display: 'flex',
                        gap: 1,
                        opacity: 0,
                        transition: 'opacity 0.3s ease',
                        zIndex: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderRadius: '10px',
                        padding: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                    }}
                >
                    <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenReplacePicker(true);
                        }}
                        startIcon={<SwapHorizIcon />}
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'primary.dark'
                            },
                            textTransform: 'none',
                            fontSize: '0.9rem',
                            px: 2
                        }}
                    >
                        Reemplazar
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<CloseIcon />}
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete(exercise, dayIndex);
                        }}
                        disabled={loading}
                        sx={{
                            backgroundColor: 'error.main',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'error.dark'
                            },
                            textTransform: 'none',
                            fontSize: '0.9rem',
                            px: 2
                        }}
                    >
                        Eliminar
                    </Button>
                </Box>

                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            {imagenUrl && (
                                <Box
                                    component="img"
                                    src={imagenUrl}
                                    alt={exercise.Zona || 'Ejercicio'}
                                    sx={{
                                        width: '80px',
                                        height: '80px',
                                        objectFit: 'cover',
                                        borderRadius: '10px',
                                        border: '1px solid #eee'
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: '#FF7F50',
                                        fontWeight: 'bold',
                                        fontSize: '1.5rem'
                                    }}
                                >
                                    {exercise.Nombre}
                                </Typography>
                                <Chip
                                    label={exercise.Zona}
                                    sx={{
                                        backgroundColor: '#2196F3',
                                        color: 'white',
                                        borderRadius: '20px',
                                        fontWeight: 500
                                    }}
                                />
                            </Box>

                            <Box sx={{
                                backgroundColor: 'rgba(255, 127, 80, 0.1)',
                                p: 2,
                                borderRadius: '10px',
                                mb: 2
                            }}>
                                <Typography sx={{ color: '#666' }}>
                                    {exercise.Nombre_extenso}
                                </Typography>
                            </Box>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        {exercise.Series > 0 && (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography variant="body2" sx={{ color: '#666', fontWeight: 'bold' }}>
                                                    Series:
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#666' }}>
                                                    {exercise.Series}
                                                </Typography>
                                            </Box>
                                        )}
                                        {exercise.Descanso && (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography variant="body2" sx={{ color: '#666', fontWeight: 'bold' }}>
                                                    Descanso:
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#666' }}>
                                                    {exercise.Descanso / 1000} segundos
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Collapse in={expanded}>
                    <CardContent>
                        {renderSeriesInfo()}
                        
                        {exercise.recursos_video && exercise.recursos_video.length > 0 && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle2">Videos de referencia:</Typography>
                                {/* Aquí iría un componente para mostrar los videos */}
                            </Box>
                        )}
                        
                        {exercise.AudioURL && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle2">Audio explicativo:</Typography>
                                <audio controls src={exercise.AudioURL} style={{ width: '100%' }} />
                            </Box>
                        )}
                    </CardContent>
                </Collapse>
            </Card>

            <ExerciseDetailModal
                open={openDetailModal}
                onClose={() => setOpenDetailModal(false)}
                exercise={exercise}
            />

            <Dialog
                open={openReplacePicker}
                onClose={() => setOpenReplacePicker(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Reemplazar ejercicio: {exercise.Nombre}
                    <IconButton
                        onClick={() => setOpenReplacePicker(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <ExercisePicker
                        onSelect={(newExercise) => {
                            onReplace(exercise, newExercise, dayIndex);
                            setOpenReplacePicker(false);
                        }}
                        dayIndex={dayIndex}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Exercise; 