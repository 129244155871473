import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Box,
    IconButton,
    CircularProgress,
    Fade
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Lottie from 'lottie-react';
import loadingAnimation from '../../../assets/animations/Resumen_Home_Loading.json';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from 'notistack';

const LoadingAnimation = () => (
    <Box 
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            p: 3,
            position: 'relative'
        }}
    >
        <Lottie 
            animationData={loadingAnimation}
            style={{ 
                width: 150,
                height: 150,
                filter: 'drop-shadow(0 0 10px rgba(255, 87, 34, 0.3))'
            }}
        />
        <Typography 
            variant="h6" 
            sx={{ 
                mt: 2,
                color: '#FF5722',
                textAlign: 'center',
                animation: 'pulse 1.5s infinite',
                '@keyframes pulse': {
                    '0%': { opacity: 0.6 },
                    '50%': { opacity: 1 },
                    '100%': { opacity: 0.6 }
                }
            }}
        >
            Generando cambios con IA...
        </Typography>
    </Box>
);

const EditAIModal = ({ open, onClose, type, client, onUpdate, userDetails }) => {
    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        setSuccess(false);
        setPrompt('');
        onClose();
    };

    const handleSubmit = async () => {
        setLoading(true);
        setSuccess(false);
        
        const requestBody = {
            uidcliente: client.uidcliente,
            tipo_profesional: userDetails.tipo_profesional,
            IDPT: userDetails.IDPT,
            prompt: prompt
        };

        const url = type === 'diet' 
            ? 'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/editar-dieta-dashboard-classic'
            : 'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/editar-rutina-dashboard-classic';

        console.log('🚀 Enviando solicitud a la cloud function:');
        console.log('📝 Request body:', JSON.stringify(requestBody, null, 2));
        console.log('🎯 Tipo de edición:', type);
        console.log('🔗 URL:', url);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            console.log('📡 Respuesta del servidor:', response.status);
            
            if (response.status === 200) {
                console.log('✅ Solicitud exitosa');
                setSuccess(true);
                setTimeout(() => {
                    handleClose();
                    if (onUpdate) onUpdate();
                }, 2000);
            } else {
                const errorData = await response.json();
                console.error('❌ Error del servidor:', errorData);
                throw new Error('Error al procesar la solicitud');
            }
        } catch (error) {
            console.error('❌ Error en la solicitud:', error);
            enqueueSnackbar('Error al procesar la solicitud', { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
        } finally {
            setLoading(false);
        }
    };

    const dietExamples = `Ejemplos para dieta:
- Ajusta la dieta para aumentar la ingesta de proteínas y reducir los carbohidratos simples
- Aumenta la ingesta de carbohidratos en las comidas posteriores al entrenamiento
- Añade una comida rica en proteínas los lunes y viernes
- Modifica el desayuno para incluir más fibra y grasas saludables
- Ajusta las porciones para reducir  calorías diarias
- Incluye más verduras en las comidas principales`;

    const routineExamples = `Ejemplos para rutina:
- Mueve la rutina del martes al miércoles y ajusta la intensidad
- Reduce la duración del entrenamiento del viernes en 30 minutos
- Añade más ejercicios de peso muerto y sentadillas
- Cambia los ejercicios de pecho del lunes al jueves
- Reduce el tiempo de cardio a 20 minutos e incrementa el entrenamiento de fuerza
- Añade ejercicios de movilidad al inicio de cada sesión`;

    const SuccessAnimation = () => (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            p: 3 
        }}>
            <CheckCircleIcon 
                sx={{ 
                    fontSize: 60, 
                    color: '#4CAF50',
                    animation: 'scale 0.5s ease-in-out',
                    '@keyframes scale': {
                        '0%': { transform: 'scale(0)' },
                        '100%': { transform: 'scale(1)' }
                    }
                }} 
            />
            <Typography 
                variant="h6" 
                sx={{ 
                    mt: 2,
                    color: '#4CAF50',
                    textAlign: 'center'
                }}
            >
                ¡Cambios realizados con éxito!
            </Typography>
        </Box>
    );

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    background: 'linear-gradient(to bottom, #ffffff, #f8f8f8)',
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                }
            }}
        >
            <DialogTitle sx={{ 
                m: 0, 
                p: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
            }}>
                <AutoFixHighIcon sx={{ color: '#FF5722', fontSize: 28 }} />
                <Typography variant="h5" component="div" sx={{ 
                    flexGrow: 1,
                    fontWeight: 600,
                    fontSize: '1.5rem'
                }}>
                    Editar {type === 'diet' ? 'Dieta' : 'Rutina'} con IA
                </Typography>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                        '&:hover': {
                            background: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ 
                p: 3,
                minHeight: 400,
                transition: 'all 0.3s ease-in-out'
            }}>
                {loading ? (
                    <LoadingAnimation />
                ) : success ? (
                    <SuccessAnimation />
                ) : (
                    <>
                        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                            Describe los cambios que quieres realizar en {type === 'diet' ? 'la dieta' : 'la rutina'} de {client?.nombre}
                        </Typography>
                        
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            placeholder={type === 'diet' ? dietExamples : routineExamples}
                            disabled={loading}
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                    backgroundColor: 'white',
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#FF5722',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#FF5722',
                                    }
                                }
                            }}
                        />
                    </>
                )}
            </DialogContent>

            {!loading && !success && (
                <DialogActions sx={{ p: 3, gap: 2 }}>
                    <Button 
                        onClick={handleClose}
                        disabled={loading}
                        sx={{
                            color: 'text.secondary',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        disabled={!prompt.trim() || loading}
                        variant="contained"
                        sx={{
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            color: 'white',
                            px: 4,
                            py: 1,
                            borderRadius: '25px',
                            textTransform: 'none',
                            fontSize: '1rem',
                            fontWeight: 600,
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                            },
                            '&:disabled': {
                                background: '#ccc',
                                color: 'rgba(255, 255, 255, 0.8)'
                            }
                        }}
                    >
                        Generar cambios
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default EditAIModal; 