import React, { useState, useEffect, useContext } from 'react';
import { 
    Typography, 
    Box, 
    Button, 
    Paper,
    List,
    ListItem,
    ListItemText,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    MenuItem,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    Alert,
    CircularProgress
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import clientsBackground from '../../assets/images/imagen-dashboard-clients.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { AuthContext } from '../../contexts/AuthContext';
import { db } from '../../config/firebaseConfig';
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion, deleteDoc, collection, getDocs, setDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import searchCodeImage from '../../assets/images/onboarding/search-code.PNG';
import confirmationImage from '../../assets/images/onboarding/confirmation.PNG';

const AddClient = () => {
    const [pendingRequests, setPendingRequests] = useState([]);
    const { currentUser, userDetails } = useContext(AuthContext);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Estado para el formulario
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        nombre: '',
        apellidos: '',
        fechaNacimiento: null,
        sexo: '',
        peso: '',
        objetivoagua: '',
        objetivocalorias: '',
        objetivograsas: '',
        objetivohidratos: '',
        objetivoprote: '',
        objetivotiempo: '',
        objetivopasos: '',
        tipodieta: ''
    });

    useEffect(() => {
        fetchPendingRequests();
    }, [currentUser, userDetails]);

    const fetchPendingRequests = async () => {
        if (currentUser && userDetails) {
            let requests = [];
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const userDocRef = doc(db, 'userDetails', currentUser.uid);
                const userDocSnap = await getDoc(userDocRef);
                
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    requests = userData.solicitudes || [];
                }
            } else if (['propietario_centro', 'entrenador_centro'].includes(userDetails.tipo_profesional)) {
                const centroDocRef = doc(db, 'Centros', userDetails.IDBO);
                const centroDocSnap = await getDoc(centroDocRef);
                
                if (centroDocSnap.exists()) {
                    const centroData = centroDocSnap.data();
                    requests = centroData.solicitudes || [];
                }
            }
            setPendingRequests(requests);
        }
    };

    const handleRejectRequest = async (requestUid) => {
        try {
            console.log('Iniciando rechazo de solicitud para:', requestUid);

            // Primero actualizamos el documento del usuario solicitante
            const userDocRef = doc(db, 'userDetails', requestUid);
            await updateDoc(userDocRef, {
                tiene_entrenador: false,
                en_solicitud: false,
                solicitud_aceptada: false,
                IDPT: null,
                IDBO: null
            });
            console.log('Usuario actualizado correctamente');

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                console.log('Procesando rechazo para entrenador independiente');
                
                // Para entrenador independiente
                const trainerDocRef = doc(db, 'userDetails', currentUser.uid);
                const requestToRemove = pendingRequests.find(request => request.uid === requestUid);
                
                // Eliminamos la solicitud del array
                await updateDoc(trainerDocRef, {
                    solicitudes: arrayRemove(requestToRemove)
                });
                console.log('Solicitud eliminada del array de solicitudes');

                // Primero obtenemos todos los documentos de la subcolección para encontrar el correcto
                const clientesRef = collection(db, 'userDetails', currentUser.uid, 'Clientes_asociados');
                const clientesSnapshot = await getDocs(clientesRef);
                
                // Buscamos el documento que tenga el uidcliente correcto
                const docToDelete = clientesSnapshot.docs.find(doc => doc.data().uidcliente === requestUid);
                
                if (docToDelete) {
                    console.log('Encontrado documento a eliminar con ID:', docToDelete.id);
                    const clienteAsociadoRef = doc(db, 'userDetails', currentUser.uid, 'Clientes_asociados', docToDelete.id);
                    await deleteDoc(clienteAsociadoRef);
                    console.log('Documento eliminado de Clientes_asociados');
                } else {
                    console.log('No se encontró el documento a eliminar');
                }

            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                console.log('Procesando rechazo para centro');
                
                // Para centro
                const centroDocRef = doc(db, 'Centros', userDetails.IDBO);
                const requestToRemove = pendingRequests.find(request => request.uid === requestUid);
                
                // Eliminamos la solicitud del array
                await updateDoc(centroDocRef, {
                    solicitudes: arrayRemove(requestToRemove)
                });
                console.log('Solicitud eliminada del array de solicitudes del centro');

                // Eliminamos el documento de Clientes_asociados_centros
                const clienteAsociadoRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', requestUid);
                console.log('Intentando eliminar documento de:', clienteAsociadoRef.path);
                await deleteDoc(clienteAsociadoRef);
                console.log('Documento eliminado de Clientes_asociados_centros');
            }

            setPendingRequests(prevRequests => prevRequests.filter(request => request.uid !== requestUid));
            console.log('Proceso de rechazo completado exitosamente');

        } catch (error) {
            console.error("Error al rechazar la solicitud:", error);
            console.error("Stack trace:", error.stack);
        }
    };

    const handleAcceptRequest = async (requestUid) => {
        try {
            const requestToAccept = pendingRequests.find(request => request.uid === requestUid);

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const trainerDocRef = doc(db, 'userDetails', currentUser.uid);
                await updateDoc(trainerDocRef, {
                    solicitudes: arrayRemove(requestToAccept),
                    clientes: arrayUnion(requestToAccept)
                });
            } else {
                const centroDocRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centroDocRef, {
                    solicitudes: arrayRemove(requestToAccept),
                    clientes: arrayUnion(requestToAccept)
                });
            }

            const clientDocRef = doc(db, 'userDetails', requestUid);
            await updateDoc(clientDocRef, {
                tiene_entrenador: true,
                en_solicitud: false,
                solicitud_aceptada: true
            });

            setPendingRequests(prevRequests => prevRequests.filter(request => request.uid !== requestUid));
            console.log(`Solicitud aceptada: ${requestUid}`);
        } catch (error) {
            console.error("Error al aceptar la solicitud:", error);
        }
    };

    const handleCreateUser = async () => {
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const auth = getAuth();
            // Guardar la referencia del usuario actual (profesional)
            const currentAuthUser = auth.currentUser;
            
            // Crear usuario en Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const newUser = userCredential.user;

            // Preparar datos base del usuario
            const baseUserData = {
                uid: newUser.uid,
                email: formData.email,
                nombre: formData.nombre,
                apellidos: formData.apellidos,
                fechanacimiento: formData.fechaNacimiento,
                sexo: formData.sexo,
                peso: Number(formData.peso) || 0,
                objetivoagua: Number(formData.objetivoagua) || 0,
                objetivocalorias: Number(formData.objetivocalorias) || 0,
                objetivograsas: Number(formData.objetivograsas) || 0,
                objetivohidratos: Number(formData.objetivohidratos) || 0,
                objetivoprote: Number(formData.objetivoprote) || 0,
                objetivotiempo: Number(formData.objetivotiempo) || 0,
                objetivopasos: Number(formData.objetivopasos) || 0,
                tipodieta: formData.tipodieta || 'normal',
                plan: 'Free',
                tipo_usuario: 'cliente',
                tiene_entrenador: true,
                en_solicitud: false,
                solicitud_aceptada: true,
                uidcliente: newUser.uid
            };

            let userData = { ...baseUserData };

            // Añadir campos específicos según el tipo de profesional
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                userData = {
                    ...userData,
                    IDPT: userDetails.IDPT,
                    IDBO: null
                };

                // Crear documento en userDetails
                await setDoc(doc(db, 'userDetails', newUser.uid), userData);

                // Crear en Clientes_asociados
                await setDoc(doc(db, 'userDetails', currentUser.uid, 'Clientes_asociados', newUser.uid), userData);

                // Actualizar el array de clientes del entrenador
                const trainerDocRef = doc(db, 'userDetails', currentUser.uid);
                await updateDoc(trainerDocRef, {
                    clientes: arrayUnion({
                        uid: newUser.uid,
                        nombre: formData.nombre,
                        email: formData.email
                    })
                });

            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                userData = {
                    ...userData,
                    IDBO: userDetails.IDBO,
                    IDPT: null
                };

                // Crear documento en userDetails
                await setDoc(doc(db, 'userDetails', newUser.uid), userData);

                // Crear en Clientes_asociados_centros
                await setDoc(doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', newUser.uid), userData);

                // Actualizar el array de clientes del centro
                const centroDocRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centroDocRef, {
                    clientes: arrayUnion({
                        uid: newUser.uid,
                        nombre: formData.nombre,
                        email: formData.email
                    })
                });
            }

            // Volver a iniciar sesión con el usuario profesional
            await auth.updateCurrentUser(currentAuthUser);

            setSuccess('Usuario creado exitosamente');
            setFormData({
                email: '',
                password: '',
                nombre: '',
                apellidos: '',
                fechaNacimiento: null,
                sexo: '',
                peso: '',
                objetivoagua: '',
                objetivocalorias: '',
                objetivograsas: '',
                objetivohidratos: '',
                objetivoprote: '',
                objetivotiempo: '',
                objetivopasos: '',
                tipodieta: ''
            });
            setTimeout(() => setOpenCreateModal(false), 2000);

        } catch (error) {
            console.error('Error al crear usuario:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (field) => (event) => {
        setFormData(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    };

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3,
                position: 'relative',
                minHeight: 'calc(100vh - 64px)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${clientsBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.1,
                    zIndex: -1,
                }
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Añade a un nuevo cliente
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PersonAddIcon />}
                    onClick={() => setOpenCreateModal(true)}
                    sx={{
                        background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                        color: 'white',
                        px: 3,
                        py: 1.5,
                        borderRadius: '25px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        fontSize: '1rem',
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 6px 20px rgba(255, 87, 34, 0.4)',
                        }
                    }}
                >
                    Crear Nuevo Cliente
                </Button>
            </Box>

            <Typography variant="subtitle1" gutterBottom>
                Gestiona nuevos ingresos de clientes desde esta página
            </Typography>

            <Paper elevation={2} sx={{ 
                p: 4, 
                mt: 3, 
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: 3,
                }
            }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', mb: 3, display: 'flex', alignItems: 'center' }}>
                    <PersonAddIcon sx={{ mr: 1 }} />
                    Solicitudes de Vinculación
                </Typography>
                {pendingRequests.length > 0 ? (
                    <List>
                        {pendingRequests.map((request, index) => (
                            <React.Fragment key={request.uid}>
                                <ListItem
                                    sx={{
                                        mb: 2,
                                        bgcolor: 'background.paper',
                                        borderRadius: 2,
                                        boxShadow: 1,
                                    }}
                                >
                                    <ListItemText
                                        primary={request.nombre}
                                        secondary={
                                            <>
                                                <Typography component="span" variant="body2" color="text.primary">
                                                    {request.email}
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <Button
                                        startIcon={<CheckCircleIcon />}
                                        variant="contained"
                                        color="success"
                                        onClick={() => handleAcceptRequest(request.uid)}
                                        sx={{ mr: 1 }}
                                    >
                                        Aceptar
                                    </Button>
                                    <Button
                                        startIcon={<CancelIcon />}
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleRejectRequest(request.uid)}
                                    >
                                        Rechazar
                                    </Button>
                                </ListItem>
                                {index < pendingRequests.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                        No hay solicitudes pendientes en este momento.
                    </Typography>
                )}
            </Paper>

            <Paper elevation={2} sx={{ 
                p: 4, 
                mt: 3, 
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', mb: 3 }}>
                    ¿Cómo funciona el proceso de vinculación?
                </Typography>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <Box>
                        <Typography variant="h6" gutterBottom color="secondary">
                            1. Comparte tu código único
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Tu código único de vinculación es <Typography component="span" variant="h6" color="orange" fontWeight="bold">
                                {userDetails?.tipo_profesional === 'entrenador_independiente' 
                                    ? `Código: ${userDetails?.IDPT}`
                                    : `Código: ${userDetails?.IDBO}`}
                            </Typography>
                        </Typography>
                        <Typography variant="body1">
                            Comparte este código con tus clientes para que puedan encontrarte en la aplicación.
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="h6" gutterBottom color="secondary">
                            2. El cliente introduce el código
                        </Typography>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <img 
                                src={searchCodeImage} 
                                alt="Búsqueda de código" 
                                style={{ 
                                    maxWidth: '300px', 
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }} 
                            />
                        </Box>
                        <Typography variant="body1">
                            Los clientes introducirán este código en la sección de búsqueda de la aplicación.
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="h6" gutterBottom color="secondary">
                            3. Confirmación del profesional
                        </Typography>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <img 
                                src={confirmationImage} 
                                alt="Confirmación de vinculación" 
                                style={{ 
                                    maxWidth: '300px', 
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }} 
                            />
                        </Box>
                        <Typography variant="body1">
                            Una vez que el cliente envíe la solicitud, aparecerá en tu lista de solicitudes pendientes en la parte superior de esta página.
                            Solo tienes que hacer clic en "Aceptar" para comenzar a trabajar con tu nuevo cliente.
                        </Typography>
                    </Box>
                </Box>
            </Paper>

            {/* Modal de Creación de Usuario */}
            <Dialog 
                open={openCreateModal} 
                onClose={() => setOpenCreateModal(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Crear Nuevo Cliente
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenCreateModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    {success && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            {success}
                        </Alert>
                    )}
                    <Grid container spacing={2}>
                        {/* Información básica */}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
                                Información Básica
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                value={formData.email}
                                onChange={handleInputChange('email')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Contraseña"
                                type="password"
                                value={formData.password}
                                onChange={handleInputChange('password')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                value={formData.nombre}
                                onChange={handleInputChange('nombre')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Apellidos"
                                value={formData.apellidos}
                                onChange={handleInputChange('apellidos')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                <DatePicker
                                    label="Fecha de Nacimiento"
                                    value={formData.fechaNacimiento}
                                    onChange={(newValue) => {
                                        setFormData(prev => ({
                                            ...prev,
                                            fechaNacimiento: newValue
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Sexo</InputLabel>
                                <Select
                                    value={formData.sexo}
                                    label="Sexo"
                                    onChange={handleInputChange('sexo')}
                                >
                                    <MenuItem value="hombre">Hombre</MenuItem>
                                    <MenuItem value="mujer">Mujer</MenuItem>
                                    <MenuItem value="otro">Otro</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Objetivos y métricas */}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 2, mt: 2, color: 'primary.main' }}>
                                Objetivos y Métricas
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Peso (kg)"
                                type="number"
                                value={formData.peso}
                                onChange={handleInputChange('peso')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Objetivo de agua (ml)"
                                type="number"
                                value={formData.objetivoagua}
                                onChange={handleInputChange('objetivoagua')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Objetivo de calorías"
                                type="number"
                                value={formData.objetivocalorias}
                                onChange={handleInputChange('objetivocalorias')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Objetivo de grasas (g)"
                                type="number"
                                value={formData.objetivograsas}
                                onChange={handleInputChange('objetivograsas')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Objetivo de hidratos (g)"
                                type="number"
                                value={formData.objetivohidratos}
                                onChange={handleInputChange('objetivohidratos')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Objetivo de proteínas (g)"
                                type="number"
                                value={formData.objetivoprote}
                                onChange={handleInputChange('objetivoprote')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Objetivo de tiempo de ejercicio (min)"
                                type="number"
                                value={formData.objetivotiempo}
                                onChange={handleInputChange('objetivotiempo')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Objetivo de pasos"
                                type="number"
                                value={formData.objetivopasos}
                                onChange={handleInputChange('objetivopasos')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Tipo de Dieta</InputLabel>
                                <Select
                                    value={formData.tipodieta}
                                    label="Tipo de Dieta"
                                    onChange={handleInputChange('tipodieta')}
                                >
                                    <MenuItem value="normal">Normal</MenuItem>
                                    <MenuItem value="vegetariana">Vegetariana</MenuItem>
                                    <MenuItem value="vegana">Vegana</MenuItem>
                                    <MenuItem value="cetogenica">Cetogénica</MenuItem>
                                    <MenuItem value="mediterranea">Mediterránea</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setOpenCreateModal(false)}
                        color="inherit"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleCreateUser}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : null}
                    >
                        Crear Cliente
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AddClient;
