import React from 'react';
import { Box } from '@mui/material';

const ElevenLabsWidget = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 1000
      }}
    >
      <elevenlabs-convai agent-id="jfuaLMEoZ0yKjm9hcX0A"></elevenlabs-convai>
    </Box>
  );
};

export default ElevenLabsWidget; 