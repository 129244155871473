import React, { useState } from 'react';
import { 
    TextField, Button, FormControl, InputLabel, Select, MenuItem,
    RadioGroup, FormControlLabel, Radio, Typography, Box, Rating
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { db, auth } from '../../config/firebaseConfig';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { es } from 'date-fns/locale';

const difficultyLevels = [
    { value: 1, label: 'Principiante' },
    { value: 2, label: 'Intermedio' },
    { value: 3, label: 'Avanzado' },
    { value: 4, label: 'Experto' },
];

const CreateGroupClassForm = ({ onSubmit, onCancel }) => {
    const [classData, setClassData] = useState({
        name: '',
        description: '',
        time: null,
        duration: 60,
        type: 'puntual',
        date: null,
        periodicity: 'semanal',
        day: '',
        capacity: 10,
        difficulty: 1,
        cancellationPolicy: 'flexible',
        categories: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'capacity') {
            setClassData({ ...classData, [name]: parseInt(value, 10) || '' });
        } else {
            setClassData({ ...classData, [name]: value });
        }
    };

    const handleTimeChange = (newTime) => {
        setClassData({ ...classData, time: newTime });
    };

    const handleDateChange = (newDate) => {
        setClassData({ ...classData, date: newDate });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            const user = auth.currentUser;
            if (!user) {
                throw new Error('No hay usuario autenticado');
            }

            const userDetailsRef = doc(db, 'userDetails', user.uid);
            const userDetailsSnap = await getDoc(userDetailsRef);
            
            if (!userDetailsSnap.exists()) {
                throw new Error('No se encontraron detalles del usuario');
            }

            const userDetails = userDetailsSnap.data();
            const IDGC = `GC${Date.now()}`;

            let fechaClase = classData.date;
            let horaClase = classData.time;

            let fechaHoraInicio = null;
            let fechaHoraFin = null;

            if (classData.type === 'puntual') {
                // Combinar fecha y hora para clases puntuales
                fechaHoraInicio = new Date(
                    classData.date.getFullYear(),
                    classData.date.getMonth(),
                    classData.date.getDate(),
                    classData.time.getHours(),
                    classData.time.getMinutes()
                );
                fechaHoraFin = new Date(fechaHoraInicio.getTime() + classData.duration * 60000);
            }

            if (classData.type === 'periodica') {
                // Para clases periódicas, solo guardamos el día de la semana
                fechaClase = null;
            }

            const newClassData = {
                IDGC: IDGC,
                IDBO: userDetails.IDBO,
                IDPT: userDetails.IDPT,
                clientes: [],
                nombreClase: classData.name,
                Descripcion: classData.description,
                Hora: horaClase,
                Duracion: classData.duration,
                Tipo: classData.type,
                Fecha: fechaClase,
                Periodicidad: classData.type === 'periodica' ? 'semanal' : null,
                Dia: classData.type === 'periodica' ? classData.day : null,
                CapacidadMaxima: parseInt(classData.capacity, 10),
                Dificultad: difficultyLevels.find(level => level.value === classData.difficulty)?.label,
                PoliticaCancelacion: classData.cancellationPolicy === 'flexible' ? 'posible_cancelar' : 'se_cobra_igual',
                Categorias: classData.categories,
                fecha_inicio: fechaHoraInicio,
                fecha_final: fechaHoraFin
            };

            console.log('Datos de la nueva clase:', newClassData); // Para depuración

            const docRef = await addDoc(collection(db, 'ClasesGrupales'), newClassData);
            console.log('Documento creado con ID: ', docRef.id);

            onSubmit(newClassData);
        } catch (error) {
            console.error('Error al crear la clase grupal:', error);
            // Aquí podrías mostrar un mensaje de error al usuario
        }
    };

    const textFieldStyle = {
        marginBottom: 2,
        '& .MuiOutlinedInput-root': {
            borderRadius: 4,
        },
        '& .MuiInputLabel-root': {
            background: 'white',
            padding: '0 4px',
        }
    };

    const selectFieldStyle = {
        ...textFieldStyle,
        '& .MuiSelect-select': {
            paddingTop: '16.5px',
            paddingBottom: '16.5px',
        }
    };

    const buttonStyle = {
        borderRadius: '25px',
        padding: '10px 20px',
        textTransform: 'none',
        fontSize: '16px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-2px)',
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin="normal"
                    name="name"
                    label="Nombre de la clase"
                    value={classData.name}
                    onChange={handleChange}
                    required
                    sx={textFieldStyle}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="description"
                    label="Descripción de la clase"
                    multiline
                    rows={4}
                    value={classData.description}
                    onChange={handleChange}
                    required
                    sx={textFieldStyle}
                />
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <TimePicker
                        label="Hora de la clase"
                        value={classData.time}
                        onChange={handleTimeChange}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                fullWidth 
                                margin="normal"
                                required 
                                sx={textFieldStyle}
                            />
                        )}
                    />
                    <TextField
                        fullWidth
                        name="duration"
                        label="Duración (minutos)"
                        type="number"
                        value={classData.duration}
                        onChange={handleChange}
                        required
                        sx={textFieldStyle}
                    />
                </Box>
                <FormControl fullWidth margin="normal" sx={selectFieldStyle}>
                    <InputLabel>Tipo de clase</InputLabel>
                    <Select
                        name="type"
                        value={classData.type}
                        onChange={handleChange}
                        required
                    >
                        <MenuItem value="puntual">Puntual</MenuItem>
                        <MenuItem value="periodica">Periódica</MenuItem>
                    </Select>
                </FormControl>
                {classData.type === 'puntual' ? (
                    <DatePicker
                        label="Fecha de la clase"
                        value={classData.date}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" required sx={textFieldStyle} />}
                    />
                ) : (
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <FormControl fullWidth sx={selectFieldStyle}>
                            <InputLabel>Periodicidad</InputLabel>
                            <Select
                                name="periodicity"
                                value={classData.periodicity}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="diario">Diario</MenuItem>
                                <MenuItem value="semanal">Semanal</MenuItem>
                                <MenuItem value="mensual">Mensual</MenuItem>
                            </Select>
                        </FormControl>
                        {classData.periodicity !== 'diario' && (
                            <FormControl fullWidth sx={selectFieldStyle}>
                                <InputLabel>Día</InputLabel>
                                <Select
                                    name="day"
                                    value={classData.day}
                                    onChange={handleChange}
                                    required
                                >
                                    <MenuItem value="lunes">Lunes</MenuItem>
                                    <MenuItem value="martes">Martes</MenuItem>
                                    <MenuItem value="miercoles">Miércoles</MenuItem>
                                    <MenuItem value="jueves">Jueves</MenuItem>
                                    <MenuItem value="viernes">Viernes</MenuItem>
                                    <MenuItem value="sabado">Sábado</MenuItem>
                                    <MenuItem value="domingo">Domingo</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                )}
                <TextField
                    fullWidth
                    margin="normal"
                    name="capacity"
                    label="Capacidad máxima"
                    type="number"
                    value={classData.capacity}
                    onChange={handleChange}
                    required
                    sx={textFieldStyle}
                />
                <Box sx={{ mt: 4, mb: 2 }}>
                    <Typography gutterBottom>Nivel de Dificultad</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Rating
                            name="difficulty"
                            value={classData.difficulty}
                            max={4}
                            onChange={(event, newValue) => {
                                handleChange({ target: { name: 'difficulty', value: newValue } });
                            }}
                            icon={<FitnessCenterIcon fontSize="inherit" />}
                            emptyIcon={<FitnessCenterIcon fontSize="inherit" />}
                            sx={{ fontSize: '2rem' }}
                        />
                        <Typography variant="body2">
                            {difficultyLevels.find(level => level.value === classData.difficulty)?.label}
                        </Typography>
                    </Box>
                </Box>
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <Typography component="legend">Política de cancelación</Typography>
                    <RadioGroup
                        name="cancellationPolicy"
                        value={classData.cancellationPolicy}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="flexible" control={<Radio />} label="Se puede cancelar" />
                        <FormControlLabel value="strict" control={<Radio />} label="Se cobra igualmente" />
                    </RadioGroup>
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    name="categories"
                    label="Categorías"
                    value={classData.categories}
                    onChange={handleChange}
                    placeholder="Ejemplo: Cardio, Fuerza, Flexibilidad, Bienestar"
                    sx={textFieldStyle}
                />
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button onClick={onCancel} sx={{ ...buttonStyle, color: '#FF5722' }}>
                        Cancelar
                    </Button>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        sx={{ ...buttonStyle, backgroundColor: '#FF5722', color: 'white', '&:hover': { backgroundColor: '#E64A19' } }}
                    >
                        Crear Clase
                    </Button>
                </Box>
            </form>
        </LocalizationProvider>
    );
};

export default CreateGroupClassForm;
