import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Typography,
    TextField,
    Switch,
    FormControlLabel,
    Button,
    Paper,
    Container,
    Grid,
    Avatar,
    Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';
import { FiCpu, FiSliders, FiMessageSquare, FiInfo, FiFileText, FiUser, FiPlus, FiTrash2, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { db } from '../../config/firebaseConfig';
import { doc, setDoc, getDoc, collection } from 'firebase/firestore';
import Collapse from '@mui/material/Collapse';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0 6px 30px rgba(0, 0, 0, 0.15)',
    }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            transition: 'border-color 0.3s',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FF5722',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiInputBase-input': {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiInputBase-input::placeholder': {
        color: 'rgba(0, 0, 0, 0.4)',
        opacity: 1,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IA = () => {
    const { userDetails } = useContext(AuthContext);
    const [estilo, setEstilo] = useState('');
    const [reglas, setReglas] = useState('');
    const [adoptarManera, setAdoptarManera] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [contexto, setContexto] = useState('');
    const [ejemploEjercicio, setEjemploEjercicio] = useState('');
    const [ejemploActual, setEjemploActual] = useState('');
    const [ejemplos, setEjemplos] = useState([]);
    const [expandedEjemplo, setExpandedEjemplo] = useState(null);

    const loadIAConfig = useCallback(async () => {
        try {
            let configDoc;
            if (userDetails.tipo_profesional === 'propietario_centro') {
                const centroRef = doc(db, 'Centros', userDetails.IDBO);
                const iaConfigRef = doc(collection(centroRef, 'IA'), 'configuracion');
                configDoc = await getDoc(iaConfigRef);
            } else if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const userRef = doc(db, 'userDetails', userDetails.uid);
                const iaConfigRef = doc(collection(userRef, 'IA'), 'configuracion');
                configDoc = await getDoc(iaConfigRef);
            }

            if (configDoc && configDoc.exists()) {
                const data = configDoc.data();
                setEstilo(data.estilo || '');
                setReglas(data.reglas || '');
                setAdoptarManera(data.adoptarManera || false);
                setContexto(data.contexto || '');
                setEjemploEjercicio(data.ejemploEjercicio || '');
                setEjemplos(data.ejemplos || []);
            }
        } catch (error) {
            console.error("Error al cargar la configuración de IA:", error);
            showSnackbar('Error al cargar la configuración. Por favor, intenta de nuevo.', 'error');
        }
    }, [userDetails]);

    useEffect(() => {
        if (userDetails) {
            setIsEditable(userDetails.tipo_profesional !== 'entrenador_centro');
            loadIAConfig();
        }
    }, [userDetails, loadIAConfig]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isEditable) {
            showSnackbar('Como trabajador del centro, no puedes editar esta configuración. Por favor, contacta al propietario del centro.', 'error');
            return;
        }

        try {
            const configData = { 
                estilo, 
                reglas, 
                adoptarManera,
                contexto,
                ejemploEjercicio,
                ejemplos,
            };
            
            if (userDetails.tipo_profesional === 'propietario_centro') {
                const centroRef = doc(db, 'Centros', userDetails.IDBO);
                await setDoc(doc(collection(centroRef, 'IA'), 'configuracion'), configData);
            } else if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const userRef = doc(db, 'userDetails', userDetails.uid);
                await setDoc(doc(collection(userRef, 'IA'), 'configuracion'), configData);
            }

            showSnackbar('Configuración guardada con éxito', 'success');
        } catch (error) {
            console.error("Error al guardar la configuración:", error);
            showSnackbar('Error al guardar la configuración. Por favor, intenta de nuevo.', 'error');
        }
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const updateButtonStyle = {
        mt: 2,
        borderRadius: '25px',
        padding: '10px 20px',
        textTransform: 'none',
        fontSize: '16px',
        color: 'white',
        backgroundColor: '#FF5722',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: '#F4511E',
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-2px)',
        }
    };

    const handleAddEjemplo = () => {
        if (ejemploActual.trim()) {
            const nuevoEjemplo = {
                id: Date.now(),
                texto: ejemploActual,
            };
            setEjemplos([...ejemplos, nuevoEjemplo]);
            setEjemploActual('');
        }
    };

    const handleDeleteEjemplo = (id) => {
        setEjemplos(ejemplos.filter(ejemplo => ejemplo.id !== id));
    };

    return (
        <Box 
            sx={{ 
                flexGrow: 1,
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(/path/to/background-image.png)`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.2,
                    zIndex: -1,
                }
            }}
        >
            <Container maxWidth="md">
                <Box sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3} alignItems="center" justifyContent="center">
                        <Grid item>
                            <Avatar sx={{ 
                                width: 80, 
                                height: 80, 
                                bgcolor: '#FF5722',
                                boxShadow: '0 0 10px rgba(255,87,34,0.5)'
                            }}>
                                <FiCpu size={40} color="#fff" />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" gutterBottom sx={{ color: '#333' }}>
                                Configuración de IA
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <StyledPaper elevation={3}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <FiSliders size={24} color="#FF5722" style={{ marginRight: '10px' }} />
                                    <Typography variant="h5" sx={{ color: '#333' }}>Estilo de IA</Typography>
                                </Box>
                                <StyledTextField
                                    fullWidth
                                    label="Define el estilo de tu IA"
                                    variant="outlined"
                                    value={estilo}
                                    onChange={(e) => setEstilo(e.target.value)}
                                    placeholder="Ej: Motivador y enérgico como un entrenador personal de élite"
                                    disabled={!isEditable}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <FiSliders size={24} color="#FF5722" style={{ marginRight: '10px' }} />
                                    <Typography variant="h5" sx={{ color: '#333' }}>Reglas de Interacción</Typography>
                                </Box>
                                <StyledTextField
                                    fullWidth
                                    label="Establece las reglas para tu IA"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={reglas}
                                    onChange={(e) => setReglas(e.target.value)}
                                    placeholder="Ej: Mantén un tono positivo, evita temas no relacionados con fitness, no des consejos médicos específicos"
                                    disabled={!isEditable}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <FiMessageSquare size={24} color="#FF5722" style={{ marginRight: '10px' }} />
                                    <Typography variant="h5" sx={{ color: '#333' }}>Adaptación del Lenguaje</Typography>
                                </Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={adoptarManera}
                                            onChange={(e) => setAdoptarManera(e.target.checked)}
                                            color="primary"
                                            disabled={!isEditable}
                                            sx={{
                                                '& .MuiSwitch-switchBase.Mui-checked': {
                                                    color: '#FF5722',
                                                },
                                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                    backgroundColor: '#FF5722',
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography style={{ color: '#333' }}>
                                            Adoptar mi manera de hablar 
                                        </Typography>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <FiUser size={24} color="#FF5722" style={{ marginRight: '10px' }} />
                                    <Typography variant="h5" sx={{ color: '#333' }}>Contexto para la IA</Typography>
                                    <Tooltip title="Describe tu especialidad, estilo de comunicación y metodología. Esto ayudará a la IA a entender mejor tu forma de trabajo y a proporcionar respuestas más alineadas con tu práctica profesional." arrow>
                                        <IconButton size="small" sx={{ ml: 1 }}>
                                            <FiInfo />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <StyledTextField
                                    fullWidth
                                    label="Describe tu contexto profesional"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={contexto}
                                    onChange={(e) => setContexto(e.target.value)}
                                    placeholder="Ej: Soy entrenador especializado en fuerza y rehabilitación. Mi enfoque es muy técnico y detallado. Con mis clientes mantengo un tono cercano pero profesional, utilizando analogías para explicar conceptos complejos..."
                                    disabled={!isEditable}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <FiFileText size={24} color="#FF5722" style={{ marginRight: '10px' }} />
                                    <Typography variant="h5" sx={{ color: '#333' }}>Ejemplos de Descripción de Ejercicios</Typography>
                                    <Tooltip title="Añade ejemplos de cómo describes los ejercicios en tus rutinas. Esto ayudará a la IA a replicar tu estilo al generar descripciones." arrow>
                                        <IconButton size="small" sx={{ ml: 1 }}>
                                            <FiInfo />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                
                                <Box sx={{ mb: 2 }}>
                                    <StyledTextField
                                        fullWidth
                                        label="Escribe un ejemplo de descripción de ejercicio"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        value={ejemploActual}
                                        onChange={(e) => setEjemploActual(e.target.value)}
                                        placeholder="Ej: Press de Banca:
1. Posición inicial: Tumbado en el banco, pies bien apoyados
2. Agarre: Ligeramente más ancho que los hombros..."
                                        disabled={!isEditable}
                                    />
                                    <Button
                                        onClick={handleAddEjemplo}
                                        startIcon={<FiPlus />}
                                        disabled={!isEditable || !ejemploActual.trim()}
                                        sx={{
                                            mt: 1,
                                            backgroundColor: '#FF5722',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#F4511E',
                                            },
                                        }}
                                    >
                                        Añadir Ejemplo
                                    </Button>
                                </Box>

                                <Box sx={{ mt: 2 }}>
                                    {ejemplos.map((ejemplo) => (
                                        <Paper
                                            key={ejemplo.id}
                                            sx={{
                                                mb: 1,
                                                p: 2,
                                                backgroundColor: '#f5f5f5',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Box 
                                                sx={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                                onClick={() => setExpandedEjemplo(expandedEjemplo === ejemplo.id ? null : ejemplo.id)}
                                            >
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                    Ejemplo #{ejemplos.indexOf(ejemplo) + 1}
                                                </Typography>
                                                <Box>
                                                    {isEditable && (
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDeleteEjemplo(ejemplo.id);
                                                            }}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            <FiTrash2 color="#FF5722" />
                                                        </IconButton>
                                                    )}
                                                    <IconButton size="small">
                                                        {expandedEjemplo === ejemplo.id ? <FiChevronUp /> : <FiChevronDown />}
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Collapse in={expandedEjemplo === ejemplo.id}>
                                                <Typography 
                                                    variant="body2" 
                                                    sx={{ 
                                                        mt: 1,
                                                        whiteSpace: 'pre-line'
                                                    }}
                                                >
                                                    {ejemplo.texto}
                                                </Typography>
                                            </Collapse>
                                        </Paper>
                                    ))}
                                </Box>
                            </Grid>
                            {!isEditable && (
                                <Grid item xs={12}>
                                    <Typography variant="body1" color="error">
                                        Como trabajador del centro, no puedes editar esta configuración. Por favor, contacta al propietario del centro.
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    size="large"
                                    startIcon={<FiCpu size={24} />}
                                    disabled={!isEditable}
                                    sx={updateButtonStyle}
                                >
                                    Guardar Configuración
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </StyledPaper>
            </Container>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default IA;