import React, { useState, useEffect } from 'react';
import {
    Box, Typography, List, ListItem, ListItemIcon,
    ListItemText, IconButton, Grid, Card, CardMedia,
    CircularProgress, Chip
} from '@mui/material';
import {
    ArrowBack as ArrowBackIcon,
    FitnessCenter as FitnessCenterIcon,
    ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const ExercisePicker = ({ onSelect, dayIndex, blockIndex = null }) => {
    const [exercises, setExercises] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedZone, setSelectedZone] = useState(null);
    const db = getFirestore();

    useEffect(() => {
        const fetchExercises = async () => {
            try {
                const barSnapshot = await getDocs(collection(db, 'BAR'));
                const exercisesByZone = {};

                const zonasSnapshot = await getDocs(collection(db, 'leezonafotos'));
                const zonasImages = {};
                
                zonasSnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.zona && data.imagen) {
                        zonasImages[data.zona.toLowerCase()] = data.imagen;
                    }
                });

                barSnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.Ejercicios && data.Zona) {
                        const zona = data.Zona;
                        if (!exercisesByZone[zona]) {
                            const zonaLower = zona.toLowerCase();
                            exercisesByZone[zona] = {
                                exercises: [],
                                image: zonasImages[zonaLower]
                            };
                        }
                        exercisesByZone[zona].exercises.push({
                            nombre: data.Ejercicios,
                            zona: data.Zona,
                            nombre_extenso: data.Nombre_extenso
                        });
                    }
                });

                setExercises(exercisesByZone);
            } catch (error) {
                console.error('Error al cargar ejercicios:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExercises();
    }, [db]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!selectedZone) {
        return (
            <Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    pb: 1
                }}>
                    <FitnessCenterIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="h6">
                        Selecciona una zona muscular
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    {Object.entries(exercises).map(([zona, data]) => (
                        <Grid item xs={12} sm={6} md={4} key={zona}>
                            <Card
                                onClick={() => setSelectedZone(zona)}
                                sx={{
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: 3
                                    }
                                }}
                            >
                                <Box sx={{ position: 'relative' }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={data.image || '/path/to/default/image.jpg'}
                                        alt={zona}
                                        sx={{ objectFit: 'cover' }}
                                    />
                                    <Box sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        bgcolor: 'rgba(0, 0, 0, 0.6)',
                                        color: 'white',
                                        p: 1
                                    }}>
                                        <Typography variant="subtitle1">
                                            {zona}
                                        </Typography>
                                        <Typography variant="body2">
                                            {data.exercises.length} ejercicios
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                borderBottom: '1px solid',
                borderColor: 'divider',
                pb: 1
            }}>
                <IconButton
                    onClick={() => setSelectedZone(null)}
                    sx={{ mr: 1 }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">
                    {selectedZone}
                </Typography>
            </Box>
            <List sx={{ width: '100%' }}>
                {exercises[selectedZone].exercises.map((exercise, index) => (
                    <ListItem
                        key={index}
                        button
                        onClick={() => onSelect(exercise, dayIndex, blockIndex)}
                        sx={{
                            borderRadius: 1,
                            mb: 1,
                            transition: 'all 0.2s ease',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                                transform: 'translateX(4px)'
                            }
                        }}
                    >
                        <ListItemIcon>
                            <FitnessCenterIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                            primary={exercise.nombre}
                            secondary={`Zona: ${exercise.zona}`}
                        />
                        <ChevronRightIcon color="action" />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default ExercisePicker; 